import React, { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../../components/ui/dialog'; // Replace with your actual dialog library imports
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
    ArrowUpDown,
    MoreHorizontal,
    Plus,
    PlusCircle,
    LayoutList,
    LayoutGrid,
} from 'lucide-react';
import { Button } from '../../components/ui/button';

import { useCommonSearch } from '@/components/shared/common-search';
import { DataTableSkeleton } from '@/components/shared/data-table-skeleton';
import DataTable from '@/components/shared/data-table';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { Batch } from '@/types';
import { useToast } from '@/components/ui/use-toast';
import { QueryParams } from '@/lib/api';
import { useDeleteBatchData, useGetBatches } from '@/queries/batch-query';
import BatchCreate from './batch-create';
import { LocationComponent } from '@/common/dropdowns';
import { GetLocationList } from '@/common/functions';
import { useCommonProvider } from '@/providers/common-provider';
import BatchCardcomponent from './batch-card-view';
import { Tooltip } from '@radix-ui/react-tooltip';
import { TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

const BatchList = () => {
    const { toast } = useToast();
    const navigate = useNavigate();
    const [selectedBatchId, setSelectedBatchId] = useState<string | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { mutate: deleteBatchData } = useDeleteBatchData();
    const { selectedLocation, setSelectedLocation } = useCommonProvider();
    console.log('selectedLocation---', selectedLocation);

    const [searchParams, setSearchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || 1);
    const limit = Number(searchParams.get('limit') || 50);
    const sortBy = String(searchParams.get('sortBy') || 'updated_at');
    const sortOrder = String(searchParams.get('sortOrder') || 'DESC');
    const { searchQuery } = useCommonSearch();
    const params: QueryParams = {
        page,
        limit,
        sortBy: sortBy,
        sortOrder: sortOrder,
        q: searchQuery || ' ',
        ...(selectedLocation && selectedLocation !== 'all' && { location_id: selectedLocation }), // Conditionally add location_id
    };
    useEffect(() => {
        if (selectedLocation && selectedLocation !== 'all') {
            // Update the searchParams with the new location and reset the page to 0
            const updatedParams = {
                ...Object.fromEntries(searchParams.entries()), // Keep other existing params
                location_id: selectedLocation,
                page: '1', // Reset page to 0
            };
            setSearchParams(updatedParams);
        }
    }, [selectedLocation, setSearchParams, searchParams]);
    const viewParam = searchParams.get('view') || 'tableView';
    const [viewChange, setViewChange] = useState(viewParam);

    const handleViewToggle = (view: string) => {
        setViewChange(view);
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()), // Retain other params
            view, // Set the new view type
        });
    };
    const handleDeleteBatch = () => {
        if (!selectedBatchId) return;

        deleteBatchData(
            { id: selectedBatchId },
            {
                onSuccess: () => {
                    toast({
                        title: 'Batch Successfully Deleted',
                        description: 'The batch was deleted successfully!',
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                },
                onError: (error: any) => {
                    console.error('Deletion failed:', error);
                    toast({
                        title: 'Deletion Failed',
                        description: 'There was an error deleting the batch.',
                    });
                },
            }
        );
        setSelectedBatchId(null); // Reset selected batch ID
    };
    const handleViewDetails = (id: string) => {
        console.log('View details of batch with ID:', id);
        navigate(`/batches/${id}`);
    };

    const { data, isLoading, isError, refetch } = useGetBatches(params);
    console.log('data-----', data);
    const handleRowClick = (id: string) => {
        navigate(`/batches/${id}`);
    };
    if (isError) {
        return <div>Error loading data</div>;
    }

    const columns: ColumnDef<Batch>[] = [
        {
            accessorKey: 'batch_code',
            header: ({ column }) => (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Batch Code
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: ({ row }) => (
                <div
                    className=" text-left ml-6 cursor-pointer"
                    onClick={() => handleRowClick(row.original.id)}>
                    {row.original.batch_code}
                </div>
            ),
        },
        {
            accessorKey: 'batch_types',
            header: 'Batch Type',
            cell: ({ row }) => {
                const types = row.original.batch_types.map((type) => type.name).join(', ');
                return (
                    <div className="cursor-pointer" onClick={() => handleRowClick(row.original.id)}>
                        {types}
                    </div>
                );
            },
        },
        {
            accessorKey: 'slot_times',
            header: 'Batch Slots',
            cell: ({ row }) => {
                const item = row.original;
                const slotTimes = item.batch_slots || [];
                return (
                    <div className="cursor-pointer" onClick={() => handleRowClick(row.original.id)}>
                        {slotTimes.map((slotTime, index) => (
                            <React.Fragment key={index}>
                                {slotTime.slot_times && slotTime.slot.includes('morning') && (
                                    <p>Morning</p>
                                )}
                                {slotTime.slot_times && slotTime.slot.includes('afternoon') && (
                                    <p>Afternoon</p>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                );
            },
        },
        {
            accessorKey: 'batch_stream_id',
            header: ({ column }) => (
                <Button
                    className=""
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Batch Stream
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: ({ row }) => {
                const streams = row.original.batch_stream
                    .map((stream) => stream.stream_names)
                    .join(', ');
                return (
                    <div className="cursor-pointer" onClick={() => handleRowClick(row.original.id)}>
                        {streams}
                    </div>
                );
            },
        },
        {
            accessorKey: 'starting_date',
            header: ({ column }) => (
                <Button
                    className=""
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Start Date
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: ({ row }) => (
                <div className="cursor-pointer" onClick={() => handleRowClick(row.original.id)}>
                    {row.original.starting_date}
                </div>
            ),
        },
        {
            accessorKey: 'duration',
            header: ({ column }) => (
                <Button
                    className=""
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Duration
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: ({ row }) => (
                <div className="cursor-pointer" onClick={() => handleRowClick(row.original.id)}>
                    {row.original.duration} {row.original.duration === 1 ? 'Year' : 'Years'}
                </div>
            ),
        },
        {
            accessorKey: 'preferred_type',
            header: 'Preferred Type',
            cell: ({ row }) => {
                const item = row.original;
                return (
                    <div className="cursor-pointer" onClick={() => handleRowClick(row.original.id)}>
                        {item.selected_days && <span>Fixed</span>}
                        {item.selected_days_count && <span>Flexible</span>}
                    </div>
                );
            },
        },
        {
            accessorKey: 'location_id',
            header: ({ column }) => (
                <Button
                    className=""
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Locations
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: ({ row }) => {
                const locations = row.original.locations
                    .map((location) => location.name)
                    .join(', ');
                return (
                    <div className="cursor-pointer" onClick={() => handleRowClick(row.original.id)}>
                        {locations}
                    </div>
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem
                            onClick={() => handleViewDetails(row.original.id)}
                            className=" cursor-pointer ">
                            View details
                        </DropdownMenuItem>

                        <DropdownMenuItem
                            onClick={() => setSelectedBatchId(row.original.id)}
                            className=" cursor-pointer ">
                            Delete batch
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            ),
        },
    ];
    return (
        <div className="w-full h-[calc(100vh-6rem)] ooverflow-auto p-4 bg-background relative border">
            <Toaster />
            <div className="flex justify-end mb-4">
                <div>
                    {<GetLocationList />}
                    <LocationComponent showLabel={false} />
                </div>
                {/* toggling views */}
                <div className="flex items-center space-x-1 ml-auto">
                    {/* Table View Icon */}
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                className={`p-1 rounded ${viewChange === 'tableView' ? 'bg-gray-300' : 'bg-white'} hover:bg-gray-200`}
                                onClick={() => handleViewToggle('tableView')}>
                                <LayoutList className="h-6 w-6" />
                            </button>
                        </TooltipTrigger>
                        <TooltipContent side="top">List view</TooltipContent>
                    </Tooltip>
                    {/* Card View Icon */}
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                className={`p-1 rounded ${viewChange === 'cardView' ? 'bg-gray-300' : 'bg-white'} hover:bg-gray-200`}
                                onClick={() => handleViewToggle('cardView')}>
                                <LayoutGrid className="h-6 w-6" />
                            </button>
                        </TooltipTrigger>
                        <TooltipContent side="top">Card view</TooltipContent>
                    </Tooltip>
                </div>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogTrigger asChild>
                        <Button
                            variant="outline"
                            className="text-primary rounded shadow h-12 gap-1 h-10 w-30 mt-0 ml-4"
                            onClick={() => {
                                setIsDialogOpen(true);
                            }}>
                            <PlusCircle className="h-3.5 w-3.5 mr-2" />
                            New Batch
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="mx-auto p-3">
                        <DialogHeader>
                            <DialogTitle>Create Batch</DialogTitle>
                            <DialogDescription>Create a new batch</DialogDescription>
                        </DialogHeader>
                        <div className="bg-slate-50">
                            <BatchCreate
                                batch={''}
                                refetch={refetch}
                                onClose={() => {
                                    setIsDialogOpen(false);
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>{' '}
            {isLoading ? (
                <div className="p-5">
                    <DataTableSkeleton
                        columnCount={50}
                        filterableColumnCount={2}
                        searchableColumnCount={1}
                    />
                </div>
            ) : (
                <>
                    {viewChange === 'tableView' ? (
                        <DataTable
                            columns={columns} // Ensure 'columns' is defined
                            data={data?.data}
                            pageCount={Math.ceil((data?.total || 0) / limit)} // Ensure 'limit' is defined
                        />
                    ) : (
                        <BatchCardcomponent
                            columns={[]}
                            data={data?.data}
                            handleViewDetails={handleViewDetails} // Ensure this function is defined
                            setSelectedBatchId={setSelectedBatchId}
                            pageCount={Math.ceil((data?.total || 0) / limit)} // Ensure 'limit' is defined
                        />
                    )}
                    <AlertDialog
                        open={!!selectedBatchId}
                        onOpenChange={(open) => !open && setSelectedBatchId(null)}>
                        <AlertDialogTrigger asChild>
                            <Button variant="outline" className="hidden" />
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This batch will be deleted permanently. Do you want to continue
                                    ?
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel onClick={() => setSelectedBatchId(null)}>
                                    Cancel
                                </AlertDialogCancel>
                                <AlertDialogAction onClick={handleDeleteBatch}>
                                    Continue
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </>
            )}
        </div>
    );
};

export default BatchList;
