import FacultyHeatmap from '@/components/charts/HeatMap';
import { Lollipop } from '@/components/charts/Lollipop';
import CustomSelect from '@/components/shared/custom-select';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import {
    useGetFacultyUtilization,
    useGetFacultyWeeklySchedule,
    useGetLocationsWithCounts,
} from '@/queries/dashboard-query';
import { useGetSchedules } from '@/queries/schedule-query';
import * as Plot from '@observablehq/plot';
import Color from 'color';
import * as d3 from 'd3';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

function unroll(rollup: any, keys: any, label = 'value', p = {}) {
    return Array.from(rollup, ([key, value]): any =>
        value instanceof Map
            ? unroll(value, keys.slice(1), label, Object.assign({}, { ...p, [keys[0]]: key }))
            : Object.assign({}, { ...p, [keys[0]]: key, [label]: value })
    ).flat();
}
interface WeeklySchedule {
    faculty: string;
    value: number;
    first_name: string;
    last_name: string;
    color_code: string;
    day: string;
}

interface FacultyUtilization {
    faculty_code: string;
    utilization_percentage: number;
    full_name: string;
    color_code: string;
}

const FacultyWeeklyClasses = ({ data }: { data?: any }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (data == null) return;
        const plot = Plot.plot({
            width: 400,
            height: 4000,
            style: {
                fontFamily: 'Inter',
                fontSize: '0.75rem',
            },
            x: {
                axis: null,
            },
            y: {
                // padding: 0,
                tickFormat: Plot.formatWeekday('en'),
                tickSize: 0,
                domain: [1, 2, 3, 4, 5, 6, 0], // change the order of week days to start with Monday
            },
            color: {
                scheme: 'reds',
                legend: true,
                label: 'Lectures',
                domain: [0, 6],
            },
            fx: {
                tickFormat: Plot.formatMonth('en'),
            },
            facet: {
                data: data,
                y: (d: any) => d.faculty_code,
                x: (d: any) => d.date.getUTCMonth(),
            },
            marks: [
                Plot.cell(data, {
                    x: (d) => d3.timeMonday.count(d3.utcMonth(d.date), d.date), // x value based on week of month (Monday based)
                    y: (d) => d.date.getUTCDay(), // y value based on day of the week
                    fill: 'count',
                    tip: true,
                    title: (d) => `${d.date}: ${d.count}`,
                }),
                Plot.text(data, {
                    x: (d) => d3.timeMonday.count(d3.utcMonth(d.date), d.date), // x value based on week of month (Monday based)
                    y: (d) => d.date.getUTCDay(), // y value based on day of the week
                    text: 'count',
                }),
                // Plot.frame(),
            ],
        });
        if (containerRef && containerRef.current) {
            containerRef.current.append(plot);
        }
        return () => plot.remove();
    }, [data]);

    return <div ref={containerRef} />;
};

const colors = [
    '#5aa9e6',
    '#f25f5c',
    '#f4a259',
    '#9f86c0',
    '#60d394',
    '#fcca46',
    '#adc178',
    '#9cafb7',
    '#9f86c0',
    '#a3a380',
];
const facultyData = [
    { faculty: 'Dr. Scott', day: 'Monday', value: 3 },
    { faculty: 'Dr. Scott', day: 'Tuesday', value: 5 },
    { faculty: 'Dr. Scott', day: 'Wednesday', value: 4 },
    { faculty: 'Dr. Scott', day: 'Thursday', value: 2 },
    { faculty: 'Dr. Scott', day: 'Friday', value: 1 },
    { faculty: 'Dr. Scott', day: 'Saturday', value: 0 },
    { faculty: 'Dr. Scott', day: 'Sunday', value: 3 },
];
function DashboardPage() {
    const [selectedRange, setSelectedRange] = useState<any>('30');
    const [facultyUtilizationChartData, setFacultyUtilizationChartData] = useState<any[]>([]);
    const [weeklyScheduleChartData, setWeeklyScheduleChartData] = useState<any[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<string | ''>('');

    const { mutate: fetchFacultyUtilization, data: facultyUtilization } =
        useGetFacultyUtilization();

    const { mutate: fetchWeeklySchedules, data: weeklySchedules } = useGetFacultyWeeklySchedule();
    useEffect(() => {
        fetchFacultyUtilization(selectedLocationId);
    }, [selectedRange, selectedLocationId]);
    useEffect(() => {
        fetchWeeklySchedules(selectedLocationId);
    }, [selectedRange, selectedLocationId]);

    useEffect(() => {
        console.log('weeklySchedules--', weeklySchedules);

        const data = _.map(weeklySchedules, (item: WeeklySchedule) => ({
            faculty: item.faculty, // Trim to remove any extra spaces
            value: item.value,
            full_name: item.first_name + ' ' + item.last_name,
            color_code: item.color_code,
            day: item.day,
        }));
        console.log('weeklydata-----------------------', data);
        setWeeklyScheduleChartData(data);
    }, [weeklySchedules, selectedLocationId]);
    useEffect(() => {
        console.log('facultyUtilization----------', facultyUtilization);

        const data = _.map(facultyUtilization, (item: FacultyUtilization) => ({
            name: item.faculty_code, // Trim to remove any extra spaces
            value: item.utilization_percentage,
            full_name: item.full_name,
            color_code: item.color_code,
        }));
        console.log('facul;tyutdata-----------------------', data);

        setFacultyUtilizationChartData(data);
    }, [facultyUtilization, selectedLocationId]);
    const { data: scheduleList, refetch: refetchSchedule } = useGetSchedules({
        location_id: selectedLocationId,
        stream_code: 'j/m',
        starting_date: moment().add(-15, 'days').format('YYYY-MM-DD'),
        ending_date: moment().format('YYYY-MM-DD'),
    });
    const { data: locationList = [], refetch: refetchLocationList } = useGetLocationsWithCounts();
    console.log('locationList--', locationList);
    useEffect(() => {
        refetchSchedule();
    }, [selectedLocationId]);
    if (!scheduleList) {
        return <>Loading</>;
    }
    const scheduleByFacultyNDate = d3.rollup(
        scheduleList,
        (v) => v.length,
        (d: any) => d.faculty.faculty_code,
        (d: any) => d.date
    );
    const cdata1 = unroll(scheduleByFacultyNDate, ['faculty_code', 'date'], 'count');

    const data = cdata1.map((o: any) => {
        return { ...o, date: new Date(o.date) };
    });
    return (
        <>
            {/* <FacultyWeeklyClasses data={data}></FacultyWeeklyClasses> */}
            <div className="flex mt-2  space-x-2 overflow-auto scrollbar-hide bg-background">
                {locationList?.map((item: any, index: number) => (
                    <Card className="max-w-xs shadow-md rounded border-2">
                        <CardContent className="flex p-3  bg-muted">
                            <div className="flex flex-col justify-center items-center w-full h-full bg-muted/40">
                                <h4
                                    className="text-sm font-semibold leading-none text-center cursor-pointer"
                                    onClick={() => setSelectedLocationId(item?.location_id)}>
                                    {item?.location_name}
                                </h4>
                            </div>
                        </CardContent>
                        <CardFooter className="flex flex-row border-t p-2 bg-background">
                            <div className="flex w-full items-center gap-1">
                                <div className="grid flex-1 auto-rows-min gap-0.5 space-y-1 ">
                                    <div className="text-xs  text-foreground  leading-none">
                                        Lectures
                                    </div>
                                    <div className="flex items-baseline  text-primary gap-1 text-base font-semibold tabular-nums leading-none justify-center">
                                        {item?.schedule_count}
                                    </div>
                                </div>
                                <Separator orientation="vertical" className="mx-1 h-10 w-px" />
                                <div className="grid flex-1 auto-rows-min gap-0.5 space-y-1">
                                    <div className="text-xs text-foreground leading-none">
                                        Batches
                                    </div>
                                    <div className="flex items-baseline gap-1  text-primary text-base font-semibold tabular-nums leading-none justify-center">
                                        {item?.batches_count}
                                    </div>
                                </div>
                                <Separator orientation="vertical" className="mx-1 h-10 w-px" />
                                <div className="grid flex-1 auto-rows-min gap-0.5 space-y-1">
                                    <div className="text-xs  text-foreground  leading-none">
                                        Faculties
                                    </div>
                                    <div className="flex items-baseline gap-1  text-primary text-base font-semibold tabular-nums leading-none justify-center">
                                        {item?.faculties_count}
                                    </div>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                ))}
            </div>
            <Card className="mt-2">
                <div className="flex">
                    <CardHeader className="p-2">
                        <CardTitle className="flex p-0  text-lg">Faculties Utilization</CardTitle>
                        <CardDescription>
                            Showing Faculties's Lectures Utilization for the last {selectedRange}{' '}
                            days
                        </CardDescription>
                    </CardHeader>
                </div>

                <CardContent className="bg-white">
                    <Lollipop data={facultyUtilizationChartData} height={250} />
                </CardContent>
            </Card>

            <Card className="mt-2">
                <div className="flex">
                    <CardHeader className="p-2">
                        <CardTitle className="flex p-0  text-lg">Weekly Utilization</CardTitle>
                        <CardDescription>
                            Showing Faculties's Lectures for Current Week
                        </CardDescription>
                    </CardHeader>
                </div>

                <CardContent className="bg-white">
                    <FacultyHeatmap height={350} data={weeklyScheduleChartData} />
                </CardContent>
            </Card>
        </>
    );
}

export default DashboardPage;
