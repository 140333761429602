import MultiselectDropDown from '@/CustomComponents/MultiselectDropDown';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '../../components/ui/table';
import { Input } from '@/components/ui/input';
import { SubjectSessionValues, Faculty, Subject } from '@/types';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useToast } from '../../components/ui/use-toast';

import {
    useFetchSlotTimeData,
    useFetchFacultyData,
    useUpdateBatchData,
    useFetchSubjects,
} from '@/queries/batch-query';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import MultiSelectWithSearch from '@/CustomComponents/MultiselectDropDown';

type BatchConfigProps = {
    batch: any; // Define a proper type for batch if available
    onClose: () => void; // Function to close the dialog
    refetchBatchDeatils: () => void;
};

interface Option {
    value: string;
    label: string;
    disabled?: boolean; // Optional disabled property
}

const BatchConfig: React.FC<BatchConfigProps> = ({ batch, onClose, refetchBatchDeatils }) => {
    const { toast } = useToast();
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [selectedDaysCount, setSelectedDaysCount] = useState<string>('');
    const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
    const [subjectSessionValues, setSubjectSessionValues] = useState<SubjectSessionValues>({});
    const [selectedSubjects, setSelectedSubjects] = useState<Subject[]>([]);
    const [facultyOptions, setFacultyOptions] = useState<Option[]>([]);
    const [subjectOptions, setSubjectOptions] = useState<Option[]>([]);
    const [selectedFaculties, setSelectedFaculties] = useState<Option[]>([]);
    const [daysSelection, setDaysSelection] = useState<'fixed' | 'flexible'>('fixed');
    const [timeValidationMessage, setTimeValidationMessage] = useState<string | null>(null);
    const [facultyValidationMessage, setFacultyValidationMessage] = useState<string | null>(null);
    const [subjectSessionValidationMessage, setSubjectSessionValidationMessage] = useState<
        string | null
    >(null);
    const [daysValidationMessage, setDaysValidationMessage] = useState<string | null>(null);

    const { mutate: updateBatchData, isError, error, isSuccess } = useUpdateBatchData();

    const params = {
        page: 1,
        sortBy: 'updated_at',
        sortOrder: 'DESC',
        q: '',
        location_id: batch.locations[0].id,
        stream_code: batch.batch_stream[0].stream_code,
    };
    const { data: Slottimes } = useFetchSlotTimeData({});
    const { data: Faculties } = useFetchFacultyData(params);
    const { data: Subjects } = useFetchSubjects(params);

    const handleSelectDayRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as 'fixed' | 'flexible';
        setDaysSelection(value);
        if (value === 'fixed') {
            setSelectedDaysCount(''); // Clear selected days count when switching to fixed
        } else if (value === 'flexible') {
            setSelectedDays([]);
        }
    };
    const handleDayCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDaysCount(event.target.value);
    };
    const handleDaysCheckboxChange = (day: string) => {
        setSelectedDays((prev) =>
            prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );
    };
    const handleSlotTimeCardClick = (slotTime: string) => {
        setSelectedTimes((prevSelected) => {
            // Toggle the selected state of the clicked card
            const newSelectedTimes = prevSelected?.includes(slotTime)
                ? prevSelected.filter((timeValue) => timeValue !== slotTime) // Remove if already selected
                : [...prevSelected, slotTime]; // Add if not selected

            return newSelectedTimes;
        });
    };
    const handleFacultyOptionChange = (selectedOptions: Option[]) => {
        setSelectedFaculties(selectedOptions);
    };
    const subjectAddRow = () => {
        const newSubject = {
            id: Date.now().toString(),
            isDynamic: true,
            subject_name: '',
        };
        setSelectedSubjects((prev: any) => [...prev, newSubject]);
    };

    const handleSubjectChange = (subjectId: string, newSubjectId: string) => {
        // Logic to replace the old subject ID with the new subject ID
        setSelectedSubjects((prev) =>
            prev.map((subject) =>
                subject.id === subjectId ? { ...subject, id: newSubjectId } : subject
            )
        );
    };

    const removeSubjectRow = (subjectId: string) => {
        setSelectedSubjects((prev) => prev.filter((subject) => subject.id !== subjectId));
        setSubjectSessionValues((prev) => {
            const updated = { ...prev };
            delete updated[subjectId];
            return updated;
        });
    };
    const handleSubjectSessionDataChange = (
        subjectId: string,
        value: string,
        type: 'lecturePerWeek' | 'totalSubjectSession'
    ) => {
        // If value is empty, set it as empty string, otherwise parse the integer
        const parsedValue = value === '' ? '' : parseInt(value, 10);

        // Check if parsedValue is a valid number or empty string
        if (parsedValue === '' || !isNaN(parsedValue)) {
            setSubjectSessionValues((prevSessionValues) => {
                const updatedSessionValues = {
                    ...prevSessionValues,
                    [subjectId]: {
                        ...prevSessionValues[subjectId],
                        [type]: parsedValue,
                    },
                };
                return updatedSessionValues;
            });
        }
    };
    useEffect(() => {
        if (isSuccess || isError) {
            toast({
                title: isSuccess ? 'Batch Updated Successfully' : 'Batch Update Unsuccessful',
                description: isSuccess ? 'The batch was updated successfully.' : error?.message,
            });
            refetchBatchDeatils();
            onClose(); // Close modal or perform any action after either success or error
        }
    }, [isSuccess, isError, onClose, error]);
    useEffect(() => {
        if (Subjects && Array.isArray(Subjects)) {
            const options = Subjects.map((subject: Subject) => ({
                value: subject.id,
                label: `${subject.subject_code} - ${subject.subject_name}`,
                disabled: selectedSubjects.some((selected) => selected.id === subject.id), // Disable if already selected
            }));

            setSubjectOptions(options); // Update the subjectOptions with options that have the disabled property
        }
    }, [Subjects, selectedSubjects]); // Add selectedSubjects to the dependency array
    useEffect(() => {
        if (batch) {
            if (batch.selected_days_count && batch.selected_days_count !== '') {
                setDaysSelection('flexible'); // Set selection to flexible if days count is provided
                setSelectedDaysCount(batch?.selected_days_count || '');
            } else if (batch.selected_days && batch.selected_days.length > 0) {
                setDaysSelection('fixed'); // Set selection to fixed if specific days are selected
                setSelectedDays(batch?.selected_days || ''); // Set the selected days
            }
        }
    }, [batch]);

    useEffect(() => {
        if (batch && batch.batch_slots?.length > 0) {
            // Initialize arrays for slot times
            const morningSlot: string[] = batch?.batch_slots[0]?.slot_times || [];
            const afternoonSlot: string[] = batch?.batch_slots[1]?.slot_times || [];
            // Combine slot_times
            const combinedSlots = [...morningSlot, ...afternoonSlot];
            // Update state
            setSelectedTimes(combinedSlots);
        }
    }, [batch]);

    // Remove a row from the table

    useEffect(() => {
        if (Faculties && Array.isArray(Faculties)) {
            const options = Faculties.map((faculty: Faculty) => ({
                value: faculty.id,
                label:
                    `${faculty.faculty_code} - ${faculty.first_name} ${faculty.last_name}` +
                    (faculty.subject.length > 0
                        ? faculty.subject.map((sub) => ` (${sub.subject_name})`).join(', ')
                        : ''),
            }));

            setFacultyOptions(options);
        }
    }, [Faculties]); // Dependency on Faculties

    useEffect(() => {
        if (batch?.batch_stream && batch.batch_stream.length > 0) {
            const firstSelectedOption = batch?.batch_stream[0];
            if (firstSelectedOption.subject) {
                // Extracting subjects
                const subjects = firstSelectedOption?.subject.map((subject: any) => ({
                    id: subject.id,
                    subject_name: subject.subject_name,
                    subject_code: subject.subject_code,
                }));

                setSelectedSubjects(subjects);

                if (batch?.batch_subjects && batch.batch_subjects.length > 0) {
                    const updatedSessionValues: Record<
                        string,
                        { lecturePerWeek: number; totalSubjectSession: number } // Match the SessionValues type
                    > = {};

                    batch?.batch_subjects.forEach((subject: any) => {
                        updatedSessionValues[subject.subject_id] = {
                            lecturePerWeek: subject?.session_per_weeks,
                            totalSubjectSession:
                                subject?.total_session_of_year !== undefined
                                    ? subject.total_session_of_year
                                    : 0, // Provide a default value if undefined
                        };
                    });

                    setSubjectSessionValues(updatedSessionValues);

                    //agarr aadditional subjeject hai jo stram mai nahi hai to use setSelectedSubjects mai add kiya hai
                    const combinedSubjects = [
                        ...subjects,
                        ...Object.keys(updatedSessionValues)
                            .filter((id) => !subjects.some((subject: any) => subject.id === id))
                            .map((id) => ({
                                id,
                                subject_name:
                                    Subjects.find((subject: any) => subject.id === id)
                                        ?.subject_name || 'Unknown Subject',
                                subject_code:
                                    Subjects.find((subject: any) => subject.id === id)
                                        ?.subject_code || 'Unknown Code',
                            })),
                    ];

                    setSelectedSubjects(combinedSubjects);
                }
            }
        }
    }, [batch, Subjects]);
    useEffect(() => {
        if (batch?.faculties && Array.isArray(Faculties)) {
            // Ensure Faculties is an array
            const matchingFaculties = batch?.faculties
                .map((batchFaculty: any) => {
                    // Directly find the faculty in the Faculties array
                    const match = Faculties?.find(
                        (faculty: Faculty) => faculty.id === batchFaculty.id
                    );

                    // Return a formatted object or null if no match
                    return match
                        ? {
                              value: match.id,
                              label: `${match.faculty_code}-${match.first_name} ${match.last_name}`,
                          }
                        : null;
                })
                .filter(Boolean); // Remove nulls from the array
            setSelectedFaculties(matchingFaculties);
        } else {
            console.log('Either batch faculties or Faculties data is not available.');
        }
    }, [batch.faculties, Faculties]);

    // Conditional rendering based on data
    if (!Faculties) {
        console.error('Faculties data is not available');
        return null;
    }

    const handleSubmit = async () => {
        const timeData = selectedTimes.reduce<{ slot: string; slot_times: string[] }[]>(
            (acc, timeInterval) => {
                // Split the timeInterval to get the start time
                const [startTime] = timeInterval.split('-');
                // Extract the hour from the start time
                const [hours] = startTime.split(':');
                const startHour = parseInt(hours, 10);

                // Determine whether the time interval is in the morning or afternoon
                const slot = startHour < 12 ? 'morning' : 'afternoon';
                // Check if the slot already exists in the accumulator array
                const existingSlot = acc.find((item) => item.slot === slot);

                // If the slot exists, add the current timeInterval to its slot_times array
                if (existingSlot) {
                    existingSlot.slot_times.push(timeInterval);
                } else {
                    // If the slot doesn't exist, create a new entry for it with the current timeInterval
                    acc.push({ slot, slot_times: [timeInterval] });
                }

                // Return the updated accumulator
                return acc;
            },
            []
        );

        const facultyIds = selectedFaculties?.map((item) => item.value) || [];

        const SubjectSessionData = Object.entries(subjectSessionValues).map(
            ([subject_id, details]) => ({
                subject_id: subject_id,
                session_per_weeks: details.lecturePerWeek,
                total_sessions_of_year: details.totalSubjectSession,
            })
        );

        const isValidValue = (value: any) => value !== '' && !isNaN(Number(value));

        // Perform validation checks
        const hasValidationCheck = [
            !selectedTimes.length &&
                (setTimeValidationMessage('Please select at least one time slot. *'), true),
            !facultyIds.length &&
                (setFacultyValidationMessage('At least one faculty must be selected. *'), true),
            SubjectSessionData.some(
                ({ session_per_weeks, total_sessions_of_year }) =>
                    !isValidValue(session_per_weeks) || !isValidValue(total_sessions_of_year)
            ) &&
                (setSubjectSessionValidationMessage(
                    'Each subject session must have both session per week and total sessions of year filled in. *'
                ),
                true),
            selectedDays.length === 0 &&
                selectedDaysCount === '' &&
                (setDaysValidationMessage('The field is required. *'), true),
        ].some(Boolean);

        if (hasValidationCheck) return; // Prevent further execution if there are validation errors

        const postData = {
            location_id: batch?.locations[0]?.id,
            batch_code: batch?.batch_code,
            batch_type_id: Array.isArray(batch.batch_types)
                ? batch.batch_types.map((item: any) => item.id)
                : [batch.batch_types.id],
            batch_stream_id: batch?.batch_stream[0].id,
            faculty_id: facultyIds,
            starting_date: batch?.starting_date,
            duration: batch?.duration,
            subject_sessions: SubjectSessionData,
            duration_type: batch?.duration_type,
            slot: timeData,
            ...(selectedDays.length > 0 ? { selected_days: selectedDays } : {}), // Only include if not empty
            ...(selectedDays.length === 0 ? { selected_days_count: selectedDaysCount } : {}), // Include count if days are empty
            special_batch: batch?.special_batch,
        };

        try {
            await updateBatchData({ id: batch?.id, postData });
        } catch (err: any) {
            const errorMsg =
                err.response && err.response.data
                    ? err.response.data.message
                    : err.response.data.message;
            toast({
                title: 'Update Error',
                description: `There was an error updating the batch. \n${errorMsg}`,
                variant: 'destructive',
            });
            console.error(err);
        }
    };

    return (
        <div className="max-w-[880px]  sm:max-h-[860px] overflow-auto p-1 ">
            <label className="text-sm mb-1 mt-3 ">
                Faculty:<span className="text-red-500">*</span>
            </label>
            <MultiSelectWithSearch
                options={facultyOptions}
                placeholder="Select Faculties"
                value={selectedFaculties}
                onChange={handleFacultyOptionChange}
            />
            {facultyValidationMessage && (
                <p className=" text-red-600">{facultyValidationMessage}</p>
            )}

            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger>
                        <button
                            onClick={subjectAddRow}
                            className="mt-2 p-1 pl-3 pr-3 bg-blue-500 text-white  hover:scale-110  transition-transform duration-200">
                            +
                        </button>
                    </TooltipTrigger>
                    <TooltipContent className="absolute left-full ml-2 top-0 whitespace-nowrap">
                        <p>Add Subject</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            <Table className="mt-2 border-collapse border border-gray">
                <TableHeader>
                    <TableRow>
                        <TableCell style={{ width: '2vw' }}>Subject</TableCell>
                        <TableCell style={{ width: '8vw' }}>Lecture Per Week</TableCell>
                        <TableCell style={{ width: '8vw' }}>Total Lectures</TableCell>
                        {selectedSubjects.some((subject) => subject.isDynamic) && (
                            <TableCell style={{ width: '2vw' }}>Actions</TableCell>
                        )}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {selectedSubjects?.map((subject) => (
                        <TableRow key={subject.id}>
                            {/* <TableCell className="py-1">{subject.subject_name}</TableCell> */}

                            <TableCell className="py-1">
                                {subject?.isDynamic ? (
                                    <Select
                                        value={subject.id}
                                        onValueChange={(value: string) =>
                                            handleSubjectChange(subject.id, value)
                                        }>
                                        <SelectTrigger className="h-7 w-28">
                                            <SelectValue placeholder="Select" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {subjectOptions?.map((subjectOption) => (
                                                <SelectItem
                                                    key={subjectOption.value}
                                                    value={subjectOption.value}
                                                    disabled={subjectOption.disabled} // Use the disabled property here
                                                >
                                                    <div>
                                                        <p>{subjectOption.label}</p>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                ) : (
                                    subject.subject_name
                                )}
                            </TableCell>

                            <TableCell className="text-center py-1">
                                <Input
                                    type="text"
                                    placeholder="Enter Number"
                                    onChange={(e) =>
                                        handleSubjectSessionDataChange(
                                            subject.id,
                                            e.target.value,
                                            'lecturePerWeek'
                                        )
                                    }
                                    value={subjectSessionValues[subject.id]?.lecturePerWeek ?? ''}
                                    onKeyPress={(e) => e.key === '-' && e.preventDefault()}
                                    className="h-8 w-24"
                                />
                            </TableCell>
                            <TableCell className="py-0">
                                <Input
                                    type="text"
                                    placeholder="Enter Number"
                                    onChange={(e) =>
                                        handleSubjectSessionDataChange(
                                            subject.id,
                                            e.target.value,
                                            'totalSubjectSession'
                                        )
                                    }
                                    value={
                                        subjectSessionValues[subject.id]?.totalSubjectSession ?? ''
                                    }
                                    onKeyPress={(e) => e.key === '-' && e.preventDefault()}
                                    className="h-8 w-24"
                                />
                            </TableCell>
                            <TableCell className="text-center py-0">
                                {subject.isDynamic && (
                                    <button
                                        onClick={() => removeSubjectRow(subject.id)}
                                        className="text-red-700">
                                        Remove
                                    </button>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {subjectSessionValidationMessage && (
                <p className=" text-red-600">{subjectSessionValidationMessage}</p>
            )}

            <div>
                <label className="text-sm mb-1 mt-8 ">
                    Select Time:<span className="text-red-500">*</span>
                </label>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-2">
                    {/* Map through Slot Times to Render Cards */}
                    {Slottimes?.map((item) => (
                        <div
                            key={item.id}
                            onClick={() => handleSlotTimeCardClick(item?.slot_time)} // Handling card click correctly with unique `id`
                            className={`flex flex-col items-start p-1 bg-white shadow-lg cursor-pointer rounded-md transition-transform transform hover:scale-105 ${
                                selectedTimes.includes(item?.slot_time)
                                    ? 'border-2 border-blue-500'
                                    : ''
                            }`}
                            style={{ minWidth: '20px', minHeight: '20px' }} // Set fixed size for consistency
                        >
                            {/* Display Slot Time */}
                            <div className="text-sm">
                                <span className="text-xs  cursor-pointer">{item?.slot_time}</span>
                            </div>
                        </div>
                    ))}
                </div>
                {timeValidationMessage && <p className=" text-red-600">{timeValidationMessage}</p>}
            </div>

            <div className="mt-2">
                <div className=" flex mb-2">
                    <div className="flex items-center ">
                        <Input
                            type="radio"
                            id="fixed"
                            name="slotType"
                            value="fixed"
                            checked={daysSelection === 'fixed'}
                            onChange={handleSelectDayRadioChange}
                            className="form-radio h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500 mr-2"
                        />
                        <label htmlFor="fixed" className="text-sm font-medium">
                            Fixed
                        </label>
                    </div>
                    <div className="flex items-center  ml-2">
                        <Input
                            type="radio"
                            id="flexible"
                            name="slotType"
                            value="flexible"
                            checked={daysSelection === 'flexible'}
                            onChange={handleSelectDayRadioChange}
                            className="form-radio h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500 mr-2"
                        />
                        <label htmlFor="flexible" className="text-sm font-medium mr-2">
                            Flexible:
                        </label>
                    </div>
                </div>
                <Input
                    type="text"
                    id="dayCount"
                    name="dayCount"
                    className="form-input border-gray-300 focus:ring-blue-500 h-8 "
                    disabled={daysSelection !== 'flexible'}
                    // defaultValue={selectedDaysCount || ''}
                    value={selectedDaysCount === '' ? '' : selectedDaysCount} // Use value instead of defaultValue
                    onChange={handleDayCountChange} // Bind the change handler
                    placeholder="Select Day Count"
                />

                <div className="mt-1">
                    <label className="block mb-2">
                        Choose Days:<span className="text-red-500">*</span>
                    </label>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
                        {[
                            'Monday',
                            'Tuesday',
                            'Wednsaday',
                            'Thusday',
                            'Friday',
                            'Saturday',
                            'Sunday',
                        ].map((day) => (
                            <div key={day} className="flex items-center">
                                <Input
                                    type="checkbox"
                                    id={day}
                                    checked={selectedDays.includes(day)}
                                    onChange={() => handleDaysCheckboxChange(day)}
                                    disabled={daysSelection !== 'fixed'}
                                    className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500 mr-2"
                                />
                                <label htmlFor={day} className="text-sm">
                                    {day}
                                </label>
                            </div>
                        ))}
                    </div>
                    {daysValidationMessage && (
                        <p className=" text-red-600">{daysValidationMessage}</p>
                    )}
                </div>
                <div className="col-span-6 flex justify-end items-center mt-2">
                    <Button
                        className="bg-white-2 border border-gray-300 mr-2"
                        variant="secondary"
                        onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        className="bg-blue-500 text-white "
                        onClick={handleSubmit} // Attach the submit handler here
                    >
                        Update
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BatchConfig;
