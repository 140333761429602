import apiService, { get } from '@/lib/api';
import { QueryKey, UseQueryOptions, useQuery, useMutation } from '@tanstack/react-query';

export const useGetLocationsWithCounts = () => {
    // Construct URL based on params
    const url = `/getScheduleCountByLocation`;

    const queryKey: QueryKey = ['getLocationsWithCounts', url];

    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            const result = await get(url); // Replace with your fetch logic
            return result;
        },
    };
    const query = useQuery<any, Error>(options);

    return query;
};

// API function for fetching faculty weekly schedule
export async function getFacultyWeeklySchedule(location_ids?: string) {
    const url = location_ids
        ? `/getFacultyWeeklyScheduleCount?location_ids=${location_ids}`
        : `/getFacultyWeeklyScheduleCount`;

    try {
        const response = await apiService.get(url); // Replace with your fetch logic
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

// -------------------------------------------------------
export async function FacultyUtilization(location_ids?: string) {
    try {
        const url = location_ids
            ? `/calculateFacultyProgress?location_ids=${location_ids}`
            : `/calculateFacultyProgress`;
        const response = await apiService.get(url); // Replace with your fetch logic

        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const useGetFacultyUtilization = () => {
    return useMutation({
        mutationFn: (location_ids?: string) => FacultyUtilization(location_ids),
    });
};
export const useGetFacultyWeeklySchedule = () => {
    return useMutation({
        mutationFn: (location_ids?: string) => getFacultyWeeklySchedule(location_ids),
    });
};
