import React, { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../../components/ui/dialog'; // Replace with your actual dialog library imports
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
    ArrowUpDown,
    MoreHorizontal,
    Plus,
    PlusCircle,
    LayoutGrid,
    LayoutList,
} from 'lucide-react';
import { Button } from '../../components/ui/button';

import { useCommonSearch } from '@/components/shared/common-search';
import { DataTableSkeleton } from '@/components/shared/data-table-skeleton';
import DataTable from '@/components/shared/data-table';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Checkbox } from '@/components/ui/checkbox';
import { Toaster } from '@/components/ui/toaster';
import { Faculty } from '@/types';
import { useToast } from '@/components/ui/use-toast';
import FacultyCreate from './faculty-create';

import { QueryParams } from '@/lib/api';
import { useDeleteFacultyData, useGetFaculties } from '@/queries/faculty-query';
import { GetLocationList } from '@/common/functions';
import { LocationComponent, SubjectComponent } from '@/common/dropdowns';
import { useCommonProvider } from '@/providers/common-provider';
import FacultyCardComponent from './faculty-card-view';
import { GetSubjectList } from '@/common/functions';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const FacultyList = () => {
    const { toast } = useToast();
    const navigate = useNavigate();
    const [selectedFacultyId, setSelectedFacultyId] = useState<string | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { mutate: deleteFacultyData } = useDeleteFacultyData();
    const { selectedLocation, setSelectedLocation } = useCommonProvider();
    const { selectedSubject } = useCommonProvider();
    // const [searchParams, setSearchParams] = useSearchParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || 1);
    const limit = Number(searchParams.get('limit') || 10);
    const sortBy = String(searchParams.get('sortBy') || 'updated_at');
    const sortOrder = String(searchParams.get('sortOrder') || 'DESC');

    const { searchQuery } = useCommonSearch();
    const params: QueryParams = {
        page,
        limit,
        sortBy: sortBy,
        sortOrder: sortOrder,
        q: searchQuery || ' ',
        ...(selectedLocation && selectedLocation !== 'all' && { location_id: selectedLocation }), // Add location_id only if not "All Locations"
        ...(selectedSubject && { subject_ids: selectedSubject }),

        // location_id: '81ae752d-63e3-4a78-8299-df2a92d1ed54',
    };
    useEffect(() => {
        if (selectedLocation && selectedLocation !== 'all') {
            // Update the searchParams with the new location and reset the page to 0
            const updatedParams = {
                ...Object.fromEntries(searchParams.entries()), // Keep other existing params
                location_id: selectedLocation,
                page: '1', // Reset page to 0
            };
            setSearchParams(updatedParams);
        }
    }, [selectedLocation, setSearchParams, searchParams]);
    const viewParam = searchParams.get('view') || 'tableView';
    const [viewChange, setViewChange] = useState(viewParam);
    // setSelectedLocation('all');
    const handleViewToggle = (view: string) => {
        setViewChange(view);
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            view,
        });
    };

    const handleDeleteFaculty = () => {
        console.log(selectedFacultyId);

        if (!selectedFacultyId) return;

        deleteFacultyData(
            { id: selectedFacultyId },
            {
                onSuccess: () => {
                    toast({
                        title: 'Faculty Successfully Deleted',
                        description: 'The Faculty was deleted successfully!',
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                },
                onError: (error: any) => {
                    console.error('Deletion failed:', error);
                    toast({
                        title: 'Deletion Failed',
                        description: 'There was an error deleting the faculty.',
                    });
                },
            }
        );
        setSelectedFacultyId(null); // Reset selected batch ID
    };
    const handleViewDetails = (id: string) => {
        console.log('View details of faculty with ID:', id);
        navigate(`/faculties/${id}`);
    };
    const handleRowClick = (id: string) => {
        navigate(`/faculties/${id}`);
    };

    const { data, isLoading, isError } = useGetFaculties(params);
    console.log(data);
    if (isError) {
        return <div>Error loading data</div>;
    }

    const columns: ColumnDef<Faculty>[] = [
        {
            accessorKey: 'faculty_code',
            header: ({ column }) => (
                <div className="text-left">
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        Faculty Code
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                        {/* <FontAwesomeIcon icon={faSortUp} className="ml-2 h-4 w-4" /> */}
                    </Button>
                </div>
            ),
            cell: ({ row }) => (
                <div
                    className="cursor-pointer text-left px-4 py-2"
                    onClick={() => handleRowClick(row.original.id)}>
                    {row.original.faculty_code}
                </div>
            ),
        },
        {
            accessorKey: 'first_name',
            header: ({ column }) => (
                <div className="text-left">
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        First Name
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                        {/* <FontAwesomeIcon icon={faSortUp} className="ml-2 h-4 w-4" /> */}
                    </Button>
                </div>
            ),
            cell: ({ row }) => (
                <div
                    className="cursor-pointer text-left px-4 py-2"
                    onClick={() => handleRowClick(row.original.id)}>
                    {row.original.first_name}
                </div>
            ),
        },
        {
            accessorKey: 'last_name',
            header: ({ column }) => (
                <div className="text-left">
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        Last Name
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            ),
            cell: ({ row }) => (
                <div
                    className="cursor-pointer text-left px-4 py-2"
                    onClick={() => handleRowClick(row.original.id)}>
                    {row.original.last_name}
                </div>
            ),
        },
        {
            accessorKey: 'subject',
            header: () => <div className="text-left">Subject</div>,
            cell: ({ row }) => {
                const subjects = row.original.subject.map((subject) => {
                    return (
                        <div key={subject.id} className="flex items-center">
                            <div
                                className="w-3 h-3 rounded-sm mr-2"
                                style={{ backgroundColor: subject.subject_color_code }}></div>
                            {/* Subject name */}
                            <span>{subject.subject_name}</span>
                        </div>
                    );
                });
                return (
                    <div
                        className="cursor-pointer text-left"
                        onClick={() => handleRowClick(row.original.id)}>
                        {subjects}
                    </div>
                );
            },
        },
        {
            accessorKey: 'mail',
            header: ({ column }) => (
                <div className="text-left">
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        Email
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            ),
            cell: ({ row }) => (
                <div
                    className="cursor-pointer text-left px-4 py-2"
                    onClick={() => handleRowClick(row.original.id)}>
                    {row.original.mail}
                </div>
            ),
        },
        {
            // accessorKey: 'starting_date',
            // header: ({ column }) => (
            //     <Button
            //         variant="ghost"
            //         onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            //         Start Date
            //         <ArrowUpDown className="ml-2 h-4 w-4" />
            //     </Button>
            // ),
            accessorKey: 'phone',
            header: ({ column }) => (
                <div className="text-left">
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        Phone <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            ),
            cell: ({ row }) => (
                <div
                    className="cursor-pointer text-left px-4 py-2"
                    onClick={() => handleRowClick(row.original.id)}>
                    {row.original.phone}
                </div>
            ),
        },

        {
            accessorKey: 'experience',
            header: ({ column }) => (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Experience
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: ({ row }) => (
                <div className="cursor-pointer" onClick={() => handleRowClick(row.original.id)}>
                    {row.original.experience}
                </div>
            ),
        },

        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem
                            onClick={() => handleViewDetails(row.original.id)}
                            className=" cursor-pointer ">
                            View details
                        </DropdownMenuItem>

                        <DropdownMenuItem
                            onClick={() => setSelectedFacultyId(row.original.id)}
                            className=" cursor-pointer ">
                            Delete faculty
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            ),
        },
    ];

    return (
        <div className="w-full h-[calc(100vh-6rem)] ooverflow-auto p-4 bg-white relative border">
            <Toaster />
            <div className="flex justify-between mb-4 items-center">
                <div className="flex items-center space-x-2">
                    {' '}
                    {/* Container for dropdowns */}
                    <div className="mr-2">
                        <GetLocationList />
                        <LocationComponent showLabel={false} />
                    </div>
                    <div className="mr-4">
                        <GetSubjectList />
                        <SubjectComponent showLabel={false} />
                    </div>
                </div>
                {/* toggling views */}
                <div className="flex items-center space-x-1 ml-auto">
                    {/* Table View Icon */}
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                className={`p-2 rounded ${viewChange === 'tableView' ? 'bg-gray-300' : 'bg-white'} hover:bg-gray-200`}
                                onClick={() => handleViewToggle('tableView')}>
                                <LayoutList className="h-6 w-6" />
                            </button>
                        </TooltipTrigger>
                        <TooltipContent side="top">List view</TooltipContent>
                    </Tooltip>
                    {/* Card View Icon */}
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                className={`p-1 rounded ${viewChange === 'cardView' ? 'bg-gray-300' : 'bg-white'} hover:bg-gray-200`}
                                onClick={() => handleViewToggle('cardView')}>
                                <LayoutGrid className="h-6 w-6" />
                            </button>
                        </TooltipTrigger>
                        <TooltipContent side="top">Card view</TooltipContent>
                    </Tooltip>
                </div>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogTrigger asChild>
                        <Button
                            variant="outline"
                            className="text-primary rounded shadow h-12 gap-1 h-10 w-30 mt-0 ml-4"
                            onClick={() => {
                                setIsDialogOpen(true);
                            }}>
                            <PlusCircle className="h-3.5 w-3.5 mr-2" />
                            New Faculty
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="mx-auto p-3">
                        <DialogHeader>
                            <DialogTitle>Create Faculty</DialogTitle>
                            <DialogDescription>Create a new faculty</DialogDescription>
                        </DialogHeader>
                        <div className="bg-slate-50">
                            <FacultyCreate
                                onClose={() => {
                                    setIsDialogOpen(false);
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>{' '}
            {isLoading ? (
                <div className="p-5">
                    <DataTableSkeleton
                        columnCount={10}
                        filterableColumnCount={2}
                        searchableColumnCount={1}
                    />
                </div>
            ) : (
                <>
                    {viewChange === 'tableView' ? (
                        <DataTable
                            columns={columns} // Ensure 'columns' is defined
                            data={data?.data}
                            pageCount={Math.ceil((data?.total || 0) / limit)} // Ensure 'limit' is defined
                        />
                    ) : (
                        <FacultyCardComponent
                            columns={[]}
                            data={data?.data}
                            handleViewDetails={handleViewDetails} // Ensure this function is defined
                            setSelectedFacultyId={setSelectedFacultyId}
                            pageCount={Math.ceil((data?.total || 0) / limit)} // Ensure 'limit' is defined
                        />
                    )}

                    <AlertDialog
                        open={!!selectedFacultyId}
                        onOpenChange={(open) => !open && setSelectedFacultyId(null)}>
                        <AlertDialogTrigger asChild>
                            <Button variant="outline" className="hidden" />
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This Faculty will be deleted permanently. Do you want to
                                    continue ?
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel onClick={() => setSelectedFacultyId(null)}>
                                    No
                                </AlertDialogCancel>
                                <AlertDialogAction onClick={handleDeleteFaculty}>
                                    Yes
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </>
            )}
        </div>
    );
};

export default FacultyList;
