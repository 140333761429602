import React, { useState, useEffect } from 'react';
import { Check } from 'lucide-react';

interface Location {
    id: string;
    name: string;
}

interface LocationSelectProps {
    locations: Location[];
    selectedLocations: string[];
    setSelectedLocations: (data: any) => void;
}

const LocationSelect: React.FC<LocationSelectProps> = ({
    locations,
    selectedLocations,
    setSelectedLocations,
}) => {
    useEffect(() => {
        // Initially, select all locations by default
        setSelectedLocations(locations.map((location) => location.id));
    }, [locations]);

    const toggleSelection = (id: string) => {
        setSelectedLocations((prev: string[]) =>
            prev.includes(id)
                ? prev.filter((locationId: string) => locationId !== id)
                : [...prev, id]
        );
    };

    const isSelected = (id: string) => selectedLocations.includes(id);

    return (
        <div className="grid grid-rows-3 grid-flow-col gap-6 p-6">
            {locations.map((location) => (
                <div
                    key={location.id}
                    className={`p-3 rounded shadow-lg cursor-pointer transition-all duration-300 hover:shadow-2xl ${
                        isSelected(location.id)
                            ? 'bg-background border border-primary  scale-100'
                            : 'bg-background border border-gray-200 scale-95'
                    }`}
                    onClick={() => toggleSelection(location.id)}>
                    <div className="flex items-center justify-between">
                        <h3 className="text-base font-medium">{location.name}</h3>
                        <div
                            className={`w-6 h-6 border-2 flex justify-center items-center ${
                                isSelected(location.id) ? 'border-primary' : 'border-gray-300'
                            }`}>
                            {isSelected(location.id) && <Check className="text-primary w-4 h-4" />}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LocationSelect;
