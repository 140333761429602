import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { z } from 'zod';
import { useFetchLocationData } from '@/queries/location-query';
import { Button } from '@/components/ui/button';
import { User } from '@/types';

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../components/ui/select';
import { useToast } from '../../components/ui/use-toast';
import { Input } from '../../components/ui/input';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form';
import { useCreateUserData, useFetchUser, useUpdateUserData } from '@/queries/users-query';
import { useLoadingProvider } from '@/providers/loading-provider';
type UserSubmitProps = {
    onClose: () => void; // Function to close the dialog
    userId: any; // Change 'any' to a more specific type if possible
    refetch: () => void;
    setUserId: any;
};
const formSchema = z.object({
    username: z.string().min(1, { message: 'This field is required.' }), // Ensure the message is clear
    email: z
        .string()
        .min(1, { message: 'This field is required.' })
        .regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
            message: 'Invalid email address.',
        }),
    phoneNumber: z
        .string()
        .optional()
        .refine((val) => !val || /^\d{10}$/.test(val), {
            message: 'The contact number must be 10 digits.', // Only validate if non-empty
        }),
    location: z.string().min(1, { message: 'This field is required.' }), // Consistent message
});
const UserSubmit: React.FC<UserSubmitProps> = ({ userId, onClose, refetch, setUserId }) => {
    const { setLoading } = useLoadingProvider();

    const params = { page: 1, limit: 40, sortBy: 'updated_at', sortOrder: 'DESC', filter: '' };
    const { data: userGetByIdData } = useFetchUser(userId || '');
    const { toast } = useToast();
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: userGetByIdData?.user?.name || '',
            location: userGetByIdData?.user?.location?.id || '',
            email: userGetByIdData?.user?.email || '',
            phoneNumber: userGetByIdData?.user?.contact_number || '',
        },
    });
    const { handleSubmit, control, setValue } = form;

    useEffect(() => {
        if (userGetByIdData) {
            setValue('username', userGetByIdData?.user?.name || '');
            setValue('email', userGetByIdData?.user?.email || '');
            setValue('location', userGetByIdData?.user?.location?.id || '');
            setValue('phoneNumber', userGetByIdData?.user?.contact_number || '');
        }
    }, [userGetByIdData, setValue]);
    const { data: locationData } = useFetchLocationData(params);
    const {
        mutate: createUserData,
        isSuccess: createIsSuccess,
        isError: createIsError,
        error,
    } = useCreateUserData();
    const {
        mutate: updaeUserData,
        isSuccess: updateIsSuccess,
        isError: updateIsError,
        error: updateError,
    } = useUpdateUserData();
    const onSubmit = (data: any) => {
        console.log(data);
        const postData: User = {
            email: data?.email,
            contact_number: data?.phoneNumber, // Change here
            location_id: data?.location,
            name: data?.username,
        };
        console.log('postDAtaupdata', postData);
        try {
            if (userGetByIdData) {
                setLoading(true);
                // Update the batch if it exists
                updaeUserData({ id: userGetByIdData?.user?.id, postData });
            } else {
                setLoading(true);
                // Create a new batch if it does not exist
                createUserData(postData);
            }
        } catch (err) {
            toast({
                title: 'ERROR:',
                description: 'There was an error processing your request.',
            });
            setLoading(false);
        }
    };
    useEffect(() => {
        if (createIsSuccess) {
            toast({
                title: 'User Created Successfully',
                description: 'The user was created successfully.',
                variant: 'default', // Success variant
            });

            onClose(); // Close the modal only on success
            setLoading(false);
            refetch();
        } else if (createIsError) {
            if (createIsSuccess) {
                toast({
                    title: 'user Created Successfully',
                    description: 'The user was created successfully.',
                    variant: 'default', // Success variant
                });
                refetch();

                onClose(); // Close the modal only on success
            } else if (createIsError) {
                const typedError = error as any; // or as unknown
                let errorMsg = 'There was an error creating the user.';

                if (typedError.response) {
                    // const emailErrors = typedError.response.data.errors?.email;
                    const errors = typedError.response?.data?.errors;
                    errorMsg = Object.keys(errors)
                        .map((field) => ` ${errors[field].join(', ')}`)
                        .join('\n');
                } else {
                    errorMsg = error.message || errorMsg;
                }
                toast({
                    title: 'ERROR:',
                    variant: 'destructive',
                    description: errorMsg,
                });
            }
            setLoading(false);
        }
    }, [createIsSuccess, onClose, error]);

    useEffect(() => {
        if (updateIsSuccess) {
            toast({
                title: 'User Updated Successfully',
                description: 'The user was updated successfully.',
                variant: 'default', // Success variant
            });
            setLoading(false);
            refetch();

            onClose(); // Close the modal only on success
        } else if (updateIsError) {
            const typedError = updateError as any; // or as unknown

            if (typedError.response) {
                const emailErrors = typedError.response.data.errors;

                const errorMsg = Object.keys(emailErrors)
                    .map((field) => ` ${emailErrors[field].join(', ')}`)
                    .join('\n');
                toast({
                    title: 'User Update Unsuccessful',
                    description: errorMsg || 'There was an issue creating the user.',
                    variant: 'destructive', // Error variant
                });
            }
            setLoading(false);
        }
    }, [updateIsSuccess, updateIsError, onClose, updateError]);

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <div className="m-2">
                    <FormField
                        control={control}
                        name="username"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    Username:<span className="text-red-500">*</span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder="Name"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length > 0) {
                                                e.target.value =
                                                    value.charAt(0).toUpperCase() + value.slice(1);
                                            }
                                            field.onChange(e); // Call onChange from react-hook-form
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="m-2">
                    <FormField
                        control={control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    Email Address:<span className="text-red-500">*</span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        type="email"
                                        placeholder="Email address"
                                        autoComplete="off"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="m-2">
                    <FormField
                        control={control}
                        name="location"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    Location:<span className="text-red-500">*</span>
                                </FormLabel>
                                <Select onValueChange={field.onChange} value={field.value || ''}>
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Select location" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {locationData?.map((location: any) => (
                                            <SelectItem key={location.id} value={location.id}>
                                                <div>
                                                    <p>{location.name}</p>
                                                </div>
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="m-2">
                    <FormField
                        control={control}
                        name="phoneNumber"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Phone Number</FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        type="tel"
                                        placeholder="Phone Number"
                                        autoComplete="off"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="flex justify-end gap-2">
                    <Button
                        className="bg-white-2  btn border border-gray-300 mr-2"
                        variant="secondary"
                        type="button" // Change to type="button"
                        onClick={() => {
                            setLoading(false);
                            setUserId(''); // Clear the userId
                            onClose(); // Close the dialog or modal
                        }}>
                        Cancel
                    </Button>
                    <Button type="submit">Submit</Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default UserSubmit;
