import { Calendar, momentLocalizer, Event as CalEvent } from 'react-big-calendar';
import moment from 'moment';
import Color from 'color';
import '../big-calendar/big-calendar.css';
import { Edit, EllipsisVertical, X } from 'lucide-react';
import BigCalendarToolbar, { BigCalendarToolbarProps } from '../big-calendar/big-calendar-toolbar';
import { Button } from '@/components/ui/button';
import { handler } from 'tailwindcss-animate';
import CustomCalendarToolbar, { CustomCalendarToolbarProps } from './custom-calendar-toolbar';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

export interface Event extends CalEvent {
    id?: string;
    color?: string;
    schedule?: any;
    scheduleId?: string;
    hasSchedule?: boolean;
    isScheduleEditable?: boolean;
}

const EventView = ({ event, onEditEvent }: { event: any; onEditEvent: (data: any) => void }) => {
    function onEdit(records: any) {
        onEditEvent({ ...records.data, type: 'Leave' });
    }

    return (
        <div className="flex flex-col h-full">
            <div
                className="text-xs bg-background flex flex-col space-y-2  h-full overflow-auto"
                style={{
                    padding: '0',
                }}>
                {event?.records?.map((item: any, index: number) => (
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div
                                key={index}
                                className="p-2 border flex border-l-8"
                                style={{
                                    borderColor: Color(item?.color).hex(),
                                    color: Color(item?.color).darken(0.7).hex(),
                                    backgroundColor: Color(item?.color).lighten(0.95).hex(),
                                }}>
                                {item.time}
                                {item.time ? ':' : ''} {item.name}
                                {moment()
                                    .startOf('day')
                                    .isSameOrBefore(moment(event.start).startOf('day')) && (
                                    <div className="ml-auto flex space-x-1">
                                        <Edit size={12} onClick={() => onEdit(item)} />
                                    </div>
                                )}
                            </div>
                        </TooltipTrigger>
                        <TooltipContent>
                            <div className="flex flex-col space-y-2">
                                <div>
                                    {item.data.faculty.first_name} {item.data.faculty.last_name}
                                </div>
                                <div className="text-muted-foreground">
                                    Date: {item.data.dateArray}
                                </div>
                                <div className="text-muted-foreground">
                                    Type:{' '}
                                    {item.data.batch_slot_name.charAt(0).toUpperCase() +
                                        item.data.batch_slot_name.slice(1).toLowerCase()}
                                </div>
                                {/* Display the event title */}
                            </div>
                        </TooltipContent>
                    </Tooltip>
                ))}
            </div>
        </div>
    );
};

const CustomCalendar = ({
    events,
    backgroundEvents,
    onDateChange,
    currentDate,
    onAddEvent, // Add a prop for handling adding events
    onEditEvent, // Add a prop for handling adding events
}: {
    events: any[];
    backgroundEvents?: any[];
    onDateChange?: (date: Date) => void;
    currentDate?: Date;
    onAddEvent?: (data: any) => void; // Add this handler for adding events
    onEditEvent: (data: any) => void; // Add this handler for deleting events
}) => {
    const localizer = momentLocalizer(moment);
    const minTime = new Date();
    minTime.setHours(7, 0, 0, 0);
    moment.updateLocale('en', {
        week: {
            dow: 1, // Monday is the first day of the week
        },
    });

    const eventPropGetter = (event: any): { className: string; style: React.CSSProperties } => {
        return {
            className: `text-sm w-full`,
            style: {
                backgroundColor: '#fff',
                color: '#000',
                borderTopWidth: '0px',
                borderRightWidth: '0px',
                borderBottomWidth: '0px',
                borderLeftWidth: '0px',
            },
        };
    };
    const CustomSlotWrapper = (props: any) => {
        return (
            <div>
                {props.children}
                {moment()
                    .startOf('day')
                    .isSameOrBefore(moment(props.slotMetrics.groups[0][0]).startOf('day')) && (
                    <Button
                        onClick={() => onAddEvent && onAddEvent(props.slotMetrics.groups)}
                        variant={'outline'}
                        className="absolute sticky text-center justify-center flex p-2 w-full text-lg border-primary">
                        +
                    </Button>
                )}
            </div>
        );
    };
    const CustomEventWrapper = ({
        props,
        onEditHolidayEvent,
    }: {
        props: any;
        onEditHolidayEvent: (data: any) => void;
    }) => {
        function onEdit(records: any) {
            onEditHolidayEvent({ ...records, type: 'Holiday' });
        }
        return (
            <div className=" h-32 overflow-auto">
                {props.event.type === 'Leave'
                    ? props.children
                    : props.event?.records?.map((item: any, index: number) => (
                          <div className="p-2 shadow-md bg-foreground/10 rounded-lg border-0 border-l-8 border-foreground/60  mb-2">
                              <p className="text-base flex font-semibold">
                                  {item.name}{' '}
                                  {moment()
                                      .startOf('day')
                                      .isSameOrBefore(moment(props.event.start).startOf('day')) && (
                                      <div className="ml-auto  cursor-pointer">
                                          <Edit size={12} onClick={() => onEdit(item)} />
                                      </div>
                                  )}
                              </p>
                              <div className="flex flex-col text-xs text-muted-foreground">
                                  {item.description?.map((label: any, i: number) => (
                                      <p className="truncate" key={i}>
                                          {label}
                                      </p>
                                  ))}
                              </div>
                          </div>
                      ))}
                {/* <p className="text-base font-semibold">{props.event.records.name}</p>
                        <div>
                            {props.event.records.batch_slots.length > 0 ? (
                                props.event.records.batch_slots.map((slot: any) => (
                                    <p key={slot.id}>{slot.name}</p>
                                ))
                            ) : (
                                <p>All Day</p>
                            )}
                        </div> */}
            </div>
        );
    };
    return (
        <Calendar
            localizer={localizer}
            events={events}
            backgroundEvents={backgroundEvents}
            defaultView="week"
            tooltipAccessor={null}
            startAccessor="start"
            endAccessor="end"
            dayLayoutAlgorithm="no-overlap"
            className="p-4"
            style={{
                padding: '4px',
            }}
            formats={{
                dayFormat: (date, culture, lz) => localizer.format(date, 'DD, dddd', culture),
                weekdayFormat: (date, culture, lz) => localizer.format(date, 'dddd', culture),
                timeGutterFormat: (date, culture, lz) => localizer.format(date, 'h A', culture),
                dayRangeHeaderFormat: (range, culture, lz) =>
                    '' +
                    localizer.format(range.start, 'MMMM').toUpperCase() +
                    localizer.format(range.start, ' ( DD').toUpperCase() +
                    ' - ' +
                    localizer.format(range.end, 'DD ) YYYY').toUpperCase(),
            }}
            eventPropGetter={eventPropGetter}
            dayPropGetter={(date) => ({
                style: {},
                className: `text-sm w-full hover:bg-muted border min-h-[90%]`,
            })}
            step={12 * 60}
            timeslots={1}
            popup
            showMultiDayTimes
            views={['month', 'week', 'day', 'agenda']}
            date={currentDate || new Date()}
            onNavigate={(newDate) => {
                if (onDateChange) {
                    onDateChange(newDate);
                }
            }}
            components={{
                toolbar: (props) => (
                    <CustomCalendarToolbar {...(props as CustomCalendarToolbarProps)} />
                ),
                event: ({ event }) => <EventView event={event} onEditEvent={onEditEvent} />, // Pass props properly
                eventContainerWrapper: CustomSlotWrapper, // Use custom slot wrapper component here
                eventWrapper: (props) => (
                    <CustomEventWrapper props={props} onEditHolidayEvent={onEditEvent} />
                ),
            }}
        />
    );
};

export default CustomCalendar;
