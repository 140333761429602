import React, { useEffect, useRef, useState, useCallback, memo } from 'react';
import * as d3 from 'd3';

const MARGIN = { top: 20, right: 30, bottom: 70, left: 50 };

type Tooltip = {
    visible: boolean;
    value?: { faculty: string; day: string; value: number };
    x: number;
    y: number;
};

type FacultyHeatmapProps = {
    containerWidth?: number;
    height: number;
    data: { faculty: string; day: string; value: number }[];
};

const FacultyHeatmap: React.FC<FacultyHeatmapProps> = ({ containerWidth = 0, height, data }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [tooltip, setTooltip] = useState<Tooltip | null>(null);

    const daysOfWeek = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
    ];

    const faculties = Array.from(new Set(data.map((d) => d.faculty)));

    const cellWidth = (containerWidth - MARGIN.left - MARGIN.right) / faculties.length;
    const cellHeight = (height - MARGIN.top - MARGIN.bottom) / daysOfWeek.length;

    const colorScale = d3
        .scaleSequential<string>(d3.interpolateBlues)
        .domain([0, d3.max(data, (d) => d.value) || 1]);

    const handleMouseEnter = useCallback(
        (faculty: string, day: string, value: number, event: React.MouseEvent<HTMLDivElement>) => {
            if (containerRef.current) {
                const tooltipWidth = 20;
                const tooltipHeight = 20;
                const scrollTop = window.scrollY || document.documentElement.scrollTop; // Get the vertical scroll position
                // Adjust position relative to container
                const containerRect = containerRef.current.getBoundingClientRect();

                // Calculate tooltip position relative to the container and scroll position
                const x = event.clientX - containerRect.left - tooltipWidth / 2;
                const y = event.clientY - containerRect.top - tooltipHeight; // Center above the mouse

                // Adjust x to keep the tooltip within the viewport
                const adjustedX = Math.min(
                    window.innerWidth - tooltipWidth - 10, // 10px from right edge
                    Math.max(0, x) // Prevent going off left
                );

                // Prevent going off top
                const adjustedY = Math.max(0, y);

                setTooltip({
                    visible: true,
                    value: { faculty, day, value },
                    x: adjustedX,
                    y: adjustedY,
                });
            }
        },
        []
    );

    const handleMouseLeave = () => setTooltip(null);

    return (
        <div
            ref={containerRef}
            className="flex flex-row overflow-x-auto"
            style={{ height: `${height}px`, position: 'relative' }}>
            <DaysColumn daysOfWeek={daysOfWeek} cellHeight={cellHeight} />
            <div>
                <div className="flex flex-row">
                    {faculties.map((faculty, index) => (
                        <FacultyHeader key={index} faculty={faculty} cellHeight={cellHeight} />
                    ))}
                </div>
                {daysOfWeek.map((day, dayIndex) => (
                    <div key={day} className="flex flex-row" style={{ height: `${cellHeight}px` }}>
                        {faculties.map((faculty, facultyIndex) => {
                            const value =
                                data.find((d) => d.faculty === faculty && d.day === day)?.value ||
                                0;

                            return (
                                <Cell
                                    key={faculty}
                                    faculty={faculty}
                                    day={day}
                                    value={value}
                                    color={colorScale(value)}
                                    cellHeight={cellHeight}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                />
                            );
                        })}
                    </div>
                ))}
            </div>

            {tooltip && tooltip.visible && tooltip.value && <TooltipComponent tooltip={tooltip} />}
        </div>
    );
};

// Separate component for rendering the days column
const DaysColumn = memo(
    ({ daysOfWeek, cellHeight }: { daysOfWeek: string[]; cellHeight: number }) => (
        <div className="flex-col sticky left-0 bg-background">
            <div className="flex w-20" style={{ height: `${cellHeight}px` }}>
                <div className="flex justify-center items-center">
                    <span className="text-xs"></span>
                </div>
            </div>
            {daysOfWeek.map((day) => (
                <div key={day} className="flex w-20" style={{ height: `${cellHeight}px` }}>
                    <div className="flex justify-center items-center">
                        <span className="text-xs">{day}</span>
                    </div>
                </div>
            ))}
        </div>
    )
);

// Separate component for each faculty header
const FacultyHeader = memo(({ faculty, cellHeight }: { faculty: string; cellHeight: number }) => (
    <div
        className="flex justify-center items-center border-b-2 border-gray-400"
        style={{ minWidth: '60px', height: `${cellHeight}px` }}>
        <span className="text-xs">{faculty}</span>
    </div>
));

// Separate component for each cell in the heatmap
const Cell = memo(
    ({
        faculty,
        day,
        value,
        color,
        cellHeight,
        onMouseEnter,
        onMouseLeave,
    }: {
        faculty: string;
        day: string;
        value: number;
        color: string;
        cellHeight: number;
        onMouseEnter: (
            faculty: string,
            day: string,
            value: number,
            event: React.MouseEvent<HTMLDivElement>
        ) => void;
        onMouseLeave: () => void;
    }) => (
        <div
            className="flex justify-center items-center border border-white transition duration-200 ease-in-out cursor-pointer"
            style={{ minWidth: '60px', height: `${cellHeight}px`, backgroundColor: color }}
            onMouseEnter={(event) => onMouseEnter(faculty, day, value, event)}
            onMouseLeave={onMouseLeave}>
            <span className="text-xs">{value}</span>
        </div>
    )
);

// Tooltip Component
const TooltipComponent = memo(({ tooltip }: { tooltip: Tooltip }) => {
    if (!tooltip?.value) return null;
    if (tooltip.x < 0) tooltip.x = 0; // Prevent going off left
    if (tooltip.y < 0) tooltip.y = 0; // Prevent going off top
    return (
        <div
            className="absolute bg-white border border-gray-300 rounded-lg shadow-lg p-2 z-30 w-40"
            style={{
                left: tooltip.x,
                top: tooltip.y,
                pointerEvents: 'none',
                transform: 'translate(-50%, -100%)', // Center above the cursor
            }}>
            <p className="text-sm font-semibold">{tooltip.value.faculty}</p>
            <p className="text-sm">{tooltip.value.day}</p>
            <p className="text-sm">{tooltip.value.value}</p>
        </div>
    );
});

export default FacultyHeatmap;
