import {
    BookOpenIcon,
    CalendarDays,
    Home,
    LogOut,
    Users,
    GraduationCapIcon,
    UserCog,
} from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useAuth } from '@/providers/auth-provider';
import { Link, NavLink } from 'react-router-dom';

function SideNav() {
    const { Logout } = useAuth();
    const handleLogout = () => {
        Logout();
    };

    return (
        <aside className="fixed inset-y-0 left-0 z-30 hidden w-14 flex-col border-r bg-background sm:flex">
            <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
                <Link
                    to={'/'}
                    className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base">
                    <img
                        src="/logo.png"
                        alt="Logo"
                        className="h-8 w-8 transition-all group-hover:scale-110"
                    />
                    <span className="sr-only">Acme Inc</span>
                </Link>

                <Tooltip>
                    <TooltipTrigger asChild>
                        <NavLink
                            to={'/dashboard'}
                            className="flex mt-5 h-9 w-9 items-center justify-center bg-accent  rounded-lg text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8 aria-[current=page]:bg-primary aria-[current=page]:text-background aria-[current=page]:rounded-full">
                            <Home className="h-4 w-4  transition-all group-hover:scale-110" />
                            <span className="sr-only">Dashboard</span>
                        </NavLink>
                    </TooltipTrigger>
                    <TooltipContent side="right">Dashboard</TooltipContent>
                </Tooltip>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <NavLink
                            to={'/'}
                            className="flex h-9 w-9 items-center justify-center bg-accent  rounded-lg text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8 aria-[current=page]:bg-primary aria-[current=page]:text-background aria-[current=page]:rounded-full">
                            <CalendarDays className="h-4 w-4 transition-all group-hover:scale-110" />
                            <span className="sr-only">Schedules</span>
                        </NavLink>
                    </TooltipTrigger>
                    <TooltipContent side="right">Schedules</TooltipContent>
                </Tooltip>

                <Tooltip>
                    <TooltipTrigger asChild>
                        <NavLink
                            to="/batches"
                            className="flex h-9 w-9 items-center justify-center rounded-lg bg-accent text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8 aria-[current=page]:bg-primary  aria-[current=page]:text-background aria-[current=page]:rounded-full">
                            <BookOpenIcon className="h-5 w-5" />
                            <span className="sr-only">Batch</span>
                        </NavLink>
                    </TooltipTrigger>
                    <TooltipContent side="right">Batches</TooltipContent>
                </Tooltip>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <NavLink
                            to="/faculties"
                            className="flex h-9 w-9 items-center justify-center rounded-lg bg-accent text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8 aria-[current=page]:bg-primary  aria-[current=page]:text-background aria-[current=page]:rounded-full">
                            <GraduationCapIcon className="h-5 w-5" />
                            <span className="sr-only">Faculty</span>
                        </NavLink>
                    </TooltipTrigger>
                    <TooltipContent side="right">Faculties</TooltipContent>
                </Tooltip>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <NavLink
                            to={'/leaves'}
                            className="flex h-9 w-9 items-center justify-center rounded-lg bg-accent text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8 aria-[current=page]:bg-primary  aria-[current=page]:text-background aria-[current=page]:rounded-full">
                            <UserCog className="h-5 w-5" />
                            <span className="sr-only">Leaves and Holidays</span>
                        </NavLink>
                    </TooltipTrigger>
                    <TooltipContent side="right">Leaves and Holidays</TooltipContent>
                </Tooltip>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <NavLink
                            to={'/users'}
                            className="flex h-9 w-9 items-center justify-center bg-accent  rounded-lg text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8 aria-[current=page]:bg-primary aria-[current=page]:text-background aria-[current=page]:rounded-full">
                            <Users className="h-4 w-4 transition-all group-hover:scale-110" />
                            <span className="sr-only">Users</span>
                        </NavLink>
                    </TooltipTrigger>
                    <TooltipContent side="right">Users</TooltipContent>
                </Tooltip>
            </nav>
            <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
                <Tooltip>
                    <TooltipTrigger asChild>
                        <button
                            onClick={handleLogout}
                            className="flex h-9 w-9 items-center justify-center rounded-lg  bg-accent text-accent-foreground transition-colors hover:text-foreground  md:h-8 md:w-8">
                            <LogOut className="h-5 w-5" />
                            <span className="sr-only">Logout</span>
                        </button>
                    </TooltipTrigger>
                    <TooltipContent side="right">Logout</TooltipContent>
                </Tooltip>
            </nav>
        </aside>
    );
}
export default SideNav;
