import { convertTimeTo12HourFormat } from '@/common/functions';
import { Card, CardContent } from '@/components/ui/card';
import { useCommonProvider } from '@/providers/common-provider';
import moment from 'moment';
import React from 'react';
import { ScheduleDetails } from './schedule';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { X } from 'lucide-react';

export const FaciltyList: React.FC<{ faculty: { id: string; faculty_code: string } }> = React.memo(
    ({ faculty }) => {
        const { selectedDate } = useCommonProvider();
        return (
            <div
                className={`w-14 h-full text-xs m-0.5 p-1.5 text-center flex flex-col justify-center hover:font-bold hover:underline underline-offset-4`}>
                <a
                    href={`/faculties/${faculty.id}?date=${moment(selectedDate?.from).format(
                        'YYYY-MM-DD'
                    )}`}
                    target="_blank"
                    key={faculty.id}
                    rel="noreferrer">
                    <span>{faculty?.faculty_code}</span>
                </a>
            </div>
        );
    }
);
export const DateCard: React.FC<{
    date: { day: number; label: string; date: Date };
    blankSlots?: any;
}> = React.memo(({ date }) => {
    return (
        <Card className="flex flex-col border-0  shadow-none mb-2">
            <CardContent
                className={`border-0  w-48  h-auto p-1.5 bg-muted text-center  flex-col items-center justify-center`}>
                <div className="flex h-12 justify-center flex-col border text-primary shadow">
                    <span className="text-sm font-medium m-2">
                        {moment(date.date).format('Do MMM, dddd')}
                    </span>
                </div>
            </CardContent>
        </Card>
    );
});
export const FacultyTimesCard: React.FC<any> = React.memo(
    ({ faculties = [], dates = [], scheduleData = [], handleCardClick, refresh }) => {
        console.log(scheduleData, faculties);
        if (scheduleData) {
            return (
                <div className="inline-flex p-2 pt-0 pl-0 relative">
                    <div className="flex flex-col sticky top-0 left-0 z-20">
                        <div className="flex sticky  top-0 z-50">
                            <div className=" sticky left-0 z-30 bg-muted h-auto w-14 m-0.5  mb-2">
                                <span className="w-full h-full flex text-center items-center justify-center">
                                    {/* <img className="w-12 h-12" src={`/images/${type}.png`} alt={type} /> */}
                                </span>
                            </div>
                            <div className="sticky top-0 z-10">
                                <div className="flex">
                                    <div className="flex">
                                        {dates.map((date: { day: any; label: any; date: Date }) => (
                                            <DateCard
                                                key={`date_${date.day}_${date.label}`}
                                                date={date}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sticky top-0 left-0 flex flex-col bg-muted space-y-1">
                            {faculties?.map((faculty: any) => (
                                <div className="flex">
                                    <div className="sticky bg-background  left-0 z-10 items-center justify-center">
                                        <FaciltyList
                                            key={`faculty_${faculty.id}`}
                                            faculty={faculty}
                                        />
                                    </div>
                                    <div className="flex">
                                        <div className="flex">
                                            {scheduleData[faculty.id]?.schedules?.map(
                                                (date: any) => (
                                                    <Card className="flex flex-col border-0 border-l-2  shadow-none">
                                                        <CardContent
                                                            className={`border-0  w-48  h-auto p-1  text-center  flex-col items-center justify-center`}>
                                                            <div className="grid grid-cols-5 gap-1 text-primary px-2">
                                                                {date?.schedules?.map(
                                                                    (schedule: any) => (
                                                                        <Popover>
                                                                            <PopoverTrigger asChild>
                                                                                <span
                                                                                    className="w-5 h-5 border-2 cursor-pointer"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            schedule?.type ===
                                                                                            'doubt_session'
                                                                                                ? '#d5d5d5'
                                                                                                : schedule
                                                                                                        ?.subject
                                                                                                        ?.subject_color_code
                                                                                                  ? schedule
                                                                                                        ?.subject
                                                                                                        ?.subject_color_code
                                                                                                  : '',
                                                                                    }}></span>
                                                                            </PopoverTrigger>
                                                                            <PopoverContent className="relative w-[28rem] z-50 bg-background shadow-2xl border-2 p-4">
                                                                                <PopoverClose className="ml-auto">
                                                                                    <X />
                                                                                </PopoverClose>
                                                                                {schedule?.type !==
                                                                                'doubt_session' ? (
                                                                                    <ScheduleDetails
                                                                                        item={
                                                                                            schedule
                                                                                        }
                                                                                        onEdit={
                                                                                            handleCardClick
                                                                                        }
                                                                                        refresh={
                                                                                            refresh
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </PopoverContent>
                                                                        </Popover>
                                                                    )
                                                                )}
                                                            </div>{' '}
                                                        </CardContent>
                                                    </Card>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex h-full flex-col mt-1"></div>
                    </div>
                </div>
            );
        }
        return null;
    }
);
