import React, { useEffect, useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { string, z } from 'zod';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { DatePicker } from '@/components/ui/date-picker';
import { useCommonProvider } from '@/providers/common-provider';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { useMutation } from '@tanstack/react-query';
import { useSubmitSchedule } from '@/queries/schedule-query';
import moment from 'moment';
import CustomSelect from '@/components/shared/custom-select';
import { group } from 'console';
import CreateSlotTime from './create-slot-time';

const ScheduleFormSchema = z.object({
    date: z.date().refine((val) => val instanceof Date, {
        message: 'Date is required',
    }),
    batch_id: z.string().min(1, 'Batch is required'),
    faculty_id: z.string().min(1, 'Faculty is required'),
    subject_id: z.string().min(1, 'Subject is required'),
    slot_time: z.string().min(1, 'Slot Time is required'),
});

type FormData = z.infer<typeof ScheduleFormSchema>;
interface ScheduleFormDefaultValues {
    date: Date;
    batch_id: string;
    slot_time: string;
    faculty_id: string;
    subject_id: string;
    id: string | undefined;
}

export const ScheduleForm = ({
    isOpen,
    onClose,
    setIsOpen,
    defaultValues,
    batchList,
    facultyList,
    timeSlotList,
}: {
    isOpen: boolean;
    onClose: (data: any) => void;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    defaultValues: ScheduleFormDefaultValues;
    batchList: any[];
    facultyList: any[];
    timeSlotList: any[];
}) => {
    const { toast } = useToast();
    const { selectedLocation } = useCommonProvider();
    const formMethods = useForm<FormData>({
        resolver: zodResolver(ScheduleFormSchema),
        defaultValues: defaultValues || {
            date: new Date(),
            batch_id: '',
            slot_time: '',
            faculty_id: '',
            subject_id: '',
        },
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = formMethods;

    const [batches, setBatches] = useState<any[]>([]);
    const [faculties, setFaculties] = useState<any[]>([]);
    const [subjects, setSubjects] = useState<any[]>([]);
    const [newSlotTime, setNewSlotTime] = useState<any[]>([]);

    const handleSlotTimeChange = useCallback(
        (value: string) => {
            formMethods.setValue('slot_time', value);
            setBatches(
                batchList.filter((batch: any) =>
                    batch.batch_slots.some((slot: any) => slot.slot_times.includes(value))
                )
            );
        },
        [batchList, formMethods]
    );

    const handleBatchChange = useCallback(
        (value: string) => {
            formMethods.setValue('batch_id', value);
            formMethods.setValue('faculty_id', '');
            formMethods.setValue('subject_id', '');
            const selectedBatch = batchList.find((batch: any) => batch.id === value);
            setFaculties(selectedBatch?.faculties || []);
        },
        [batchList, formMethods]
    );

    const handleFacultyChange = useCallback(
        (value: string) => {
            formMethods.setValue('faculty_id', value);
            const selectedFaculty = facultyList.find((faculty: any) => faculty.id === value);
            setSubjects(selectedFaculty?.subject || []);
        },
        [facultyList, formMethods]
    );

    useEffect(() => {
        if (isOpen) {
            reset(defaultValues); // Reset the form with default values on modal open
            handleSlotTimeChange(defaultValues.slot_time);
            handleBatchChange(defaultValues.batch_id);
            handleFacultyChange(defaultValues.faculty_id);
        } else {
            setSubjects([]);
        }
    }, [isOpen, reset, defaultValues]);

    useEffect(() => {
        if (subjects.length === 1) {
            formMethods.setValue('subject_id', subjects[0]['id']);
        } else {
            formMethods.setValue('subject_id', '');
        }
    }, [subjects, formMethods]);

    const { mutate } = useMutation<any>({
        mutationFn: useSubmitSchedule,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            onClose([{ add: data.data }]); // Close the dialog after submission
        },
        onError: (error: any) => {
            console.log(error);
            toast({
                title: error?.response?.data?.message || 'Invalid data',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });

    const onSubmit = async (value: any) => {
        try {
            value.location_id = selectedLocation;
            value.date = moment(value.date).format('YYYY-MM-DD');
            const isFacultyInBatch = faculties.some((faculty) => faculty.id === value.faculty_id);
            if (defaultValues.id) {
                // Update operation
                await mutate({
                    ...value,
                    id: defaultValues.id,
                    isBatchFaculty: isFacultyInBatch,
                });
            } else {
                // Create operation
                await mutate({ ...value, isBatchFaculty: isFacultyInBatch });
            }
        } catch (error: any) {
            console.error('Error submitting form:', error.message);
        }
    };
    const handleNewSlotTime = async (time: any) => {
        const { startTime, endTime } = time;

        // Parse the start and end times
        const startMoment = moment(startTime, 'HH:mm');
        const endMoment = moment(endTime, 'HH:mm');
        // Create the formatted output
        const newSlot = {
            originalTime: `${startMoment.format('HH:mm')}-${endMoment.format('HH:mm')}`,
            time: `${startMoment.format('h:mm A')} - ${endMoment.format('h:mm A')}`,
            startTime: startMoment.format('h:mm A'),
            endTime: endMoment.format('h:mm A'),
        };
        setNewSlotTime((prev: any) => {
            const updatedSlots = [...prev, ...timeSlotList];
            const isUnique = !updatedSlots.some(
                (slot: any) => slot.originalTime === newSlot.originalTime
            );
            if (isUnique) {
                return [...prev, newSlot];
            }
            return [...prev];
        });
        handleSlotTimeChange(newSlot.originalTime);
    };
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-md p-4">
                <DialogHeader>
                    <DialogTitle>Create Schedule</DialogTitle>
                    <DialogDescription>Fill out the form below:</DialogDescription>
                </DialogHeader>
                <FormProvider {...formMethods}>
                    <form className="space-y-2 p-3 bg-muted/40">
                        <div className="grid grid-cols-1 gap-4">
                            <FormField
                                control={control}
                                name="date"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel htmlFor="date">
                                            Date<span className="text-red-500">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <DatePicker
                                                initialDate={field.value}
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                }}
                                            />
                                        </FormControl>
                                        <FormMessage>{errors.date?.message}</FormMessage>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={control}
                                name="slot_time"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel htmlFor="slot_time" className="flex">
                                            Slot Time<span className="text-red-500">*</span>
                                            <CreateSlotTime onSubmit={handleNewSlotTime} />
                                        </FormLabel>
                                        <FormControl>
                                            <CustomSelect
                                                options={[
                                                    ...(timeSlotList ?? []).map((item: any) => ({
                                                        value: item.originalTime,
                                                        label: item.time,
                                                        group: 'Existing Slots',
                                                    })),
                                                    ...(newSlotTime ?? []).map((item: any) => ({
                                                        value: item.originalTime,
                                                        label: item.time,
                                                        group: 'New Slots',
                                                    })),
                                                ]}
                                                onChange={(value: any) => {
                                                    if (value !== field.value) {
                                                        field.onChange(value);
                                                        handleSlotTimeChange(value);
                                                    }
                                                }}
                                                selectedValues={field.value}
                                                label="Slot Time"
                                            />
                                        </FormControl>
                                        <FormMessage>{errors.slot_time?.message}</FormMessage>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={control}
                                name="batch_id"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel htmlFor="batch_id">
                                            Batch<span className="text-red-500">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <CustomSelect
                                                options={[
                                                    ...(batches ?? []).map((item: any) => ({
                                                        value: item.id,
                                                        label: item.batch_code,
                                                        group: 'Slot Batches',
                                                    })),
                                                    // Add other sources if needed, for example:
                                                    ...(
                                                        batchList?.filter(
                                                            (batch) =>
                                                                !batches.some(
                                                                    (b) => b.id === batch.id
                                                                )
                                                        ) ?? []
                                                    ).map((item: any) => ({
                                                        value: item.id,
                                                        label: item.batch_code,
                                                        group: 'Other Batches',
                                                    })),
                                                ]}
                                                onChange={(value: any) => {
                                                    if (value !== field.value) {
                                                        field.onChange(value);
                                                        handleBatchChange(value);
                                                    }
                                                }}
                                                selectedValues={field.value}
                                                label="Batch"
                                            />
                                        </FormControl>
                                        <FormMessage>{errors.batch_id?.message}</FormMessage>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={control}
                                name="faculty_id"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel htmlFor="faculty_id">
                                            Faculty<span className="text-red-500">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <CustomSelect
                                                options={[
                                                    ...(faculties ?? []).map((item: any) => ({
                                                        value: item.id,
                                                        label: item.facultyLabel,
                                                        group: 'Batch Faculties',
                                                    })),
                                                    // Add other sources if needed, for example:
                                                    ...(
                                                        facultyList?.filter(
                                                            (faculty) =>
                                                                !faculties.some(
                                                                    (f) => f.id === faculty.id
                                                                )
                                                        ) ?? []
                                                    ).map((item: any) => ({
                                                        value: item.id,
                                                        label: item.facultyLabel,
                                                        group: 'Other Faculties',
                                                    })),
                                                ]}
                                                onChange={(value: any) => {
                                                    if (value !== field.value) {
                                                        field.onChange(value);
                                                        handleFacultyChange(value);
                                                    }
                                                }}
                                                selectedValues={field.value}
                                                label="Faculty"
                                            />
                                        </FormControl>
                                        <FormMessage>{errors.faculty_id?.message}</FormMessage>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={control}
                                name="subject_id"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel htmlFor="subject_id">
                                            Subject<span className="text-red-500">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <CustomSelect
                                                options={subjects?.map((item: any) => ({
                                                    value: item.id,
                                                    label: item.subject_name,
                                                }))}
                                                onChange={(value: any) => {
                                                    if (value !== field.value) {
                                                        field.onChange(value);
                                                    }
                                                }}
                                                selectedValues={field.value}
                                                label="Subject"
                                            />
                                        </FormControl>
                                        <FormMessage>{errors.subject_id?.message}</FormMessage>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </form>
                </FormProvider>
                <DialogFooter>
                    <Button type="button" variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="button" onClick={handleSubmit(onSubmit)} variant="default">
                        Submit
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
