import React, { useState, useEffect } from 'react';
import { Popover, PopoverTrigger, PopoverContent } from '@radix-ui/react-popover';
import { Button } from '@/components/ui/button'; // Adjust import paths as necessary
import { Check, ChevronUp, ChevronDown, X } from 'lucide-react'; // Import cross (X) and clear (Trash) icons
import { cn } from '@/lib/utils'; // Adjust import paths as necessary
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@/components/ui/command';

interface Option {
    value: string;
    label: string;
    group?: string; // Optional property for grouping
}

interface OptionSelectProps {
    options: Option[];
    selectedValues?: string | string[];
    onChange: (values: string | string[]) => void;
    label: string;
    placeHolder?: string;
    width?: string;
    multiple?: boolean; // Add a prop to handle multiple selection mode
    removable?: boolean; // Add a prop to handle multiple selection mode
}

const MAX_VISIBLE_SELECTIONS = 3; // Limit visible selections

const CustomSelect: React.FC<OptionSelectProps> = ({
    options,
    selectedValues = '',
    onChange,
    multiple = false,
    placeHolder = '',
    label,
    width = '100%',
    removable = true,
}) => {
    const [localSelectedValues, setLocalSelectedValues] = useState<string | string[]>(
        selectedValues || (multiple ? [] : '')
    );
    const [popoverOpen, setPopoverOpen] = useState(false); // Track the open state of the popover

    const handleSelect = (value: string) => {
        if (multiple) {
            if (Array.isArray(localSelectedValues)) {
                if (localSelectedValues.includes(value)) {
                    setLocalSelectedValues((prev: any) => prev.filter((v: any) => v !== value));
                } else {
                    setLocalSelectedValues((prev: any) => [...prev, value]);
                }
            } else {
                setLocalSelectedValues([value]);
            }
        } else {
            setLocalSelectedValues(value);
        }
    };

    const handleClear = () => {
        setLocalSelectedValues(multiple ? [] : '');
    };

    const handleRemoveValue = (value: string) => {
        if (multiple && Array.isArray(localSelectedValues)) {
            setLocalSelectedValues(localSelectedValues.filter((v) => v !== value));
        }
    };

    // Effect to sync localSelectedValues with selectedValues prop
    useEffect(() => {
        if (multiple && Array.isArray(selectedValues)) {
            if (!arraysEqual(localSelectedValues as string[], selectedValues)) {
                setLocalSelectedValues(selectedValues);
            }
        } else if (localSelectedValues !== selectedValues) {
            setLocalSelectedValues(selectedValues);
        }
    }, [selectedValues, multiple]);

    // Effect to notify parent component of localSelectedValues change
    useEffect(() => {
        onChange(
            multiple
                ? localSelectedValues
                : Array.isArray(localSelectedValues)
                  ? localSelectedValues[0]
                  : localSelectedValues
        );
        !multiple && setPopoverOpen(false);
    }, [localSelectedValues]);

    const arraysEqual = (a: any[], b: any[]) => {
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    };

    const groupedOptions = options.reduce((groups: Record<string, Option[]>, option) => {
        const group = option.group || ''; // Default group if none specified
        if (!groups[group]) groups[group] = [];
        groups[group].push(option);
        return groups;
    }, {});

    // Render the selected values
    const renderSelectedValues = () => {
        if (!multiple || !Array.isArray(localSelectedValues)) {
            return (
                options.find((option) => option.value === localSelectedValues)?.label ||
                `Select ${label}`
            );
        }
        const visibleValues = localSelectedValues.slice(0, MAX_VISIBLE_SELECTIONS);
        const extraCount = localSelectedValues.length - MAX_VISIBLE_SELECTIONS;
        if (visibleValues?.length === 0) {
            return (
                <span className="h-6 m-0.5">{placeHolder ? placeHolder : 'Select ' + label}</span>
            );
        }
        return (
            <div className="grid grid-cols-4 gap-2  justify-center">
                {visibleValues.map((value) => (
                    <div key={value} className="flex items-center bg-muted p-1">
                        <span className="truncate ">
                            {options.find((option) => option.value === value)?.label}
                        </span>
                        <span className="cursor-pointer" onClick={() => handleRemoveValue(value)}>
                            <X className="h-4 w-4 " />
                        </span>
                    </div>
                ))}
                {extraCount > 0 && (
                    <span className="flex items-center">
                        +{extraCount} {label}
                    </span>
                )}
            </div>
        );
    };

    return (
        <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
            <PopoverTrigger asChild className="w-34">
                <Button
                    variant="outline"
                    role="combobox"
                    className={cn(
                        'justify-between',
                        'flex items-center space-x-2 hover:bg-background'
                    )}
                    style={{ width: width }}>
                    {renderSelectedValues()}
                    <div className="flex items-center space-x-2">
                        {localSelectedValues?.length > 0 && removable && (
                            <span className="cursor-pointer" onClick={handleClear}>
                                <X className="h-4 w-4 cursor-pointer" />
                            </span>
                        )}
                        {popoverOpen ? (
                            <ChevronUp className="h-4 w-4 shrink-0 opacity-50" />
                        ) : (
                            <ChevronDown className="h-4 w-4 shrink-0 opacity-50" />
                        )}
                    </div>
                </Button>
            </PopoverTrigger>
            <PopoverContent className={`p-0 shadow-2xl border`} style={{ width: width }}>
                <Command>
                    <CommandInput placeholder={`Search ${label}...`} />
                    <CommandList>
                        <CommandEmpty>No {label} found.</CommandEmpty>
                        {Object.keys(groupedOptions).map((group) => (
                            <CommandGroup key={group} heading={group}>
                                {groupedOptions[group].map((option) => (
                                    <CommandItem
                                        value={option.label}
                                        key={option.value}
                                        onSelect={() => handleSelect(option.value)}>
                                        <Check
                                            className={cn(
                                                'mr-2 h-4 w-4',
                                                multiple
                                                    ? Array.isArray(localSelectedValues) &&
                                                      localSelectedValues.includes(option.value)
                                                        ? 'opacity-100'
                                                        : 'opacity-0'
                                                    : option.value === localSelectedValues
                                                      ? 'opacity-100'
                                                      : 'opacity-0'
                                            )}
                                        />
                                        {option.label}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        ))}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};

export default CustomSelect;
