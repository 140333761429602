import { useEffect, useState } from 'react';
import { GetLocationList } from '@/common/functions';
import { useCommonProvider } from '@/providers/common-provider';
import { Check } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { useGetDoubtSessions } from '@/queries/doubt-query';
import { startOfWeek, endOfWeek, addWeeks, subWeeks } from 'date-fns';
import { DateRange } from 'react-day-picker';
import moment from 'moment';
import _ from 'lodash';

interface CarouselProfileCardProps {
    item: any;
    selectedLocations: string[];
    setSelectedLocations: (data: any) => void;
}
const CarouselProfileCard: React.FC<CarouselProfileCardProps> = ({
    item,
    selectedLocations,
    setSelectedLocations,
}) => {
    const toggleSelection = (id: string) => {
        setSelectedLocations((prev: string[]) =>
            prev.includes(id)
                ? prev.filter((locationId: string) => locationId !== id)
                : [...prev, id]
        );
    };
    const isSelected = (id: string) => selectedLocations.includes(id);

    return (
        <div className="pt-1 basis-0 ">
            <div className="p-1">
                <div
                    key={item.id}
                    className={`p-3 rounded  cursor-pointer transition-all duration-300`}
                    onClick={() => toggleSelection(item.id)}>
                    <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium">{item.name}</h3>
                        <div
                            className={`w-6 h-6 border-2 flex justify-center items-center ${
                                isSelected(item.id) ? 'border-primary' : 'border-gray-300'
                            }`}>
                            {isSelected(item.id) && <Check className="text-primary w-4 h-4" />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function DoubtSession() {
    const { locationList } = useCommonProvider();
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [events, setEvents] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const date = searchParams.get('date');
    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>();
    useEffect(() => {
        if (date) {
            const startDate = startOfWeek(date, { weekStartsOn: 1 });
            const endDate = endOfWeek(date, { weekStartsOn: 1 });
            setSelectedDate({
                from: startDate,
                to: endDate,
            });
        }
    }, [date]);
    useEffect(() => {
        setSelectedLocations(locationList.map((location: any) => location.id));
    }, [locationList]);
    const { data: doubtSessionList, refetch: refetchDoubtSessionList } = useGetDoubtSessions(
        selectedLocations && selectedDate
            ? {
                  location_ids: selectedLocations.join(','),
                  starting_date: moment(selectedDate.from).format('YYYY-MM-DD'),
                  ending_date: moment(selectedDate.to).format('YYYY-MM-DD'),
              }
            : {}
    );
    useEffect(() => {
        if (doubtSessionList) {
            const records = _.map(doubtSessionList.data, (event) => ({
                id: event.id,
                title: `${event.faculty.first_name} ${event.faculty.last_name} - ${event.location.name}`,
                start: new Date(`${event.date}T${event.start_time}`),
                end: new Date(`${event.date}T${event.end_time}`),
                session: event,
                isSessionEditable:
                    new Date(event.date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0),
                locationName: event.location.name,
                date: event.date,
                color: 'gray',
            }));
            setEvents(records);
        }
    }, [doubtSessionList]);
    return (
        <div className="flex flex-col md:flex-row gap-2">
            {/* Sidebar (Left section) */}
            <div className="w-full md:w-1/3 lg:w-1/5 h-[calc(100vh-6rem)] p-4 bg-background border overflow-auto">
                {/* Sidebar content */}
                <GetLocationList />
                <div className="flex justify-between items-center mt-4">
                    <label className="text-base font-medium text-center leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 mb-1 ml-1">
                        Locations
                    </label>
                    <div className="ml-1 flex justify-center">
                        {selectedLocations.length === locationList.length ? (
                            <button
                                className="px-2 py-1  rounded"
                                onClick={() => setSelectedLocations([])}>
                                Deselect All
                            </button>
                        ) : (
                            <button
                                className="px-2 py-1 rounded"
                                onClick={() =>
                                    setSelectedLocations(locationList.map((loc: any) => loc.id))
                                }>
                                Select All
                            </button>
                        )}
                    </div>
                </div>
                <div className="flex flex-col overflow-auto justify-center rounded  my-2 flex-grow ml-1  scrollbar-hide">
                    {locationList?.map((item: any) => (
                        <CarouselProfileCard
                            setSelectedLocations={setSelectedLocations}
                            selectedLocations={selectedLocations}
                            item={item}
                            key={item.id}
                        />
                    ))}
                </div>
            </div>
            <div className="w-full md:w-2/3 lg:w-4/5 h-[calc(100vh-6rem)] overflow-auto p-4 bg-background"></div>
        </div>
    );
}
export default DoubtSession;
