import _ from 'lodash';
import moment from 'moment';
import { DateRange } from 'react-day-picker';

export function getScheduleSuggestions(
    errorSchedule: any,
    batchData: any[],
    schedules: any[],
    allFacultyData: any[],
    time: string[],
    selectedDate: any
): any[] | null {
    return suggestFacultyOrSwap();

    function suggestFacultyOrSwap(): any[] | null {
        const { date, slot_time, batch_id } = errorSchedule;

        // Get the batch and associated faculties
        const batch = _.find(batchData, { id: batch_id });
        if (!batch) {
            return null;
        }

        const faculties = batch.faculties || [];
        if (faculties.length === 0) {
            console.error(`No faculties found for batch ID ${batch_id}.`);
            return null;
        }

        // Collect all suggestions
        const suggestions: any[] = [];

        // Check for possible swaps
        const possibleSwap = suggestSwap();
        if (possibleSwap) {
            suggestions.push({
                suggestionType: 'Swap',
                swapWith: {
                    faculty_name: `${possibleSwap.faculty.first_name} ${possibleSwap.faculty.last_name}`,
                    faculty_code: possibleSwap.faculty.faculty_code,
                    date: possibleSwap.date,
                    slot_time: possibleSwap.slot_time,
                    id: possibleSwap.id,
                },
                swapTo: {
                    faculty_name: `${errorSchedule.faculty.first_name} ${errorSchedule.faculty.last_name}`,
                    faculty_code: errorSchedule.faculty.faculty_code,
                    date: errorSchedule.date,
                    slot_time: errorSchedule.slot_time,
                    id: errorSchedule.id,
                },
                faculty_name: '',
                faculty_code: '',
                faculty: undefined,
            });
        }

        const suitableFaculties = findSuitableFaculty(faculties, date, slot_time);
        suggestions.push(
            ...suitableFaculties.map((suitable) => ({
                suggestionType: suitable.suggestionType,
                faculty_name: `${suitable.faculty.first_name} ${suitable.faculty.last_name}`,
                faculty_code: suitable.faculty.faculty_code,
                faculty_id: suitable.faculty.id,
                subjects: suitable.faculty.subject,
            }))
        );

        return suggestions.length > 0 ? suggestions : null;
    }

    function isFacultyFree(faculty: any, date: string, slot_time?: string): boolean {
        const isSlotSpecified = slot_time !== null && slot_time !== undefined;
        const facultyHasSchedule = _.some(schedules, {
            faculty_id: faculty.id,
            date,
            ...(isSlotSpecified && { slot_time }),
        });

        return !facultyHasSchedule && isFacultyAvailable(faculty.id, date, slot_time);
    }

    function findSuitableFaculty(faculties: any[], date: string, slot_time: string): any[] {
        return faculties.reduce((acc: any[], faculty) => {
            if (isFacultyFree(faculty, date)) {
                acc.push({
                    suggestionType: 'Faculty Free All Day',
                    faculty,
                    faculty_name: '',
                    faculty_code: '',
                });
            } else if (isFacultyFree(faculty, date, slot_time)) {
                acc.push({
                    suggestionType: 'Faculty Free in Slot',
                    faculty,
                    faculty_name: '',
                    faculty_code: '',
                });
            }
            return acc;
        }, []);
    }

    function suggestSwap() {
        const { date, slot_time, faculty_id } = errorSchedule;

        const swapToSchedules = _.filter(
            findSwapSchedules(),
            (record) => !findSchedule(record.faculty_id, date, slot_time)
        );

        let swapFromSchedule = _.filter(swapToSchedules, (record) => {
            const isConflictWithOriginalFaculty = findSchedule(
                faculty_id,
                record.date,
                record.slot_time
            );
            const isFacultyOnLeave = !isFacultyAvailable(faculty_id, record.date, record.slot_time);
            const isBackToBack = checkBackToBackLecture(faculty_id, record.date, record.slot_time);

            return !isConflictWithOriginalFaculty && !isBackToBack && !isFacultyOnLeave;
        });

        if (swapFromSchedule.length === 0) {
            swapFromSchedule = _.filter(swapToSchedules, (record: any) => {
                const isConflictWithOriginalFaculty = findSchedule(
                    faculty_id,
                    record.date,
                    record.slot_time
                );
                const isFacultyOnLeave = !isFacultyAvailable(
                    faculty_id,
                    record.date,
                    record.slot_time
                );
                return !isConflictWithOriginalFaculty && !isFacultyOnLeave;
            });
        }

        return swapFromSchedule.length > 0 ? swapFromSchedule[0] : null;
    }

    function findSwapSchedules(): any[] {
        const { date, batch_id, faculty_id, id } = errorSchedule;
        const startDate = moment(selectedDate.from).startOf('day');
        const endDate = moment(selectedDate.to).endOf('day');
        return _.filter(schedules, (record) => {
            return (
                record.batch_id === batch_id &&
                record.id !== id &&
                record.faculty_id !== faculty_id &&
                moment(record.date).isBetween(startDate, endDate, null, '[]') &&
                isFacultyAvailable(record.faculty_id, record.date, record.slot_time)
            );
        });
    }

    function isFacultyAvailable(facultyId: number, date: string, slot_time?: string): boolean {
        const batch = _.find(batchData, { id: errorSchedule.batch_id });
        const facultyMap = _.mapValues(
            _.keyBy([...allFacultyData, ...batch.faculties], 'id'),
            (faculty) =>
                new Set(
                    _.flatMap(faculty.leave || [], (leave) =>
                        leave.dates.split(',').map((d: Date) => moment(d).format('YYYY-MM-DD'))
                    )
                )
        );

        const facultyLeaveDates = facultyMap[facultyId];
        return !facultyLeaveDates || !facultyLeaveDates.has(moment(date).format('YYYY-MM-DD'));
    }

    function findSchedule(faculty_id: number, date: string, slot_time?: string): boolean {
        return _.some(
            schedules,
            (record) =>
                record.date === date &&
                (!slot_time || record.slot_time === slot_time) &&
                record.faculty_id === faculty_id
        );
    }

    function checkBackToBackLecture(facultyId: number, date: string, slot_time: string): boolean {
        const { previous, next } = getPrevNextSlotTime(slot_time);

        const hasPreviousLecture = previous ? findSchedule(facultyId, date, previous) : false;
        const hasNextLecture = next ? findSchedule(facultyId, date, next) : false;

        return hasPreviousLecture || hasNextLecture;
    }

    function getPrevNextSlotTime(currentSlotTime: string) {
        const currentIndex = time.indexOf(currentSlotTime);
        if (currentIndex === -1) {
            return { previous: null, next: null };
        }

        const previousSlotTime = currentIndex > 0 ? time[currentIndex - 1] : null;
        const nextSlotTime = currentIndex < time.length - 1 ? time[currentIndex + 1] : null;

        return { previous: previousSlotTime, next: nextSlotTime };
    }
}
