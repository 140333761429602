import { useEffect } from 'react';
import { useGetLocations } from '@/queries/location-query';
import { useGetBatches } from '@/queries/batch-query';
import { useCommonProvider } from '../providers/common-provider';
import moment from 'moment';
import { useGetSlotTimes } from '@/queries/slot-time-query';
import { useGetSubjects } from '@/queries/subject-query';
import { useLocation } from 'react-router-dom';

const scheduleType: any = [
    { value: 'j/m', label: 'JEE / Medical', data: ['JEE', 'Medical'] },
    { value: 'j', label: 'JEE', data: ['JEE'] },
    { value: 'm', label: 'Medical', data: ['Medical'] },
    // { value: 'f', label: 'Foundation' },
];
export function GetLocationList() {
    const location = useLocation();
    const url = '/getlocation';
    const { data, isLoading } = useGetLocations(url);
    const { setLocationList } = useCommonProvider();
    useEffect(() => {
        if (data) {
            console.log(location);
            if (location.pathname === '/faculties' || location.pathname === '/batches') {
                const allLocationsOption = { id: 'all', name: 'All LOCATION' };
                setLocationList([allLocationsOption, ...data]);
            } else {
                setLocationList(data);
            }
        }
    }, [data, setLocationList]);

    return null;
}
export function GetSubjectList() {
    const url = '/getSubject'; // API endpoint for fetching subjects
    const { data, isLoading, isError } = useGetSubjects(url); // Fetch subject data using your custom hook

    const { setSubjectList } = useCommonProvider(); // Context or state setter for subjects

    useEffect(() => {
        if (data) {
            console.log('Fetched Subject Data:', data); // Log the fetched data
            setSubjectList(data); // Update the subject list in context or state when data is fetched
        }
    }, [data, setSubjectList]);

    return null; // Component does not render UI, similar to GetLocationList
}

export function GetBatchList() {
    const { setBatchList, selectedLocation, selectedScheduleType } = useCommonProvider();
    const { data, isLoading, refetch } = useGetBatches({
        sortBy: 'id',
        sortOrder: 'ASC',
        location_id: selectedLocation,
        stream_code: selectedScheduleType,
    });
    useEffect(() => {
        if (selectedLocation && selectedLocation !== undefined && selectedLocation !== '') {
            refetch();
        }
    }, [refetch, selectedLocation, selectedScheduleType]);
    useEffect(() => {
        if (data) {
            const batchesWithData = filterBatchesByStream(data.data, scheduleType).map(
                (batch: any) => ({
                    ...batch,
                    faculties: batch.faculties.map((faculty: any) => ({
                        ...faculty,
                        facultyLabel:
                            faculty?.faculty_code +
                            '- ' +
                            faculty?.first_name +
                            ' ' +
                            faculty?.last_name +
                            ' ( ' +
                            faculty?.subject
                                ?.map((subject: any) => subject.subject_code)
                                .join(', ') +
                            ' )',
                    })),
                })
            );
            setBatchList(batchesWithData);
        }
    }, [data, setBatchList, refetch]);
    const filterBatchesByStream = (batches: any[], streamCode: string) => {
        // Find the matching stream entry in streamMap by value
        const streamEntry = scheduleType.find((entry: any) => entry.value === streamCode);
        if (!streamEntry) {
            return batches; // Return all batches if streamCode is not found
        }
        const selectedStreams = streamEntry.data;
        console.log(selectedStreams);
        return batches.filter((batch) => {
            const streamNames = batch.batch_stream.map((stream: any) => stream.stream_names);
            return selectedStreams.some((stream: any) => streamNames.includes(stream));
        });
    };
    return null;
}

export function GetScheduleTypeList() {
    const { setScheduleTypeList } = useCommonProvider();
    useEffect(() => {
        setScheduleTypeList(scheduleType);
    }, []); // Empty dependency array to run once on mount

    return null;
}

export function GetSlotTimeList() {
    const { setTimeSlotList } = useCommonProvider();
    const { data, refetch } = useGetSlotTimes(`/getSlotTime`);
    useEffect(() => {
        if (data) {
            const timesArray = data?.map((slot: any) => {
                const [startTime, endTime] = slot.slot_time.split('-');
                const formattedStartTime = formatTime(startTime);
                const formattedEndTime = formatTime(endTime);
                return {
                    originalTime: slot.slot_time,
                    time: `${formattedStartTime} - ${formattedEndTime}`,
                    startTime: formattedStartTime,
                    endTime: formattedEndTime,
                };
            });
            setTimeSlotList(timesArray);
        }
    }, [data, setTimeSlotList, refetch]);
    return null;
}

export function generateDateArray(startDate: Date | undefined, endDate: Date | undefined) {
    if (!startDate || !endDate) {
        return [];
    }

    const dayLabels = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    const start = moment(startDate);
    const end = moment(endDate);
    const dateArray: any[] = [];

    let currentDate = moment(start);

    while (currentDate <= end) {
        dateArray.push({
            day: currentDate.date(),
            date: currentDate.format('YYYY-MM-DD'),
            label: dayLabels[currentDate.day()],
        });
        currentDate = currentDate.add(1, 'day');
    }

    return dateArray;
}
export function calculateValueFromDates(
    startDate: string | Date,
    endDate: string | Date,
    conversionRatePerDay: number
): number {
    const startDateObj = moment(startDate);
    const endDateObj = moment(endDate);

    let daysDifference = 0;
    const currentDate = startDateObj.clone();

    while (currentDate.isSameOrBefore(endDateObj)) {
        if (currentDate.day() !== 0) {
            // 0 is Sunday
            daysDifference++;
        }
        currentDate.add(1, 'day');
    }

    const calculatedValue = daysDifference * conversionRatePerDay;
    return calculatedValue;
}
// Function to convert time format using Moment.js
export function convertTimeTo24TimeFormat(timeString: string): string {
    const [startTime, endTime] = timeString.split('-');
    const format = 'h:mm A'; // Input format for AM/PM times

    const startFormatted = moment(startTime.trim(), format).format('HH:mm');
    const endFormatted = moment(endTime.trim(), format).format('HH:mm');

    return `${startFormatted}-${endFormatted}`;
}
// Function to convert a time range
export function convertTimeTo12HourFormat(timeRange: string): string {
    const [startTime, endTime] = timeRange.split('-');
    const format = 'HH:mm'; // Input format for AM/PM times

    const startFormatted = moment(startTime.trim(), format).format('hh:mm A');
    const endFormatted = moment(endTime.trim(), format).format('hh:mm A');

    return `${startFormatted}-${endFormatted}`;
}
// Utility function to format time
export function formatTime(time: string) {
    const [hours, minutes] = time.split(':');
    let formattedHours = parseInt(hours, 10);
    const suffix = formattedHours >= 12 ? 'PM' : 'AM';
    formattedHours = formattedHours % 12 || 12; // Convert midnight (00:00) to 12 AM

    return `${formattedHours}:${minutes} ${suffix}`;
}

export const getDatesBetween = (from: string, to: string): string[] => {
    const fromDate = moment(from);
    const toDate = moment(to);
    const dateArray: string[] = [];
    while (fromDate.isSameOrBefore(toDate)) {
        dateArray.push(fromDate.format('YYYY-MM-DD'));
        fromDate.add(1, 'days'); // Move to the next day
    }
    return dateArray;
};
