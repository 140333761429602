import { BookOpenText, CogIcon, Edit, MapPin, Clock, CalendarDays, Repeat } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../../components/ui/dialog';
import BatchCreate from './batch-create';
export const BatchInfo: React.FC<{ batch: any; refetchBatch: () => void }> = ({
    batch,
    refetchBatch,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <div className="   ">
            {/* Edit Icon */}
            <div className="absolute top-4 right-0 mt-0  mr-4 z-10">
                {/* <div className="flex m-1">
                    <Edit className="p-1 hover:text-primary hover:scale-100 transition-transform duration-200 cursor-pointer" />
                </div> */}
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogTrigger asChild>
                        <div className=" top-4 right-0">
                            <Edit className="p-1 hover:text-primary hover:scale-100  transition-transform duration-200 top-4 right-0 mt-0  cursor-pointer " />
                        </div>
                    </DialogTrigger>
                    <DialogContent className="mx-auto p-3">
                        <DialogHeader>
                            <DialogTitle>Update Batch</DialogTitle>
                            <DialogDescription>Update the batch details</DialogDescription>
                        </DialogHeader>
                        <div className="bg-slate-50">
                            <BatchCreate
                                batch={batch}
                                refetch={refetchBatch}
                                onClose={() => {
                                    setIsDialogOpen(false);
                                    refetchBatch(); // Refetch batch details after closing dialog
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            {/* Batch Code */}
            <div className="mt-2 flex text-base font-bold items-center  text-center">
                <span>{batch?.batch_code}</span>
            </div>

            {/* Batch Information */}
            <div className="mt-3 text-sm ">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    {/* Location */}
                    <div className="flex items-center space-x-1">
                        <MapPin size="16" className=" text-primary " />
                        <span>
                            {batch?.locations?.map((loc: any) => loc.name).join(', ') || 'N/A'}
                        </span>
                    </div>

                    {/* Stream */}
                    <div className="flex items-center space-x-1">
                        <BookOpenText size="16" className=" text-primary " />
                        <div className="flex items-center">
                            {batch?.batch_stream?.map((batchStream: any) => (
                                <span key={batchStream.stream_names}>
                                    {batchStream.stream_names ? batchStream.stream_names : ''}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* Start Date */}
                    <div className="flex items-center space-x-1">
                        <CalendarDays size="16" className=" text-primary " />
                        <span>{moment(batch?.starting_date).format('LL')}</span>
                    </div>

                    {/* Duration */}
                    <div className="flex items-center space-x-1">
                        <Clock size="16" className=" text-primary " />
                        <span>
                            {/* {batch?.duration} {batch?.duration_type} */}
                            {batch?.duration} {batch?.duration === 1 ? 'Year' : 'Years'}
                        </span>
                    </div>

                    {/* Batch Type */}
                    <div className="flex items-center space-x-1">
                        <CogIcon size="16" className=" text-primary " />
                        <span>{batch?.batch_types[0]?.name}</span>
                    </div>

                    {/* Selected Days */}
                    <div className="flex items-center space-x-1">
                        <Repeat size="16" className=" text-primary " />
                        <span>{batch?.selected_days_count ? 'Flexible' : 'Fixed'}</span>
                        <span>
                            {batch?.selected_days_count
                                ? batch?.selected_days_count + ' Days'
                                : batch.selected_days?.map((days: any) => days).join(', ')}
                        </span>
                    </div>
                </div>
            </div>
            <hr className=" w-full bg-gray-200 h-[0.2vh] mt-4  " />
        </div>
    );
};
