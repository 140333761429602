import { del, get, getFile, patch, post } from '@/lib/api';
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';

interface ScheduleSearchParams {
    location_id?: string | number;
    starting_date?: string; // Adjust date type as per your API's requirements
    ending_date?: string; // Adjust date type as per your API's requirements
    batch_id?: string | undefined;
    stream_code?: string;
}
export const useGetSchedules = (params: ScheduleSearchParams) => {
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const url = `/getSchedule?${queryParams}`; //params.location_id ? `/getSchedule?${queryParams}` : '';
    const queryKey: QueryKey = ['schedules', url];
    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            if (url && url !== '') {
                const result = await get(url); // Replace with your fetch logic
                return result;
            }
            throw new Error('URL is not provided.');
        },
    };

    const query = useQuery<any, Error>(options);

    return query;
};

interface ScheduleParams {
    location_id?: string | number;
    from_date?: string; // Adjust date type as per your API's requirements
    to_date?: string; // Adjust date type as per your API's requirements
    batch_stream?: string;
    facultyIds?: string;
    batchIds?: string;
}
export const useAutoSchedule = (params: ScheduleParams, enable: boolean) => {
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const url = params.location_id ? `/sendAutoScheduleDataToApi?${queryParams}` : '';

    const queryKey: QueryKey = ['auto-schedules', url];

    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            if (url && url !== '') {
                const result = await get(url); // Replace with your fetch logic
                return result;
            }
            throw new Error('URL is not provided.');
        },
        enabled: !!enable,
    };

    const query = useQuery<any, Error>(options);

    return query;
};

// Define your mutation function with correct type signature
export const useSubmitSchedule = async (formData: any): Promise<any> => {
    if (formData.id) {
        const response = await patch(
            `/UpdateSchedule/default/${formData.id}?isBatchFaculty=${formData.isBatchFaculty}`,
            formData
        );
        return response; // Assuming response.data is of type any
    } else {
        const response = await post(
            `/createSchedule?isBatchFaculty=${formData.isBatchFaculty}`,
            formData
        );
        return response; // Assuming response.data is of type any
    }
};

// Define your mutation function with correct type signature
export const useDeleteSchedule = async (id: any): Promise<unknown> => {
    const response = await del('/DeleteSchedule/' + id);
    return response; // Assuming response.data is of type any
};

// Define your mutation function with correct type signature
export const useUpdateScheduleFaculty = async (formData: any): Promise<any> => {
    const response = await patch(`/UpdateSchedule/default/${formData.id}`, formData);
    return response; // Assuming response.data is of type any
};

// Define your mutation function with correct type signature
export const useSwapSchedule = async (data: any): Promise<any> => {
    const response = await post(
        `/swapSchedule?schedule1=${data.schedule1}&schedule2=${data.schedule2}`
    );
    return response; // Assuming response.data is of type any
};

export const useExportSchedule = async (data: any): Promise<any> => {
    let url = `/export-schedule?batch_stream=${data?.streamCode}&location_id=${data?.locationIds.join(',')}&start_date=${data?.from_date}&end_date=${data?.to_date}`;

    if (data?.exportFacultyStatus) {
        url += `&view=faculty`;
    } else if (data?.exportStudentStatus) {
        url += `&view=student`;
    }

    const response = await getFile(url);

    const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    return blob; // Or response.json() based on your API
};

// Define your mutation function with correct type signature
export const useCopySchedule = async (data: any): Promise<any> => {
    const url = `/copySchedule?location_id=${data?.locationId}&from_date=${data?.from_date}&to_date=${data?.to_date}& previous_week_from_date=${
        data?.previous_week_from_date
    }&previous_week_to_date=${data?.previous_week_to__date}`;
    const response = await post(url);
    return response; // Assuming response.data is of type any
};

export const useAutoScheduleStatus = (params: ScheduleParams) => {
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const url = params.from_date ? `/getScheduleStatus?${queryParams}` : '';

    const queryKey: QueryKey = ['auto-schedules-status', url];

    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            if (url && url !== '') {
                const result = await get(url); // Replace with your fetch logic
                return result;
            }
            throw new Error('URL is not provided.');
        },
        enabled: true,
    };

    const query = useQuery<any, Error>(options);

    return query;
};

export const useCheckScheduleValidation = async (params: any): Promise<any> => {
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const url = params.from_date ? `/checkAutoScheduleDataToApi?${queryParams}` : '';
    const response = await post(url);
    return response; // Assuming response.data is of type any
};

// Define your mutation function with correct type signature
export const useDeleteBatchSchedule = async (params: any): Promise<unknown> => {
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const response = await del(`/DeleteBatchSchedule?${queryParams}`);
    return response; // Assuming response.data is of type any
};
