import * as React from 'react';
import { Button } from '@/components/ui/button';
import { addDays, startOfWeek, endOfWeek, subWeeks } from 'date-fns'; // date-fns makes date manipulation easy

import {
    Calendar,
    ArrowLeftRight,
    CalendarCheck2,
    CalendarClock,
    Edit,
    EllipsisVertical,
    ExternalLink,
    ExternalLinkIcon,
    FlagIcon,
    GripVerticalIcon,
    RefreshCw,
    Trash,
} from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import SmallPieChart from '@/components/shared/small-pie-chart';
import { Checkbox } from '@/components/ui/checkbox';
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from '@/components/ui/carousel';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { useCommonProvider } from '@/providers/common-provider';
import {
    GetBatchList,
    GetLocationList,
    GetScheduleTypeList,
    GetSlotTimeList,
    calculateValueFromDates,
    convertTimeTo24TimeFormat,
    generateDateArray,
} from '@/common/functions';
import { useEffect, useState } from 'react';
import { DateRangeComponent, LocationComponent, ScheduleTypeComponent } from '@/common/dropdowns';
import { useGetSubjects } from '@/queries/subject-query';
import { useGetFaculty, useGetFacultyScheduleCount } from '@/queries/faculty-query';
import {
    useAutoSchedule,
    useAutoScheduleStatus,
    useCheckScheduleValidation,
    useCopySchedule,
    useDeleteBatchSchedule,
    useDeleteSchedule,
    useExportSchedule,
    useGetSchedules,
    useSwapSchedule,
    useUpdateScheduleFaculty,
} from '@/queries/schedule-query';
import moment from 'moment';
import { useLoadingProvider } from '@/providers/loading-provider';
import { DateRange } from 'react-day-picker';

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { ScheduleForm } from './schedule-form';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '@/components/ui/use-toast';
import _ from 'lodash';
import { eachDayOfInterval } from 'date-fns';
import { ErrorPingIcon, ScheduleTimesCard } from './schedule-times-card';
import { ScheduleBatchesCard } from './schedule-batches-card';
import ErrorDialog from './error-dialog';
import { getScheduleSuggestions } from './suggestion';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import CustomSelect from '@/components/shared/custom-select';
import { Label } from '@/components/ui/label';
import Color from 'color';
import LocationSelect from './location-selection';
import Timer from './timer';
import { DatePickerWithRange } from '@/components/shared/date-range-picker';
import { useCreateDoubtSessionData, useGetDoubtSessions } from '@/queries/doubt-query';
import { useSearchParams } from 'react-router-dom';
import { WeekPicker } from '@/components/shared/week-picker';
import { useGetHolidays } from '@/queries/holiday-query';
import { FacultyTimesCard } from './faculty-times-card';

const combineFacultyData = (
    facultyCounts: any[],
    facultyDetails: any[],
    selectedDate: any
): any[] => {
    return facultyDetails?.map((detail) => {
        const totalSession = calculateValueFromDates(selectedDate.from, selectedDate.to, 3);
        const countData = facultyCounts?.find((count) => count.faculty_id === detail.id);
        const takenSessions = countData ? countData.count : 0;
        const values = Math.floor((takenSessions / totalSession) * 100) || 0;
        detail.percentage = values;
        detail.totalSession = totalSession;
        if (detail.subject.length > 0) {
            detail.subject_color_code = detail.subject[0].subject_color_code;
            detail.subject_name = detail.subject[0].subject_name;
        }
        detail.color_code = '#FFFF00';
        if (values >= 70 && values <= 100) {
            detail.color_code = '#008000';
        } else if (values >= 101) {
            detail.color_code = '#FF0000';
        } else {
            detail.color_code = '#F3C92C';
        }
        detail.facultyLabel =
            detail?.faculty_code +
            '- ' +
            detail?.first_name +
            ' ' +
            detail?.last_name +
            ' ( ' +
            detail?.subject?.map((subject: any) => subject.subject_code).join(', ') +
            ' )';
        return {
            ...detail,
            count: countData ? countData.count : 0,
        };
    });
};

interface CarouselProfileCardProps {
    item: any;
    selectedFaculties: string[];
    setSelectedFaculties: (data: any) => void;
}
const CarouselProfileCard: React.FC<CarouselProfileCardProps> = ({
    item,
    selectedFaculties,
    setSelectedFaculties,
}) => {
    const { selectedDate } = useCommonProvider();
    const toggleSelection = (id: string) => {
        setSelectedFaculties((prev: string[]) =>
            prev.includes(id) ? prev.filter((facultyId: string) => facultyId !== id) : [...prev, id]
        );
    };

    const isSelected = (id: string) => selectedFaculties.includes(id);

    return (
        <CarouselItem className="pt-1 basis-0">
            <div className="p-1">
                <Card
                    style={{
                        borderColor: `${item.subject_color_code}`,
                    }}
                    className="bg-blend-darken	border border-l-[12px]">
                    <CardContent className={`flex flex-col items-center justify-center p-1 ml-2`}>
                        <div className="flex  w-full ">
                            <div className="flex flex-col">
                                <p>{item?.faculty_code}</p>
                                <p className="text-xs">
                                    {item?.first_name} {item?.last_name}
                                </p>
                            </div>
                            <div className="flex items-center ml-auto">
                                <Checkbox
                                    onClick={() => toggleSelection(item.id)}
                                    checked={isSelected(item.id)}
                                    className="mx-1"
                                />
                                <a
                                    href={`/faculties/${item.id}?date=${moment(
                                        selectedDate?.from
                                    ).format('YYYY-MM-DD')}`}
                                    target="_blank"
                                    key={item.id}
                                    rel="noreferrer">
                                    <ExternalLinkIcon size={18} className="mx-1 text-primary" />
                                </a>
                            </div>
                        </div>
                        <div className="flex items-center  w-full">
                            <span className="flex flex-col">
                                <p className="">
                                    <span className="text-xl">{item?.count}</span> /{' '}
                                    <span className="text-xs">{item?.totalSession}</span>
                                </p>
                                <span className="text-xs font-semibold">{item?.subject_name}</span>
                            </span>
                            <div className="ml-auto">
                                <SmallPieChart percent={item?.percentage} color={item.color_code} />
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </CarouselItem>
    );
};

interface ScheduleDetailsCardProps {
    item: any;
    onEdit: (item: any) => void;
    refresh: (data: any) => void;
}
const isValidDate = (selectedDate: { from: Date | undefined }) => {
    if (!selectedDate?.from) return false;

    const startOfWeekDate = moment().startOf('isoWeek');
    const selectedDateFormatted = moment(selectedDate.from).format('YYYY-MM-DD');

    // Allow editing if the date is in the current week or later
    return moment(selectedDateFormatted).isSameOrAfter(startOfWeekDate);
};
export const ScheduleDetails: React.FC<ScheduleDetailsCardProps> = ({ item, onEdit, refresh }) => {
    item.formatted_date = moment(item.date).format('LL');
    const color = Color(item?.subject?.subject_color_code);
    const { selectedDate } = useCommonProvider();
    const { toast } = useToast();
    const [scheduleIds, setScheduleIds] = useState<any[]>([]);
    const handleEditClick = () => {
        onEdit(item); // Correct usage of onEdit
    };
    const { mutate: deleteSchedule } = useMutation<any>({
        mutationFn: useDeleteSchedule,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            refresh([{ delete: item.id, batch_id: item.batch_id, faculty_id: item.faculty_id }]);
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Can`t Delete',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    const { mutate: deleteBatchSchedule } = useMutation<any>({
        mutationFn: useDeleteBatchSchedule,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            refresh([
                {
                    deleteBatchSchedule: item.date,
                    batch_id: item.batch_id,
                    faculty_id: item.faculty_id,
                },
            ]);
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Can`t Delete',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    async function handleDelete() {
        await deleteSchedule(item.id);
    }
    const { mutate: replaceFaculty } = useMutation<any>({
        mutationFn: useUpdateScheduleFaculty,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            refresh(scheduleIds); // Close the dialog after submission
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Invalid data',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    const { mutate: swapFaculty } = useMutation<any>({
        mutationFn: useSwapSchedule,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            refresh(scheduleIds); // Close the dialog after submission
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Invalid data',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    async function updateScheduleFaculty(suggestion: any) {
        if (suggestion.suggestionType === 'Swap') {
            const data: any = {
                schedule1: suggestion.swapTo.id,
                schedule2: suggestion.swapWith.id,
            };
            setScheduleIds([suggestion.swapTo.id, suggestion.swapWith.id]);
            await swapFaculty(data);
        } else {
            const data: any = {
                faculty_id: suggestion.faculty_id,
                id: item.id,
                batch_id: item.batch_id,
                date: item.date,
                slot_time: item.slot_time,
                location_id: item.location_id,
                subject_id: suggestion.subjects[0]['id'],
            };
            setScheduleIds([item.id]);
            await replaceFaculty(data);
        }
    }
    function handleBatchScheduleDelete() {
        const params: any = {
            id: item.batch_id,
            date: item.date,
        };
        deleteBatchSchedule(params);
    }
    return (
        <div>
            <div className="absolute top-1 right-1 mt-1 mr-1 w-90 ">
                {selectedDate && isValidDate(selectedDate) && (
                    <div className="flex m-1  text-lg">
                        <Edit
                            onClick={handleEditClick}
                            className="p-1 w-8 h-8 text-primary cursor-pointer"
                        />
                        <AlertDialog>
                            <AlertDialogTrigger asChild>
                                <Trash className="p-1 text-red-500 w-8 h-8 cursor-pointer" />
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        This Schedule will be deleted permanently. Do you want to
                                        continue ?
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                                    <AlertDialogAction onClick={handleDelete}>
                                        Continue
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>{' '}
                    </div>
                )}
            </div>
            <div className="absolute top-1 left-1 m-1">
                {/* <p className="pb-3">
                    <span className="text-xl">{item?.session_number}</span> /{' '}
                    <span className="text-xs">50</span>
                </p> */}
            </div>
            <div className="items-center  flex flex-col">
                <div className="-mt-20 mb-1  items-center">
                    <Avatar
                        className="w-16 h-16 shadow-xl"
                        style={{
                            //borderColor: color.darken(0.7).hex(),
                            //borderStyle: '1px solid',
                            border: '1px solid ' + color.darken(0.7).hex(),
                        }}>
                        <AvatarImage
                            src={
                                process.env.REACT_APP_API_URL +
                                `/faculty/` +
                                item?.faculty?.id +
                                `/image`
                            }
                            alt={item?.faculty?.faculty_code}
                        />
                        <AvatarFallback
                            className="border-2  hover:font-semibold hover:underline underline-offset-4"
                            style={{
                                backgroundColor: color.lighten(0.95).hex(),
                                color: color.darken(0.7).hex(),
                                // backgroundColor: color.lighten(0.95).hex(),
                                borderColor: color.darken(0.7).hex(),
                                borderStyle: '1px solid',
                                border: '1px',
                            }}>
                            <a
                                href={`/faculties/${item?.faculty?.id}?date=${moment(
                                    selectedDate?.from
                                ).format('YYYY-MM-DD')}`}
                                target="_blank"
                                key={item?.faculty?.id}
                                rel="noreferrer">
                                <p className="text-sm  hover:font-semibold hover:underline underline-offset-4">
                                    {item?.faculty?.faculty_code}
                                </p>
                            </a>
                        </AvatarFallback>
                    </Avatar>
                </div>

                <a
                    href={`/faculties/${item?.faculty?.id}?date=${moment(selectedDate?.from).format(
                        'YYYY-MM-DD'
                    )}`}
                    target="_blank"
                    key={item?.faculty?.id}
                    rel="noreferrer">
                    <p className="text-sm  hover:font-semibold hover:underline underline-offset-4">
                        {item?.faculty?.first_name} {item?.faculty?.last_name}
                    </p>
                </a>

                {/* <span className="text-xs text-gray-500">+91 9876543210</span> */}
                <span className="text-xs text-primary mt-1">{item?.faculty?.mail}</span>
            </div>
            <hr className="m-3" />
            <div className="text-sm flex flex-col space-y-3">
                <div className="grid grid-cols-2 gap-x-2">
                    <div className="shadow-md p-2 bg-muted/40">
                        <p className="font-semibold text-primary">Batch</p>
                        <p className="mt-1">{item?.batch?.batch_code}</p>
                    </div>
                    <div className="shadow-md p-2 bg-muted/40">
                        <p className="font-semibold text-primary">Subject</p>
                        <p className="mt-1">{item?.subject?.subject_name}</p>
                    </div>
                </div>
                <div className="shadow-md p-2 bg-muted/40">
                    {/* <p className="font-semibold text-primary">Date & Time</p> */}
                    <p className="mt-1">
                        {item?.formatted_date} {/* <br /> */}
                        {item?.formatted_time}
                    </p>
                </div>
                {item?.displayError && (
                    <div className="shadow-md p-2 flex bg-muted/40 items-center">
                        {/* <p className="font-semibold text-red-500">Error</p> */}
                        <div className="-mt-2.5">
                            <ErrorPingIcon align="left" />
                        </div>
                        <p className="mt-1 ml-6 text-red-700 font-semibold">{item?.error}</p>
                    </div>
                )}
                <div className="p-2  bg-muted/40">
                    <p className="font-semibold text-primary flex">
                        <span>Suggesions:</span>
                        <AlertDialog>
                            <AlertDialogTrigger asChild>
                                <span className="ml-auto cursor-pointer  hover:font-semibold hover:underline underline-offset-4">
                                    Declare Study Leave?
                                </span>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        Declare whole day as a Study Leave, All Schedule for this
                                        batch and date will deleted, Do you want to continue?
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                                    <AlertDialogAction onClick={() => handleBatchScheduleDelete()}>
                                        Continue
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </p>
                    <div className="grid grid-rows-3 grid-flow-col gap-2 overflow-auto mt-1">
                        {item?.suggestions?.map((suggestion: any, index: any) => (
                            <AlertDialog>
                                <AlertDialogTrigger asChild>
                                    <Button
                                        key={index}
                                        variant="outline"
                                        className="text-xs border-primary rounded shadow">
                                        {suggestion.suggestionType === 'Swap' ? (
                                            <>
                                                Swap with {suggestion.swapWith.faculty_code} <br />
                                                &nbsp;at&nbsp;
                                                {moment(suggestion.swapWith?.date).format(
                                                    'Do MMM,yyyy dddd'
                                                )}{' '}
                                                {suggestion.swapWith?.slot_time}
                                            </>
                                        ) : (
                                            <>Replace with {suggestion.faculty_code}</>
                                        )}
                                    </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>
                                            Are you absolutely sure?
                                        </AlertDialogTitle>
                                        <AlertDialogDescription>
                                            Changing Schedule Faculty, Do you want to continue ?
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction
                                            onClick={() => updateScheduleFaculty(suggestion)}>
                                            Continue
                                        </AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

function SchedulePage() {
    // Fetching data and states from custom providers
    const {
        selectedLocation,
        userDetail,
        selectedDate,
        selectedScheduleType,
        timeSlotList,
        locationList,
        setTimeSlotList,
        batchList,
    } = useCommonProvider();
    const { setLoading } = useLoadingProvider();
    const { toast } = useToast();

    // Local state management
    const [dates, setDates] = useState<any[]>([]);
    const [faculties, setFaculties] = useState<any[]>([]);
    const [subjects, setSubjects] = useState<any[]>([]);
    const [allDetails, setAllDetails] = useState<any[]>([]);
    const [submitAutoSchedule, setSubmitAutoSchedule] = useState<boolean>(false);
    const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
    const [isErrorsDialogOpen, setIsErrorsDialogOpen] = useState(false);
    const [scheduleDetail, setScheduleDetail] = useState<any>([]);
    const [batchSlotData, setBatchSlotData] = useState<any>({});
    const [schedules, setSchedules] = useState<any[]>([]);
    const [hardRuleError, setHardRuleError] = useState<any>([]);
    const [facultyRuleError, setFacultyRuleError] = useState<any>([]);
    const [searchError, setSearchError] = useState('');
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    const [isExportAlertOpen, setIsExportAlertOpen] = useState<any>('');
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [selectedFaculties, setSelectedFaculties] = useState<string[]>([]);
    const [autoScheduleStatus, setAutoScheduleStatus] = useState(null);
    const [checkValidationData, setCheckValidationData] = useState(null);
    const [allSchedules, setAllSchedules] = useState<any>([]);
    const [locationsSchedules, setLocationsSchedules] = useState<any>([]);
    const [facultyViewScheduleData, setFacultyViewScheduleData] = useState<any>([]);
    const [scheduleView, setScheduleView] = useState<any>('batch');

    // Custom hooks for fetching data
    const { data: subjectList = [] } = useGetSubjects(`/getSubject`);
    const { data: facultyList = [], refetch: refetchFaculty } = useGetFaculty({
        q: '',
        gender: '',
        sortBy: 'id',
        sortOrder: 'ASC',
        location_id: selectedLocation,
    });
    const { data: facultyScheduleCount = [], refetch: refetchFacultyScheduleCount } =
        useGetFacultyScheduleCount(
            selectedLocation && selectedDate
                ? {
                      location_id: selectedLocation,
                      starting_date: moment(selectedDate.from).format('YYYY-MM-DD'),
                      ending_date: moment(selectedDate.to).format('YYYY-MM-DD'),
                  }
                : {}
        );
    const { data: holidayList = [], refetch: refetchHolidayList } = useGetHolidays(
        selectedLocation || ''
    );
    const { data: scheduleList, refetch: refetchScheduleList } = useGetSchedules(
        selectedLocation && selectedDate
            ? {
                  starting_date: moment(selectedDate.from).format('YYYY-MM-DD'),
                  ending_date: moment(selectedDate.to).format('YYYY-MM-DD'),
                  stream_code: selectedScheduleType,
              }
            : {}
    );
    const { data: doubtSessionList, refetch: refetchDoubtSessionList } = useGetDoubtSessions(
        selectedLocations && selectedDate
            ? {
                  starting_date: moment(selectedDate.from).format('YYYY-MM-DD'),
                  ending_date: moment(selectedDate.to).format('YYYY-MM-DD'),
              }
            : {}
    );
    const {
        data: autoScheduleResponse,
        refetch: refetchAutoSchedule,
        isLoading,
        isRefetching,
    } = useAutoSchedule(
        selectedLocations && selectedDate
            ? {
                  location_id: selectedLocations.map((option) => option).join(',') || '',
                  from_date: moment(selectedDate.from).format('YYYY-MM-DD'),
                  to_date: moment(selectedDate.to).format('YYYY-MM-DD'),
                  batch_stream: selectedScheduleType,
              }
            : {},
        submitAutoSchedule
    );
    const { data: autoScheduleStatusData = [], refetch: refetchAutoScheduleStatus } =
        useAutoScheduleStatus(
            selectedDate
                ? {
                      from_date: moment(selectedDate.from).format('YYYY-MM-DD'),
                      to_date: moment(selectedDate.to).format('YYYY-MM-DD'),
                      batch_stream: selectedScheduleType,
                  }
                : {}
        );
    useEffect(() => {
        const oldStatus = autoScheduleStatus ? autoScheduleStatus['status'] : false;
        const status = _.some(
            autoScheduleStatusData.data,
            (location) => location.location_id === selectedLocation
        );
        const record = _.filter(
            autoScheduleStatusData.data,
            (location) => location.location_id === selectedLocation
        );
        const statusData = record.length !== 0 ? record[0] : null;
        setAutoScheduleStatus(statusData);
        if (oldStatus !== status && !status) {
            // setScheduleRefresh(true);
            refresh(['all']);
        }
    }, [autoScheduleStatusData, selectedLocation]);
    // Effect to manage autoschedule loading state
    useEffect(() => {
        setLoading(isLoading || isRefetching);
        if (autoScheduleResponse) {
            setSubmitAutoSchedule(false);
            toast({
                title: autoScheduleResponse.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            refetchAutoScheduleStatus();
        }
    }, [autoScheduleResponse]);

    // Effect to combine faculty data and update state
    useEffect(() => {
        if (facultyScheduleCount && facultyList && selectedDate) {
            const combinedFacultyDetails = combineFacultyData(
                facultyScheduleCount?.Faculties,
                facultyList,
                selectedDate
            );
            setFaculties(combinedFacultyDetails);
        }
    }, [facultyList, facultyScheduleCount, selectedDate]);

    // Effect to refetch faculties and schedule count on location change
    useEffect(() => {
        if (selectedLocation) {
            refetchFaculty();
            refetchFacultyScheduleCount();
            refetchAutoScheduleStatus();
            updateErrors();
        }
    }, [selectedLocation, selectedDate]);

    // Effect to generate dates array based on selected date range
    useEffect(() => {
        if (selectedDate) {
            const generatedDates = generateDateArray(selectedDate.from, selectedDate.to);
            setDates(generatedDates);
            refetchFacultyScheduleCount();
            refetchScheduleList();
            refetchDoubtSessionList();
        }
    }, [selectedDate]);

    // Effect to process subjectList and update subjects state
    useEffect(() => {
        const locationSchedules = _.filter(
            allSchedules,
            (schedule) => schedule.location_id === selectedLocation
        );
        setLocationsSchedules(locationSchedules);
        const lessonsGroupedBySubjectCode = _.groupBy(locationSchedules, 'subject.subject_code');
        const subjectArray = subjectList?.data?.map((item: any) => {
            const subjectCode = item.subject_code;
            const lessonsForSubject = lessonsGroupedBySubjectCode[subjectCode] || [];
            return {
                subject_name: item.subject_name,
                subject_color_code: item.subject_color_code,
                subject_code: subjectCode,
                lessonCount: lessonsForSubject.length,
                lessons: lessonsForSubject,
            };
        });
        setSubjects(subjectArray);
    }, [schedules, selectedLocation]);

    // Effect to process scheduleList and update schedules state
    useEffect(() => {
        if (scheduleList) {
            setAllSchedules(scheduleList);
        }
    }, [scheduleList]);
    useEffect(() => {
        setFacultyViewScheduleData([]);
        if (scheduleView == 'faculty' && doubtSessionList && scheduleList) {
            const doubtSessions = doubtSessionList?.data;
            const morningSlots = [
                { timeRange: '08:00-10:00', start: 8, end: 10 },
                { timeRange: '10:00-12:00', start: 10, end: 12 },
                { timeRange: '12:00-14:00', start: 12, end: 14 },
            ];

            const afternoonSlots = [
                { timeRange: '14:00-17:00', start: 14, end: 17 },
                { timeRange: '17:00-20:00', start: 17, end: 20 },
            ];

            // Helper function to extract hour from a time string (e.g., "10:15" -> 10)
            const extractHour = (time: string) => parseInt(time.split(':')[0], 10);

            // Function to check if the start hour of slot_time fits within a slot's range
            const isWithinSlot = (scheduleTime: string, slotStart: number, slotEnd: number) => {
                const scheduleStartHour = extractHour(scheduleTime.split('-')[0]); // Extract start hour
                return scheduleStartHour >= slotStart && scheduleStartHour < slotEnd;
            };

            // Step 1: Merge schedules and doubt sessions into one array
            const mergedSchedules = [
                ...scheduleList,
                ...doubtSessions.map((session: { start_time: any; end_time: any }) => ({
                    ...session,
                    slot_time: `${session.start_time}-${session.end_time}`, // Create a slot_time for easier comparison
                    type: 'doubt_session',
                })),
            ];

            // Step 2: Group by faculty_id and date
            const groupedByFacultyAndDate = _.groupBy(
                mergedSchedules,
                (item) => `${item.faculty_id}-${item.date}`
            );

            // Step 3: Create final schedule data with faculty.id as the main key
            const finalFacultyScheduleData = faculties.reduce((acc, faculty) => {
                const facultySchedules = Object.entries(groupedByFacultyAndDate)
                    .filter(([key]) => key.startsWith(faculty.id)) // Filter by faculty_id
                    .map(([, _schedules]) => _schedules);

                // Process each day in the date range
                const facultySchedule = dates.map(({ date, label }) => {
                    const schedulesForDate =
                        facultySchedules.find((_schedules) => _schedules[0]?.date === date) || [];

                    // Fill morning slots
                    const morningFilledSlots = morningSlots.map(
                        (slot: { start: any; end: any; timeRange: any }) => {
                            const matchingSchedules = schedulesForDate.filter((schedule) =>
                                isWithinSlot(schedule.slot_time, slot.start, slot.end)
                            );
                            return matchingSchedules.length === 0
                                ? {
                                      faculty_id: faculty.id,
                                      time: slot.timeRange,
                                      subject: '',
                                      slot_time: slot.timeRange,
                                  }
                                : matchingSchedules;
                        }
                    );

                    // Fill afternoon slots
                    const afternoonFilledSlots = afternoonSlots.map(
                        (slot: { start: any; end: any; timeRange: any }) => {
                            const matchingSchedules = schedulesForDate.filter((schedule) =>
                                isWithinSlot(schedule.slot_time, slot.start, slot.end)
                            );
                            return matchingSchedules.length === 0
                                ? {
                                      faculty_id: faculty.id,
                                      time: slot.timeRange,
                                      subject: '',
                                      slot_time: slot.timeRange,
                                  }
                                : matchingSchedules;
                        }
                    );

                    // Add any remaining unmatched schedules (that do not fit into the predefined slots)
                    const unmatchedSchedules = schedulesForDate.filter((schedule) => {
                        const isMatched =
                            morningSlots.some((slot: { start: any; end: any }) =>
                                isWithinSlot(schedule.slot_time, slot.start, slot.end)
                            ) ||
                            afternoonSlots.some((slot: { start: any; end: any }) =>
                                isWithinSlot(schedule.slot_time, slot.start, slot.end)
                            );
                        return !isMatched;
                    });

                    return {
                        date,
                        label,
                        schedules: [
                            ...morningFilledSlots.flat(), // Flatten in case there are multiple schedules in the same slot
                            ...afternoonFilledSlots.flat(),
                            ...unmatchedSchedules, // Include unmatched schedules at the end
                        ],
                    };
                });

                // Add faculty schedule with faculty.id as key
                acc[faculty.id] = { faculty, schedules: facultySchedule };
                return acc;
            }, {});
            setFacultyViewScheduleData(finalFacultyScheduleData);
        }
    }, [scheduleView, scheduleList, faculties, doubtSessionList, selectedLocation]);
    useEffect(() => {
        generateData();
        updateErrors();
        const groupedData = timeSlotList.map((slot: any) => ({
            slot_time: convertTimeTo24TimeFormat(slot.time),
            formatted_time: slot.time,
            dates: dates.reduce((acc: any, date) => {
                acc[date.date] = [];
                return acc;
            }, {}),
        }));
        allSchedules?.map((entry: any) => {
            const date = entry.date;
            const slotTime = convertTimeTo24TimeFormat(entry.slot_time);
            const suggestions = getScheduleSuggestions(
                entry,
                batchList,
                allSchedules,
                faculties,
                timeSlotList,
                selectedDate
            );
            entry.suggestions = suggestions;
            const group = groupedData.find((item: any) => item.slot_time == slotTime);
            if (group && entry && group.dates[date]) {
                entry['formatted_time'] = group.formatted_time;
                group.dates[date].push(entry);
            }
        });
        setSchedules(groupedData);
    }, [allSchedules, faculties]);
    useEffect(() => {
        generateData();
    }, [batchList]);
    function isTimeInRange(time: string, start: string, end: string): boolean {
        const [hour, minute] = time.split(':').map(Number);
        const [startHour, startMinute] = start.split(':').map(Number);
        const [endHour, endMinute] = end.split(':').map(Number);

        const totalMinutes = hour * 60 + minute;
        const startMinutes = startHour * 60 + startMinute;
        const endMinutes = endHour * 60 + endMinute;

        return totalMinutes >= startMinutes && totalMinutes <= endMinutes;
    }

    function isSlotInRange(slotTimes: any[], start: string, end: string): boolean {
        return _.some(slotTimes, (slotTime) => {
            const [startSlotTime, endSlotTime] = slotTime.split('-');
            return (
                isTimeInRange(startSlotTime, start, end) || isTimeInRange(endSlotTime, start, end)
            );
        });
    }

    const categorizeBatches = (): any => {
        const timeRanges = {
            morning: { start: '00:00', end: '13:00' },
            afternoon: { start: '13:00', end: '23:59' },
        };
        const specialBatches = _.filter(batchList, (batch: any) => batch.special_batch === 1);
        const morningBatches = _.filter(
            batchList,
            (batch: any) =>
                batch.special_batch === 0 &&
                _.some(batch.batch_slots, (slot: any) =>
                    isSlotInRange(slot.slot_times, timeRanges.morning.start, timeRanges.morning.end)
                )
        );

        const afternoonBatches = _.filter(
            batchList,
            (batch: any) =>
                batch.special_batch === 0 &&
                !_.some(batch.batch_slots, (slot: any) =>
                    isSlotInRange(slot.slot_times, timeRanges.morning.start, timeRanges.morning.end)
                )
        );

        const getUniqueSlotTimes = (batches: any[], isSpecial = false) => {
            const batchId = _.flatMap(batches, (batch) => batch.id);
            if (isSpecial) {
                const filteredSchedules = allSchedules?.filter((sched: { batch_id: any }) =>
                    batchId.includes(sched.batch_id)
                );
                const groupedSchedules = _(filteredSchedules)
                    .groupBy((sched) => `${sched.batch_id}_${sched.date}`)
                    .mapValues((group) => group.length)
                    .value();
                // Find the maximum length
                const maxLength: number = _.max(_.values(groupedSchedules)) ?? 0;

                const arrayWithMaxLength: string[] = Array.from(
                    { length: maxLength },
                    () => '00:00-00:00'
                );
                return arrayWithMaxLength.map((data) => ({
                    id: data,
                    slot_time: data,
                }));
            } else {
                const allSlotTimes = _.flatMap(batches, (batch) =>
                    _.flatMap(batch.batch_slots, 'slot_times')
                );
                const scheduleSlotTimes = _.chain(allSchedules)
                    .groupBy('batch_id')
                    .pick(batchId) // Filter the groups by the specified batch_id(s)
                    .mapValues((batch) => _.uniq(batch.map((ele) => ele.slot_time)).sort())
                    .value();
                const uniqueSlotTimes = _.chain(scheduleSlotTimes).flatMap().uniq().sort().value();
                return _.uniq([...allSlotTimes, ...uniqueSlotTimes]).map((slotTime) => ({
                    id: slotTime,
                    slot_time: slotTime,
                }));
            }
        };

        const parseTime = (timeStr: any): any => {
            const [startTime] = timeStr.split('-');
            return moment(startTime, 'HH:mm').format('HH:mm');
        };

        const sortSlotTimes = (slots: any): any => {
            return _.sortBy(slots, (slot: any) => parseTime(slot.slot_time));
        };

        const morningSlots = sortSlotTimes(getUniqueSlotTimes(morningBatches));
        const afternoonSlots = sortSlotTimes(getUniqueSlotTimes(afternoonBatches));
        const specialSlots = sortSlotTimes(getUniqueSlotTimes(specialBatches, true));

        const calculateWidth = (slots: any[]): number => slots.length; // Slot width in rem
        const morningWidth = calculateWidth(morningSlots);
        const afternoonWidth = calculateWidth(afternoonSlots);
        const specialWidth = calculateWidth(specialSlots);
        const containerWidthRem =
            Math.max(morningWidth, afternoonWidth, specialWidth) < 3
                ? 3
                : Math.max(morningWidth, afternoonWidth, specialWidth);
        const remainingWidthMorning = containerWidthRem - morningWidth;

        const remainingWidthAfternoon = containerWidthRem - afternoonWidth;
        const remainingWidthSpecial = containerWidthRem - specialWidth;
        return {
            morningBatches,
            afternoonBatches,
            specialBatches,
            morningSlots,
            afternoonSlots,
            specialSlots,
            extra: {
                morning: Array.from(
                    { length: morningWidth !== containerWidthRem ? remainingWidthMorning : 0 },
                    (a, index) => index
                ),
                afternoon: Array.from(
                    {
                        length: afternoonWidth !== containerWidthRem ? remainingWidthAfternoon : 0,
                    },
                    (a, index) => index
                ),
                special: Array.from(
                    { length: specialWidth !== containerWidthRem ? remainingWidthSpecial : 0 },
                    (a, index) => index
                ),
            },
        };
    };
    const generateData = (): void => {
        const start = selectedDate && selectedDate.from ? selectedDate.from : '';
        const end = selectedDate && selectedDate.to ? selectedDate.to : '';
        const dateArray = eachDayOfInterval({
            start: new Date(start),
            end: new Date(end),
        });
        const {
            morningBatches,
            afternoonBatches,
            specialBatches,
            morningSlots,
            afternoonSlots,
            specialSlots,
            extra,
        } = categorizeBatches();
        setBatchSlotData(categorizeBatches());
        const list = dateArray.map((d: moment.MomentInput) => {
            const date = moment(d);
            const formattedDate = date.format('YYYY-MM-DD');
            const subSchedule = _.filter(allSchedules, ({ date: schedDate }) =>
                moment(schedDate).isSame(date, 'day')
            );

            const isHoliday = _.some(holidayList, ({ dates: dateList }) =>
                _.some(dateList, (holidayDate) => moment(holidayDate).isSame(date, 'day'))
            );

            const holiday = _.filter(holidayList, ({ dates: dateList }) =>
                _.some(dateList, (holidayDate) => moment(holidayDate).isSame(date, 'day'))
            );

            const morningLessons = generateLessons(
                morningSlots,
                morningBatches,
                subSchedule,
                date,
                holiday
            );
            const afternoonLessons = generateLessons(
                afternoonSlots,
                afternoonBatches,
                subSchedule,
                date,
                holiday
            );
            const specialLessons = generateLessons(
                specialSlots,
                specialBatches,
                subSchedule,
                date,
                holiday,
                true
            );

            return {
                date: formattedDate,
                isHoliday,
                extra,
                morningLessons,
                afternoonLessons,
                specialLessons,
            };
        });
        setAllDetails(list);
    };
    const isHolidayForLesson = (
        holidays: any[],
        batchType: { id: any },
        batch: { id: any },
        location: { id: any },
        batchSlot: { slot: any }
    ) => {
        return _.some(holidays, (holiday) => {
            const appliesToAll =
                _.isEmpty(holiday.locations) &&
                _.isEmpty(holiday.batch_types) &&
                _.isEmpty(holiday.batch_slots) &&
                _.isEmpty(holiday.batches);
            const locationMatch =
                _.isEmpty(holiday.locations) ||
                _.some(holiday.locations, ({ id }) => id === location.id);
            const batchTypeMatch =
                _.isEmpty(holiday.batch_types) ||
                _.some(holiday.batch_types, ({ id }) => id === batchType.id);
            const batchesMatch =
                _.isEmpty(holiday.batches) || _.some(holiday.batches, ({ id }) => id === batch.id);

            const batchSlotMatch =
                _.isEmpty(holiday.batch_slots) ||
                _.some(
                    holiday.batch_slots,
                    ({ name }) => name.toLowerCase() === batchSlot.slot.toLowerCase()
                ); // Check for batch_slot_id
            return (
                appliesToAll || (locationMatch && batchTypeMatch && batchesMatch && batchSlotMatch)
            );
        });
    };
    const getStartTime = (slot_time: string) => {
        return slot_time.split('-')[0]; // Extracts the start time (e.g., '08:00')
    };
    const generateLessons = (
        slots: any[],
        batches: any[],
        subSchedule: any[],
        date: any,
        holiday: any[],
        isSpecial = false
    ): any => {
        const formatTime = (time: string): string => moment(time, 'HH:mm').format('h:mm A');

        const times = _.map(slots, ({ slot_time }) => {
            const [start, end] = slot_time.split('-');
            return `${formatTime(start)} - ${formatTime(end)}`;
        });

        const displayTimes = _.map(slots, ({ slot_time }) => {
            const [start, end] = slot_time.split('-');
            return {
                startTime: moment(start, 'hh:mm A'),
                endTime: moment(end, 'hh:mm A'),
            };
        });

        const lessons = _.map(
            batches,
            ({ id, batch_code, faculties: faculty, batch_slots, locations, batch_types }) => {
                const flatSlots = _.flatMap(batch_slots, 'slot_times');
                return _.map(times, (time, timeIndex) => {
                    const [start, end] = time.split(' - ');
                    const formattedTime = `${moment(start, 'h:mm A').format('HH:mm')}-${moment(end, 'h:mm A').format('HH:mm')}`;
                    let scheduleData = [];
                    if (!isSpecial) {
                        scheduleData = _.filter(
                            subSchedule,
                            ({ slot_time, batch }, index) =>
                                (isSpecial ? '' : slot_time === formattedTime) && batch.id === id
                        );
                    } else {
                        const scheduleData1 = _.filter(
                            subSchedule,
                            ({ slot_time, batch }, index) => batch.id === id
                        );
                        // Sort the schedules by the start time of slot_time
                        scheduleData = scheduleData1.sort((a, b) => {
                            const [hoursA, minutesA] = getStartTime(a.slot_time)
                                .split(':')
                                .map(Number);
                            const [hoursB, minutesB] = getStartTime(b.slot_time)
                                .split(':')
                                .map(Number);
                            const timeA = hoursA * 60 + minutesA;
                            const timeB = hoursB * 60 + minutesB;
                            return timeA - timeB;
                        });
                    }
                    // const scheduleData = _.filter(
                    //     subSchedule,
                    //     ({ slot_time, batch }) => slot_time === formattedTime && batch.id === id
                    // );
                    const schedule = isSpecial
                        ? scheduleData[timeIndex] || {}
                        : _.head(scheduleData) || {};
                    schedule['displayError'] = false;
                    if (schedule['error'] && schedule['error'][0]) {
                        schedule['displayError'] = true;
                        const errorArray = schedule['error'][0].split(':');
                        if (errorArray[0] === 'WARNING') {
                            schedule['displayError'] = false;
                        }
                        schedule['error'] =
                            errorArray[0] === 'WARNING' || errorArray[0] === 'ERROR'
                                ? [errorArray[1]]
                                : [errorArray[0]];
                    }
                    const isHoliday =
                        holiday &&
                        holiday.length > 0 &&
                        isHolidayForLesson(
                            holiday,
                            batch_types[0],
                            { id },
                            locations[0],
                            batch_slots[0]
                        );
                    const isUnique = !timeSlotList.some(
                        (slot: any) => slot.originalTime === formattedTime
                    );
                    if (isUnique) {
                        const [startTime, endTime] = formattedTime.split('-');
                        const formattedStartTime = formatTime(startTime);
                        const formattedEndTime = formatTime(endTime);
                        const lessonSlot = {
                            originalTime: formattedTime,
                            time: `${formattedStartTime} - ${formattedEndTime}`,
                            startTime: formattedStartTime,
                            endTime: formattedEndTime,
                        };
                        setTimeSlotList((prev: any) => {
                            const updatedSlots = [...prev, lessonSlot];
                            const uniqueSlots = updatedSlots.filter(
                                (slot, index, self) =>
                                    index ===
                                    self.findIndex((s) => s.originalTime === slot.originalTime)
                            );
                            return uniqueSlots;
                        });
                    }

                    return {
                        time,
                        time_old: formattedTime,
                        display_time: {
                            startTime: moment(start, 'hh:mm A'),
                            endTime: moment(end, 'hh:mm A'),
                        },
                        date: date.format('L'),
                        batch_id: id,
                        batch_code,
                        faculty,
                        isHoliday,
                        lesson: schedule,
                        allLesson: scheduleData,
                        allowToAddLesson: _.includes(flatSlots, formattedTime),
                    };
                });
            }
        );

        return {
            times,
            displayTimes,
            lessons,
        };
    };
    const updateErrors = () => {
        const facultyError = calculateFacultyHoursAndLectures(allSchedules);

        // Filter out records that have error messages
        const filteredData = facultyError.filter((record) => record.message !== null);
        setFacultyRuleError(filteredData);

        // Extract hard rule errors based on selected location
        const errorMessages = allSchedules.reduce((acc: any, item: any) => {
            if (item.error && selectedLocation === item.location_id) {
                const errorArray = item.error[0]?.split(':');
                if (errorArray && (errorArray[0] === 'ERROR' || errorArray[0] !== 'WARNING')) {
                    const [start, end] = item.slot_time.split('-');
                    const time = `${moment(start, 'HH:mm').format('h:mm A')} - ${moment(end, 'HH:mm').format('h:mm A')}`;

                    acc.push({
                        message: errorArray[errorArray.length - 1],
                        id: item.id,
                        time,
                        batch_name: item.batch.batch_code,
                        date: item.date,
                        faculty_id: item.faculty.id,
                        faculty_code: item.faculty.faculty_code,
                        subject_name: item.subject.subject_name,
                        faculty_name: `${item.faculty.first_name} ${item.faculty.last_name[0]}.`,
                        lesson: item,
                    });
                }
            }
            return acc;
        }, []);

        setHardRuleError(errorMessages);
    };
    const calculateFacultyHoursAndLectures = (records: any) => {
        // Group records by date and faculty_id
        const filterRecords = _.filter(records, (data) => {
            return _.some(data.faculty.location, (l) => l.id === selectedLocation);
        });
        const groupedRecords = _.groupBy(
            filterRecords,
            (record) => `${record.date}_${record.faculty_id}`
        );
        return _.map(groupedRecords, (record: any, key) => {
            const faculty = record[0].faculty;
            // Calculate the earliest start time and latest end time for the faculty on this date
            const times = _.flatMap(record, (r) => r.slot_time.split('-'));
            const minTime = _.min(times.map((time) => moment(time, 'HH:mm')));
            const maxTime =
                _.max(times.map((time) => moment(time, 'HH:mm'))) || moment('00:00', 'HH:mm');

            const totalHours = moment.duration(maxTime.diff(minTime)).asHours();
            const roundedTotalHours = Math.round(totalHours);

            // Calculate the total number of lectures
            const totalLectures = record.length;

            // Determine if there is an error
            let error = null;
            if (record[0]['error'] && record[0]['error'][0]) {
                const errorArray = record[0]['error'][0].split(':');
                if (errorArray[0] === 'WARNING') {
                    error = errorArray[errorArray.length - 1];
                }
            }
            if (totalLectures > 3) {
                error = error ? `${error} & Total lectures exceed 3` : 'Total lectures exceed 3';
            }
            if (roundedTotalHours > 10) {
                error = error ? `${error} & total hours exceed 10` : 'Total hours exceed 10';
            }
            return {
                date: record[0].date,
                location_id: record[0]['location_id'],
                faculty_name: `${faculty.first_name} ${faculty.last_name}`,
                faculty_id: record[0].faculty.id,
                faculty_code: faculty.faculty_code,
                totalHours: roundedTotalHours,
                totalLectures,
                message: error,
                lesson: record[0],
            };
        });
    };
    // Function to trigger auto-scheduling
    function AutoSchedule() {
        if (submitAutoSchedule) {
            refetchAutoSchedule();
        } else {
            setSubmitAutoSchedule(true);
        }
    }
    function refresh(data: any = {}) {
        setScheduleDetail([]);
        setIsScheduleDialogOpen(false);
        //refetchScheduleList();
        const details: any = {};
        if (data && data.length > 0) {
            if (data[0]['delete']) {
                const removeDeletedSchedule = _.filter(
                    allSchedules,
                    ({ id }) => id !== data[0]['delete']
                );
                setAllSchedules(removeDeletedSchedule);
                details['facultyIds'] = [data[0]['faculty_id']];
                setLoading(true);
                setCheckValidationData(details);
                updateErrors();
                data = [data[0]['delete']];
            } else if (data[0]['deleteBatchSchedule']) {
                const removeDeletedSchedule = _.filter(
                    allSchedules,
                    ({ batch_id, date }) =>
                        batch_id !== data[0]?.batch_id && date !== data[0]?.deleteBatchSchedule
                );
                setAllSchedules(removeDeletedSchedule);
                details['batchIds'] = [data[0]['batch_id']];
                details['facultyIds'] = [data[0]['faculty_id']];
                setLoading(true);
                setCheckValidationData(details);
                updateErrors();
                data = [data[0]['delete']];
            } else if (data[0]['add']) {
                details['batchIds'] = [data[0]['add']['batch_id']];
                details['facultyIds'] = [data[0]['add']['faculty_id']];
                setLoading(true);
                setCheckValidationData(details);
                refetchFacultyScheduleCount();
                updateErrors();
            } else if (data[0] === 'all') {
                setCheckValidationData(details);
                setLoading(true);
                refetchScheduleList();
                updateErrors();
            } else {
                const filteredSchedule = allSchedules.filter((item: any) => data.includes(item.id));
                // Get unique batch_id and faculty_id
                const uniqueBatchIds = _.uniq(filteredSchedule.map((item: any) => item.batch_id));
                const uniqueFacultyIds = _.uniq(
                    filteredSchedule.map((item: any) => item.faculty_id)
                );
                details['batchIds'] = uniqueBatchIds;
                details['facultyIds'] = uniqueFacultyIds;
                setCheckValidationData(details);
                setLoading(true);
                refetchFacultyScheduleCount();
                updateErrors();
            }
        }
    }
    function handleCardClick(data: any) {
        if (data?.id) {
            const detail = {
                id: data.id,
                batch_id: data.batch_id,
                slot_time: data.slot_time,
                faculty_id: data.faculty_id,
                subject_id: data.subject_id,
                date: new Date(data.date),
            };
            setScheduleDetail(detail);
        } else {
            const detail = {
                date: data.date ? new Date(data.date) : '',
                slot_time: data.time ? convertTimeTo24TimeFormat(data.time) : '',
                batch_id: data.batch_id ? data.batch_id : '',
            };
            setScheduleDetail(detail);
        }
        setIsScheduleDialogOpen(true);
    }
    const { mutate: exportSchedule } = useMutation<any>({
        mutationFn: useExportSchedule,
        onSuccess: (data) => {
            toast({
                title: 'Exported Successfully',
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            const url = URL.createObjectURL(data);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Schedule Export ${moment(selectedDate?.from).format('YYYY-MM-DD')} TO ${moment(selectedDate?.to).format('YYYY-MM-DD')}.xlsx`; // Change filename based on your needs
            a.click();
            URL.revokeObjectURL(url);
            setLoading(false);
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Can`t Export',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
            setLoading(false);
        },
    });
    async function handleExportSchedule(type: any) {
        if (selectedDate) {
            const data: any = {
                locationIds: selectedLocations ? selectedLocations : [],
                from_date: moment(selectedDate.from).format('YYYY-MM-DD'),
                to_date: moment(selectedDate.to).format('YYYY-MM-DD'),
                streamCode: selectedScheduleType,
                exportFacultyStatus: type === 'faculty' ? true : false,
                exportStudentStatus: type === 'student' ? true : false,
            };
            setLoading(true);
            exportSchedule(data);
        }
    }
    const { mutate: copySchedule } = useMutation<any>({
        mutationFn: useCopySchedule,

        onSuccess: (data) => {
            setLoading(false);
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            refresh(['all']);
        },
        onError: (error: any) => {
            setLoading(false);

            toast({
                title: error?.response?.data?.message || 'Can`t Copy',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    async function handleCopySchedule() {
        if (selectedDate) {
            const data: any = {
                from_date: moment(selectedDate.from).format('YYYY-MM-DD'),
                to_date: moment(selectedDate.to).format('YYYY-MM-DD'),
                locationId: selectedLocation,
                previous_week_from_date: moment(selectedCopyScheduleDate?.from).format(
                    'YYYY-MM-DD'
                ),
                previous_week_to__date: moment(selectedCopyScheduleDate?.to).format('YYYY-MM-DD'),
            };
            copySchedule(data);
        }
    }

    const { mutate: checkValidations } = useMutation<any>({
        mutationFn: useCheckScheduleValidation,
        onSuccess: (validationData) => {
            setLoading(false);
            if (validationData && validationData.response) {
                let mergedArray = _.map(allSchedules, (record) => {
                    // Find the updated record by matching the 'id'
                    const updatedRecord = _.find(validationData.response, {
                        id: record.id,
                    });
                    // If an updated record is found, merge it with the original record
                    return updatedRecord ? _.merge({}, record, updatedRecord) : record;
                });
                // Find the records in updatedArray that are not in the original array and add them to the merged array
                const newRecords = _.filter(validationData.response, (updatedRecord) => {
                    return !_.some(allSchedules, { id: updatedRecord.id });
                });
                // Combine the merged array with the new records
                mergedArray = [...mergedArray, ...newRecords];
                setAllSchedules(mergedArray);
                setCheckValidationData(null);
            }
        },
        onError: (error: any) => {
            setLoading(false);
            toast({
                title: 'Can`t validate',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    useEffect(() => {
        if (checkValidationData) {
            const params: any = {
                location_id: selectedLocation,
                batch_stream: selectedScheduleType,
                from_date: moment(selectedDate ? selectedDate.from : '').format('YYYY-MM-DD'),
                to_date: moment(selectedDate ? selectedDate.to : '').format('YYYY-MM-DD'),
                facultyIds: (checkValidationData && checkValidationData['facultyIds']) || '',
                batchIds: (checkValidationData && checkValidationData['batchIds']) || '',
            };
            checkValidations(params);
        }
    }, [checkValidationData]);
    const getPreviousWeekDateRange = (date: Date): DateRange => {
        const startOfCurrentWeek = startOfWeek(date, { weekStartsOn: 1 });
        const endOfCurrentWeek = endOfWeek(date, { weekStartsOn: 1 });

        // Calculate previous week's start and end
        const startOfPreviousWeek = subWeeks(startOfCurrentWeek, 1);
        const endOfPreviousWeek = subWeeks(endOfCurrentWeek, 1);

        return {
            from: startOfPreviousWeek,
            to: endOfPreviousWeek,
        };
    };
    const initialDate = selectedDate?.from || new Date(); // Default to today if selectedDate?.from is undefined
    const [selectedCopyScheduleDate, setSelectedCopyScheduleDate] = useState<DateRange | undefined>(
        getPreviousWeekDateRange(initialDate)
    );
    useEffect(() => {
        // Update the state if selectedDate changes
        if (selectedDate?.from) {
            setSelectedCopyScheduleDate(getPreviousWeekDateRange(selectedDate.from));
        }
    }, [selectedDate, isCopyAlertOpen]);

    const handleonChangeCopySchedule = (value: DateRange | undefined) => {
        setSelectedCopyScheduleDate(value);
    };
    const [selectDoubtSessionDate, setSelectDoubtSessionDate] = useState<DateRange | undefined>();
    const [searchParams] = useSearchParams();
    const {
        mutate: createDoubtSession,
        isSuccess: isDoubtSessionSuccess,
        isError: isDoubtSessionError,
    } = useCreateDoubtSessionData();

    const from = searchParams.get('from');
    const to = searchParams.get('to');
    useEffect(() => {
        if (from && to) {
            setSelectDoubtSessionDate({ from: new Date(from), to: new Date(to) });
        } else {
            setSelectDoubtSessionDate({
                from: startOfWeek(new Date(), { weekStartsOn: 1 }),
                to: endOfWeek(new Date(), { weekStartsOn: 1 }),
            });
        }
    }, [selectedDate]);
    const handleonChangeDoubtSessionDate = (value: DateRange | undefined) => {
        setSelectDoubtSessionDate(value);
    };
    async function DoubtSession() {
        setLoading(true); // Set loading to false once the request is done (success or error)

        const startingDate = moment(selectDoubtSessionDate?.from).format('YYYY-MM-DD');
        const endingDate = moment(selectDoubtSessionDate?.to).format('YYYY-MM-DD');
        const postData = {
            location_ids: selectedLocations.map(String), // Convert IDs to strings if necessary
            starting_date: startingDate,
            ending_date: endingDate,
        };

        await createDoubtSession(postData);
    }

    useEffect(() => {
        // Check if either success or error occurred
        if (isDoubtSessionSuccess || isDoubtSessionError) {
            // Set loading to false first
            setLoading(false);
            // Now display the toast after loading is set to false
            if (!isLoading) {
                toast({
                    title: isDoubtSessionSuccess
                        ? 'Doubt Session Created Successfully'
                        : 'Doubt Session Creation Failed',
                    description: isDoubtSessionSuccess
                        ? 'The doubt session has been created successfully.'
                        : 'An error occurred during the creation of the doubt session.',
                    variant: isDoubtSessionError ? 'destructive' : 'default',
                });
            }
        }
    }, [isDoubtSessionSuccess, isDoubtSessionError, isLoading]); // Add loading as a dependency

    return (
        <>
            {userDetail && <GetLocationList />}
            {selectedLocation && <GetBatchList />}
            <GetSlotTimeList />
            <GetScheduleTypeList />
            <ScheduleForm
                isOpen={isScheduleDialogOpen}
                setIsOpen={setIsScheduleDialogOpen}
                onClose={refresh}
                defaultValues={scheduleDetail}
                batchList={batchList}
                facultyList={faculties}
                timeSlotList={timeSlotList}
            />
            <div className="flex flex-wrap  md:flex-nowrap h-[calc(100vh-85px)]">
                <div className="flex flex-col flex-grow p-3 rounded shadow bg-background">
                    <div className="sticky top-0 z-30 flex flex-wrap items-center mb-1">
                        <div className="flex lg:flex-nowrap xs:flex-wrap sm:flex-nowrap  flex-wrap items-center space-x-2">
                            <LocationComponent />
                            {/* <BatchComponent /> */}
                            {scheduleView === 'batch' && <ScheduleTypeComponent />}
                            <DateRangeComponent />
                        </div>
                        <div className="ml-auto flex space-x-2">
                            {!autoScheduleStatus ? (
                                <>
                                    <AlertDialog>
                                        <AlertDialogTrigger asChild>
                                            <Button
                                                variant="outline"
                                                className="text-primary rounded shadow">
                                                <RefreshCw className="mr-2" /> Validations
                                            </Button>
                                        </AlertDialogTrigger>
                                        <AlertDialogContent>
                                            <AlertDialogHeader>
                                                <AlertDialogTitle>
                                                    Recheck Validation
                                                </AlertDialogTitle>
                                                <AlertDialogDescription>
                                                    Validating existing records. Do you want to
                                                    continue ?
                                                </AlertDialogDescription>
                                            </AlertDialogHeader>
                                            <AlertDialogFooter>
                                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                                <AlertDialogAction onClick={() => refresh(['all'])}>
                                                    Continue
                                                </AlertDialogAction>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialog>
                                    <AlertDialog>
                                        <AlertDialogTrigger asChild>
                                            <Button
                                                variant="outline"
                                                disabled={
                                                    selectedDate && !isValidDate(selectedDate)
                                                }
                                                className="text-primary rounded shadow">
                                                <Calendar className="mr-2" /> Doubt Session
                                            </Button>
                                        </AlertDialogTrigger>
                                        <AlertDialogContent className="max-w-3xl">
                                            <AlertDialogHeader>
                                                <AlertDialogTitle className="text-center">
                                                    Create Doubt Session
                                                </AlertDialogTitle>
                                                <AlertDialogDescription>
                                                    <Label>
                                                        Select Locations for Doubt Session
                                                    </Label>
                                                    <LocationSelect
                                                        locations={locationList}
                                                        selectedLocations={selectedLocations}
                                                        setSelectedLocations={setSelectedLocations}
                                                    />
                                                    <div className="">
                                                        <Label>Select Date</Label>
                                                        <WeekPicker
                                                            initialDateRange={
                                                                selectDoubtSessionDate
                                                            }
                                                            onSubmit={
                                                                handleonChangeDoubtSessionDate
                                                            }
                                                            width="w-full" // Use the width prop for dynamic width
                                                            border="  border-primary"
                                                            className=" mt-1 pl-6"
                                                        />{' '}
                                                    </div>
                                                </AlertDialogDescription>
                                            </AlertDialogHeader>
                                            <AlertDialogFooter>
                                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                                <AlertDialogAction
                                                    disabled={selectedLocations?.length === 0}
                                                    onClick={DoubtSession}>
                                                    Submit
                                                </AlertDialogAction>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialog>
                                    <AlertDialog>
                                        <AlertDialogTrigger asChild>
                                            <Button
                                                variant="outline"
                                                disabled={
                                                    selectedDate && !isValidDate(selectedDate)
                                                }
                                                className="text-primary rounded shadow">
                                                <CalendarClock className="mr-2" />{' '}
                                                {locationsSchedules?.length > 0
                                                    ? 'Reschedule'
                                                    : 'Auto Schedule'}
                                            </Button>
                                        </AlertDialogTrigger>
                                        <AlertDialogContent className="max-w-3xl">
                                            <AlertDialogHeader>
                                                <AlertDialogTitle className="text-center">
                                                    {locationsSchedules?.length > 0
                                                        ? 'Reschedule'
                                                        : 'Auto Schedule'}
                                                </AlertDialogTitle>
                                                <AlertDialogDescription>
                                                    <Label>
                                                        Select Locations for{' '}
                                                        {locationsSchedules?.length > 0
                                                            ? 'Reschedule'
                                                            : 'Auto Schedule'}
                                                    </Label>
                                                    <LocationSelect
                                                        locations={locationList}
                                                        selectedLocations={selectedLocations}
                                                        setSelectedLocations={setSelectedLocations}
                                                    />
                                                    <p className="p-2 text-center text-lg">
                                                        {locationsSchedules?.length > 0 && (
                                                            <>
                                                                Rescheduling will replace existing
                                                                records.
                                                            </>
                                                        )}
                                                        Do you want to continue ?
                                                    </p>
                                                </AlertDialogDescription>
                                            </AlertDialogHeader>
                                            <AlertDialogFooter>
                                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                                <AlertDialogAction
                                                    disabled={selectedLocations?.length === 0}
                                                    onClick={AutoSchedule}>
                                                    Continue
                                                </AlertDialogAction>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialog>
                                    <AlertDialog>
                                        <AlertDialogTrigger asChild>
                                            <Button
                                                variant="outline"
                                                disabled={
                                                    selectedDate && !isValidDate(selectedDate)
                                                }
                                                className="text-primary rounded shadow">
                                                <CalendarCheck2 className="mr-2" /> Publish
                                            </Button>
                                        </AlertDialogTrigger>
                                        <AlertDialogContent>
                                            <AlertDialogHeader>
                                                <AlertDialogTitle>
                                                    Are you absolutely sure?
                                                </AlertDialogTitle>
                                                <AlertDialogDescription>
                                                    Publishing Schedule, Do you want to continue ?
                                                </AlertDialogDescription>
                                            </AlertDialogHeader>
                                            <AlertDialogFooter>
                                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                                <AlertDialogAction>Continue</AlertDialogAction>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialog>
                                    <DropdownMenu>
                                        <DropdownMenuTrigger>
                                            <EllipsisVertical />
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent side="bottom" align="end">
                                            <DropdownMenuItem
                                                onClick={() => setIsCopyAlertOpen(true)}>
                                                Copy Schedule From Previous Week
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                                onClick={() => setIsExportAlertOpen('faculty')}>
                                                Export Schedule For Faculty
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                                onClick={() => setIsExportAlertOpen('student')}>
                                                Export Schedule For Student
                                            </DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                    <AlertDialog
                                        open={isCopyAlertOpen}
                                        onOpenChange={setIsCopyAlertOpen}>
                                        <AlertDialogContent className="w-full max-w-xl -mt-20">
                                            <AlertDialogHeader>
                                                <AlertDialogTitle>
                                                    Are you absolutely sure?
                                                </AlertDialogTitle>
                                                <AlertDialogDescription>
                                                    <div className="">
                                                        <Label>Select Date:</Label>
                                                        <WeekPicker
                                                            initialDateRange={
                                                                selectedCopyScheduleDate
                                                            }
                                                            maxDate={
                                                                selectedDate &&
                                                                new Date(
                                                                    moment(
                                                                        selectedDate['from'] || ''
                                                                    )
                                                                        .startOf('week')
                                                                        .format('YYYY-MM-DD')
                                                                )
                                                            }
                                                            onSubmit={handleonChangeCopySchedule}
                                                            width="w-[520px]" // Use the width prop for dynamic width
                                                            className="mt-1 mb-1"
                                                        />{' '}
                                                    </div>
                                                    Copy will replace existing records. Do you want
                                                    to continue?
                                                </AlertDialogDescription>
                                            </AlertDialogHeader>
                                            <AlertDialogFooter>
                                                <AlertDialogCancel
                                                    onClick={() => {
                                                        setIsCopyAlertOpen(false);
                                                        setSelectedCopyScheduleDate(undefined); // Clear the date range on cancel
                                                    }}>
                                                    Cancel
                                                </AlertDialogCancel>
                                                <AlertDialogAction
                                                    onClick={() => {
                                                        handleCopySchedule();
                                                        setIsCopyAlertOpen(false);
                                                    }}>
                                                    Continue
                                                </AlertDialogAction>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialog>
                                    <AlertDialog
                                        open={isExportAlertOpen !== ''}
                                        onOpenChange={() => setIsExportAlertOpen('')}>
                                        <AlertDialogContent className="w-full max-w-3xl -mt-20">
                                            <AlertDialogHeader>
                                                <AlertDialogTitle className="text-center">
                                                    Export Schedule
                                                </AlertDialogTitle>
                                                <AlertDialogDescription>
                                                    <div className="">
                                                        <Label>Select Locations for Export</Label>
                                                        <LocationSelect
                                                            locations={locationList}
                                                            selectedLocations={selectedLocations}
                                                            setSelectedLocations={
                                                                setSelectedLocations
                                                            }
                                                        />
                                                    </div>
                                                </AlertDialogDescription>
                                            </AlertDialogHeader>
                                            <AlertDialogFooter>
                                                <AlertDialogCancel
                                                    onClick={() => {
                                                        setIsExportAlertOpen('');
                                                    }}>
                                                    Cancel
                                                </AlertDialogCancel>
                                                <AlertDialogAction
                                                    disabled={selectedLocations?.length === 0}
                                                    onClick={() => {
                                                        handleExportSchedule(isExportAlertOpen);
                                                    }}>
                                                    Export
                                                </AlertDialogAction>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialog>
                                </>
                            ) : (
                                <>
                                    <p className="flex ml-auto">
                                        <span className="mt-2">
                                            <span className="">
                                                Auto Scheduling in progress. Please wait...
                                            </span>
                                        </span>
                                        <Button
                                            className="px-4 rounded ml-2 flex items-center shadow-lg"
                                            onClick={() => refetchAutoScheduleStatus()}>
                                            <Timer
                                                duration={
                                                    parseInt(autoScheduleStatus['duration']) + 9
                                                }
                                                createdAt={autoScheduleStatus['created_at']}
                                            />
                                        </Button>
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className=" ">
                        <div className="flex">
                            <div className="flex ml-auto space-x-4 mb-1">
                                {subjects?.map((subject, index) => (
                                    <div
                                        key={`subjectList_` + index}
                                        className="flex items-center rounded-lg w-22">
                                        <div
                                            style={{
                                                backgroundColor: `${subject.subject_color_code}`,
                                            }}
                                            className="w-4 h-4 font-serif"></div>
                                        <span className="m-2 text-xs">
                                            {subject.subject_name} ({subject?.lessonCount})
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {scheduleView === 'batch' ? (
                        <div className="overflow-auto bg-muted w-full md:w-[calc(100vw-19rem)] h-[calc(100vh-195px)]">
                            <div className="relative flex flex-col w-max h-full ">
                                {allDetails && (
                                    <>
                                        {batchSlotData?.morningBatches?.length > 0 && (
                                            <ScheduleTimesCard
                                                key={'morning'}
                                                type={'morning'}
                                                batchSlotData={batchSlotData}
                                                dates={dates}
                                                allDetails={allDetails}
                                                handleCardClick={handleCardClick}
                                                refresh={refresh}
                                                selectedFaculties={selectedFaculties}
                                            />
                                        )}
                                        {batchSlotData?.afternoonBatches?.length > 0 && (
                                            <ScheduleTimesCard
                                                key={'afternoon'}
                                                type={'afternoon'}
                                                batchSlotData={batchSlotData}
                                                dates={dates}
                                                allDetails={allDetails}
                                                handleCardClick={handleCardClick}
                                                refresh={refresh}
                                                selectedFaculties={selectedFaculties}
                                            />
                                        )}
                                        {batchSlotData?.specialBatches?.length > 0 && (
                                            <ScheduleTimesCard
                                                key={'special'}
                                                type={'special'}
                                                batchSlotData={batchSlotData}
                                                dates={dates}
                                                allDetails={allDetails}
                                                handleCardClick={handleCardClick}
                                                refresh={refresh}
                                                selectedFaculties={selectedFaculties}
                                            />
                                        )}
                                    </>
                                )}

                                {/* <ScheduleBatchesCard
                                timeSlotList={timeSlotList}
                                dates={dates}
                                schedules={schedules}
                                handleCardClick={handleCardClick}
                                refresh={refresh}
                            /> */}
                            </div>
                        </div>
                    ) : (
                        <div className="overflow-auto bg-muted w-full  md:w-[calc(100vw-6rem)] h-[calc(100vh-195px)]">
                            <div className="relative flex flex-col w-max h-full ">
                                {facultyViewScheduleData && faculties && (
                                    <FacultyTimesCard
                                        faculties={faculties}
                                        dates={dates}
                                        scheduleData={facultyViewScheduleData}
                                        handleCardClick={handleCardClick}
                                        refresh={refresh}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {scheduleView === 'batch' && (
                    <div className="flex flex-col shadow ml-2 ">
                        <label className="text-base font-medium mt-4 text-center leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 mb-1 ml-1">
                            Faculties
                        </label>
                        <div className="flex flex-col overflow-auto justify-center rounded  my-2 flex-grow ml-1 items-center scrollbar-hide">
                            <p
                                onClick={() => setSelectedFaculties([])}
                                className="ml-auto mr-2 cursor-pointer  hover:font-semibold hover:underline underline-offset-4">
                                Clear All
                            </p>
                            <Carousel
                                opts={{ align: 'start' }}
                                orientation="vertical"
                                className="w-full">
                                <CarouselContent className="h-[calc(100vh-15rem)] mt-1">
                                    {faculties?.map((item) => (
                                        <CarouselProfileCard
                                            item={item}
                                            key={item.id}
                                            selectedFaculties={selectedFaculties}
                                            setSelectedFaculties={setSelectedFaculties}
                                        />
                                    ))}
                                </CarouselContent>
                                <CarouselPrevious className=" border-primary" />
                                <CarouselNext className=" border-primary" />
                            </Carousel>
                        </div>
                    </div>
                )}
                <ErrorDialog
                    isOpen={isErrorsDialogOpen}
                    onClose={() => setIsErrorsDialogOpen(false)}
                    setIsOpen={setIsErrorsDialogOpen}
                    scheduleError={hardRuleError}
                    facultyError={facultyRuleError}
                    setScheduleError={setHardRuleError}
                    setFacultyError={setFacultyRuleError}
                    handleCardClick={handleCardClick}
                    searchError={searchError}
                    setSearchError={setSearchError}
                />
            </div>
        </>
    );
}

export default SchedulePage;
