// import apiService, { fetchById, fetchData, get, QueryParams } from '@/lib/api';
// import { CreateOrUpdateBatchData, DeleteItemData, DoubtSessionData } from '@/types';
// import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

// export const useGetUsers = (queryParams: QueryParams) => {
//     const url = `/getUsers`;
//     const queryKey: QueryKey = ['users', queryParams];
//     const options: UseQueryOptions<any, Error> = {
//         queryKey: queryKey,
//         queryFn: async () => {
//             return get(url, { params: queryParams }); // Replace with your fetch logic
//         },
//     };
//     const query = useQuery<any, Error>(options);
//     return query;
// };
import apiService, { fetchById, fetchData, get, post, QueryParams } from '@/lib/api';
import { CreateOrUpdateBatchData, DeleteItemData } from '@/types';
import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { UsersApiResponse, User } from '@/types'; // Assuming you have defined this

export const useGetUsers = (queryParams: QueryParams) => {
    const url = `/getUsers`;
    const queryKey: QueryKey = ['users', queryParams];

    // Defining the options for useQuery
    const options: UseQueryOptions<UsersApiResponse, Error> = {
        queryKey: queryKey,
        queryFn: async (): Promise<any> => {
            const response = await get(url, { params: queryParams }); // No type argument needed
            return response; // Type the data after getting the response
        },
    };

    // Pass the options directly into useQuery
    const query = useQuery<UsersApiResponse, Error>(options);
    return query;
};
async function createUser({ postData }: { postData: User }) {
    try {
        const response = await apiService.post(`${process.env.REACT_APP_API_URL}/users`, postData);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useCreateUserData = () => {
    // const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (params: User) => createUser({ postData: params }),
    });
};

async function updateUser({ id, postData }: { id: string; postData: User }) {
    try {
        const response = await apiService.patch(
            `${process.env.REACT_APP_API_URL}/updateUser/${id}`,
            postData
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const useUpdateUserData = () => {
    return useMutation({
        mutationFn: async ({ id, postData }: { id: string; postData: User }) =>
            await updateUser({ id, postData }),
    });
};
export const useFetchUser = (id: any) => {
    return useQuery({
        queryKey: ['batch', id],
        queryFn: () => fetchById('getByIdUser', id),
    });
};
export async function deleteUserItem({ itemToDelete }: { itemToDelete: DeleteItemData }) {
    try {
        const response = await apiService.delete(
            `${process.env.REACT_APP_API_URL}/deleteUser/${itemToDelete.id}`
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useDeleteUserData = () => {
    return useMutation({
        mutationFn: async (params: DeleteItemData) => deleteUserItem({ itemToDelete: params }),
    });
};
