import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormProvider } from 'react-hook-form';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../components/ui/select';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form';
import { Input } from '../../components/ui/input';
import { useFetchLocationData } from '@/queries/location-query';
import { Checkbox } from '@/components/ui/checkbox';
import {
    useCreateBatchData,
    useFetchBatchStreamData,
    useFetchBatchTypeData,
    useUpdateBatchData,
} from '@/queries/batch-query';
import { DatePicker } from '@/components/ui/date-picker';
import moment from 'moment';

const formSchema = z.object({
    batchCode: z.string().nonempty({ message: 'This is required. *' }),
    locations: z.string().min(1, { message: 'This is required. *' }),
    duration: z.number().positive({ message: 'Duration must be a positive number. *' }),
    durationtype: z.string().min(2, { message: 'This is required. *' }),
    startingDate: z.date({ required_error: 'This is required. *' }), // Ensure `startingDate` is a Date

    batchStream: z.string().min(1, { message: 'This is required. *' }),
    batchType: z.string().min(1, { message: 'This is required. *' }),
    specialBatch: z.boolean(), // Add this to the schema
});

type BatchCreateProps = {
    refetch: () => void;

    onClose: () => void; // Function to close the dialog
    batch: any; // Define a proper type for batch if available
};
const BatchCreate: React.FC<BatchCreateProps> = ({ batch, onClose, refetch }) => {
    const { toast } = useToast();

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            batchCode: batch?.batch_code || '',
            locations: batch?.locations?.[0]?.id || '',

            batchType: batch?.batch_types?.[0]?.id || '',
            duration: batch?.duration || 1,
            durationtype: batch.duration_type || 'Years',
            startingDate: batch?.starting_date
                ? new Date(batch.starting_date)
                : new Date(new Date().toISOString().substr(0, 10)),
            batchStream: batch?.batch_stream?.[0]?.id || '',
            specialBatch: batch?.special_batch === 1 ? true : false, // Convert 1 to true, 0 to false
        },
    });
    const { handleSubmit, control, setValue } = form;
    const params = { page: 1, limit: 10, sortBy: 'updated_at', sortOrder: 'DESC', filter: '' };
    const { data: locationData } = useFetchLocationData(params);
    const { data: batchTypeData } = useFetchBatchTypeData(params);
    const { data: batchStreamData } = useFetchBatchStreamData(params);
    const {
        mutate: createBatchData,
        isSuccess: createIsSuccess,
        isError: createIsError,
        error,
    } = useCreateBatchData();
    const {
        mutate: updateBatchData,
        isSuccess: updateIsSuccess,
        isError: updateIsError,
        error: updateError,
    } = useUpdateBatchData();

    useEffect(() => {
        if (batch) {
            setValue('batchCode', batch.batch_code || '');

            // setValue('startingDate', batch.starting_date || new Date().toISOString().substr(0, 10));
            setValue(
                'startingDate',
                batch?.starting_date
                    ? new Date(batch.starting_date) // Convert to Date object
                    : new Date(new Date().toISOString().substr(0, 10)) // Default to today's date as Date object
            );
            setValue('duration', batch.duration || 1);
            setValue('durationtype', batch.duration_type || 'Years');
            setValue('batchStream', batch.batch_stream?.[0]?.id || '');
            setValue('locations', batch.locations?.[0]?.id || '');
            setValue('batchType', batch.batch_types?.[0]?.id || '');
            setValue('specialBatch', batch.special_batch === 1 ? true : false); // Convert 1 to true, 0 to false
        }
    }, [batch, setValue]);
    function onSubmit(values: z.infer<typeof formSchema>) {
        const facultyIds = batch?.faculties?.map((item: any) => item.id) || [];
        const SubjectSessionData = batch?.batch_subjects?.map((subject: any) => ({
            subject_id: subject?.subject_id,
            session_per_weeks: subject?.session_per_weeks,
            total_sessions_of_year: subject?.total_session_of_year,
        }));

        const timeData = batch?.batch_slots?.map((slotData: any) => ({
            slot: slotData?.slot, // Extract the slot name ('morning', 'afternoon', etc.)
            slot_times: slotData?.slot_times, // Extract the array of time intervals
        }));
        const dateFormat = moment(values?.startingDate).format('YYYY-MM-DD');

        const postData = {
            location_id: values?.locations,

            batch_code: values.batchCode,
            batch_type_id: [values?.batchType],
            batch_stream_id: values?.batchStream,
            faculty_id: facultyIds,
            starting_date: dateFormat,
            duration: values?.duration,
            subject_sessions: SubjectSessionData,
            duration_type: values?.durationtype,
            slot: timeData,

            ...(batch?.selectedDays?.length > 0 ? { selected_days: batch?.selected_days } : {}), // Only include if not empty
            ...(batch?.selectedDays?.length === 0
                ? { selected_days_count: batch?.selected_days_count }
                : {}), // Include count if days are empty
            special_batch: values.specialBatch ? 1 : 0, // Set to 1 if true, 0 if false
        };

        try {
            if (batch) {
                // Update the batch if it exists
                updateBatchData({ id: batch?.id, postData });
            } else {
                // Create a new batch if it does not exist
                createBatchData(postData);
            }
        } catch (err) {
            toast({
                title: 'ERROR:',
                description: 'There was an error processing your request.',
            });
        }
    }
    useEffect(() => {
        if (createIsSuccess) {
            toast({
                title: 'Batch Created Successfully',
                description: 'The batch was created successfully.',
                variant: 'default', // Success variant
            });
            refetch();

            onClose(); // Close the modal only on success
        } else if (createIsError) {
            const typedError = error as any; // or as unknown
            let errorMsg = 'There was an error creating the batch.';

            if (typedError.response) {
                // const emailErrors = typedError.response.data.errors?.email;
                const errors = typedError.response.data.errors;
                errorMsg = Object.keys(errors)
                    .map((field) => ` ${errors[field].join(', ')}`)
                    .join('\n');
            } else {
                errorMsg = error.message || errorMsg;
            }
            toast({
                title: 'ERROR:',
                variant: 'destructive',
                description: errorMsg,
            });
        }
    }, [createIsSuccess, onClose, error]);
    useEffect(() => {
        if (updateIsSuccess) {
            toast({
                title: 'Batch Updated Successfully',
                description: 'The batch was updated successfully.',
                variant: 'default', // Success variant
            });
            refetch();

            onClose(); // Close the modal only on success
        } else if (updateIsError) {
            const typedError = error as any; // or as unknown
            let errorMsg = 'There was an error update the batch.';

            if (typedError.response) {
                const errors = typedError.response.data.errors;
                errorMsg = Object.keys(errors)
                    .map((field) => ` ${errors[field].join(', ')}`)
                    .join('\n');
            } else {
                errorMsg = error?.message || errorMsg;
            }
            toast({
                title: 'ERROR:',
                variant: 'destructive',
                description: errorMsg,
            });
        }
    }, [updateIsSuccess, updateIsError, onClose, updateError]);
    return (
        <div className="max-w-[880px] max-h-[600px] sm:max-h-[860px] overflow-auto p-1">
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
                    <div className="space-y-4">
                        <FormField
                            control={control}
                            name="batchCode"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        Batch Code:<span className="text-red-500">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="Enter Batch Code"
                                            {...field}
                                            onChange={(e) => {
                                                const value = e.target.value.toUpperCase(); // Convert the entire input to uppercase
                                                field.onChange(value); // Update the form field with the uppercase value
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="batchStream"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        Batch Stream:<span className="text-red-500">*</span>
                                    </FormLabel>
                                    <Select
                                        onValueChange={(value) => {
                                            field.onChange(value);
                                        }}
                                        value={field.value}>
                                        <SelectTrigger className="w-full">
                                            <SelectValue placeholder="Select Batch Stream" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {batchStreamData?.map((stream: any) => (
                                                <SelectItem key={stream.id} value={stream.id}>
                                                    <div>
                                                        <p>{stream.stream_names}</p>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="locations"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        Location:<span className="text-red-500">*</span>
                                    </FormLabel>
                                    <Select
                                        onValueChange={field.onChange}
                                        value={field.value || ''}>
                                        <SelectTrigger className="w-full">
                                            <SelectValue placeholder="Select location" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {locationData?.map((location: any) => (
                                                <SelectItem key={location.id} value={location.id}>
                                                    <div>
                                                        <p>{location.name}</p>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="batchType"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        Batch Type:<span className="text-red-500">*</span>
                                    </FormLabel>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger className="w-full">
                                            <SelectValue placeholder="Select Batch Type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {batchTypeData?.map((batchtype: any) => (
                                                <SelectItem key={batchtype.id} value={batchtype.id}>
                                                    <div>
                                                        <p>{batchtype.name}</p>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <div className="flex flex-col sm:flex-row gap-4">
                            <FormField
                                control={control}
                                name="duration"
                                render={({ field }) => (
                                    <FormItem className="flex-1">
                                        <FormLabel>
                                            Duration:<span className="text-red-500">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                type="number"
                                                placeholder="Duration"
                                                {...field}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value, 10);
                                                    field.onChange(value);
                                                }}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={control}
                                name="durationtype"
                                render={({ field }) => (
                                    <FormItem className="flex-1">
                                        <FormLabel>
                                            Duration Type:<span className="text-red-500">*</span>
                                        </FormLabel>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="w-full">
                                                <SelectValue placeholder="Select Duration Type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {['Days', 'Weeks', 'Months', 'Years'].map(
                                                    (type) => (
                                                        <SelectItem key={type} value={type}>
                                                            {type}
                                                        </SelectItem>
                                                    )
                                                )}
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <FormField
                            control={control}
                            name="startingDate"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel htmlFor="date">
                                        Date:<span className="text-red-500">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <DatePicker
                                            initialDate={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="specialBatch"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md p-2">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormLabel>Special Batch</FormLabel>
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button
                            className="bg-white-2 border border-gray-300 mr-2"
                            variant="secondary"
                            onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit">Submit</Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default BatchCreate;
