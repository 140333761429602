import React, { useState, useRef } from 'react';
import { X } from 'lucide-react'; // Import the CloseIcon from lucide-react

interface Option {
    value: string;
    label: string;
}

interface MultiSelectProps {
    options: Option[];
    value: Option[];
    onChange: (selected: Option[]) => void;
    placeholder: string;
    width?: string; // Define width as an optional string
    enableSearch?: boolean;
}

const MultiselectDropDown: React.FC<MultiSelectProps> = ({
    options,
    value,
    onChange,
    placeholder,
    width,
    enableSearch = true,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const selectRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleOptionClick = (selectedOption: Option) => {
        let newValue;
        if (value.some((option) => option.value === selectedOption.value)) {
            newValue = value.filter((option) => option.value !== selectedOption.value);
        } else {
            newValue = [...value, selectedOption];
        }
        onChange(newValue);
        setSearchTerm(''); // Clear search term after selecting option
        setIsOpen(false); // Close dropdown after selecting option
    };

    const handleRemoveOption = (selectedValue: string) => {
        const newValue = value.filter((option) => option.value !== selectedValue);
        onChange(newValue);
    };

    const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setIsOpen(true); // Open dropdown when typing in search input
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            selectRef.current &&
            !selectRef.current.contains(event.target as Node) &&
            !(inputRef.current && inputRef.current.contains(event.target as Node))
        ) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission or other default behavior
            setIsOpen(true); // Open dropdown on Enter key press
        }
    };

    const handleSearchClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation(); // Prevent click event propagation to parent elements
    };

    return (
        <div
            ref={selectRef}
            className={`relative w-full border  p-1 cursor-pointer focus:ring-2 focus:ring-blue-500`}
            onClick={() => setIsOpen(!isOpen)}
            onKeyDown={handleKeyDown} // Handle tab and enter key press
            tabIndex={0} // Make div focusable for keyboard navigation
        >
            {value.length > 0 ? (
                value.map((option) => (
                    <span
                        key={option.value}
                        className="bg-gray-200  px-2 py-1 m-1 inline-flex items-center text-xs">
                        {option.label}
                        <X
                            onClick={(e: any) => {
                                e.stopPropagation();
                                handleRemoveOption(option.value);
                            }}
                            className="ml-2 h-4 w-4 cursor-pointer text-gray-500"
                        />
                    </span>
                ))
            ) : (
                <span className="text-gray-500 ">{placeholder}</span>
            )}

            {isOpen && (
                <div
                    className="absolute w-52 border rounded bg-white z-10 mt-1"
                    style={{ width: width || 'auto' }} // Use dynamic width or default to 'auto'
                >
                    {enableSearch && (
                        <input
                            ref={inputRef} // Ref for input field
                            type="text"
                            className="w-full p-2 outline-none text-xs border-b border-gray-300 rounded" // Smaller font size
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            autoFocus
                            onClick={handleSearchClick} // Prevents click event from closing dropdown
                        />
                    )}
                    <div className="max-h-48 overflow-y-auto text-xs">
                        {' '}
                        {/* Smaller font size */}
                        {filteredOptions.map((option) => (
                            <div
                                key={option.value}
                                className={`p-2 cursor-pointer text-sm ${
                                    value.some((val) => val.value === option.value)
                                        ? 'bg-gray-200'
                                        : ''
                                }`}
                                onClick={() => handleOptionClick(option)}>
                                {option.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiselectDropDown;
