import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

interface CreateSlotTimeProps {
    onSubmit: ({ startTime, endTime }: any) => void;
}

const CreateSlotTime: React.FC<CreateSlotTimeProps> = ({ onSubmit }) => {
    const [startTime, setStartTime] = useState<string>('09:00');
    const [endTime, setEndTime] = useState<string>('10:00');
    const [error, setError] = useState<string | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartTime(e.target.value);
        if (error) setError(null); // Reset error when times are modified
    };
    useEffect(() => {
        if (isDialogOpen) {
            setEndTime('10:00');
            setStartTime('09:00');
        }
    }, [isDialogOpen]);

    const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndTime(e.target.value);
        if (error) setError(null); // Reset error when times are modified
    };

    const validateTime = () => {
        // Convert times to comparable numbers (minutes since midnight)
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);

        const startTotalMinutes = startHour * 60 + startMinute;
        const endTotalMinutes = endHour * 60 + endMinute;

        return endTotalMinutes > startTotalMinutes;
    };

    const handleSubmit = () => {
        if (validateTime()) {
            onSubmit({ startTime, endTime }); // Pass values to parent
            setIsDialogOpen(false);
        } else {
            setError('End time must be later than start time.');
        }
    };

    return (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
                <span className="ml-auto text-primary hover:font-bold hover:underline underline-offset-4 cursor-pointer">
                    + Create Slot
                </span>
            </DialogTrigger>
            <DialogContent className="mx-auto p-4">
                <div>
                    <h2 className="text-xl font-semibold text-gray-700 mb-4">Pick a Time Range</h2>
                    <div className="flex  bg-muted/40 space-x-4 rounded-xl w-full mx-auto">
                        {/* Start Time Section */}
                        <div className="flex flex-col w-full items-center space-y-2 p-4">
                            <label className="text-gray-600">Start Time</label>
                            <input
                                type="time"
                                value={startTime}
                                onChange={handleStartTimeChange}
                                className="w-full p-2 text-center rounded-md shadow focus:outline-none focus:ring-2 focus:ring-blue-400"
                            />
                        </div>

                        {/* End Time Section */}
                        <div className="flex flex-col w-full items-center space-y-2 p-4">
                            <label className="text-gray-600">End Time</label>
                            <input
                                type="time"
                                value={endTime}
                                onChange={handleEndTimeChange}
                                className="w-full p-2 text-center rounded-md shadow focus:outline-none focus:ring-2 focus:ring-blue-400"
                            />
                        </div>
                    </div>
                    {/* Validation Error Message */}
                    {error && <div className="text-red-500 text-sm p-2">{error}</div>}
                    <DialogFooter>
                        <Button type="button" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </DialogFooter>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default CreateSlotTime;
